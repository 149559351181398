<template>

  <head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Business Permits</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body data-sidebar="dark">

  <!-- Begin page -->
  <div id="layout-wrapper">


    <!-- ========== Left Sidebar Start ========== -->
    <NavigationBar/>
    <!-- Left Sidebar End -->


    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Invoice Waivers</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Action Center</a></li>
                    <li class="breadcrumb-item"><a href="#">Waivers</a></li>

                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <ToolBarWaiver/>
          <!-- end of toolbar -->
          <div class="row">
            <div class="col-12">
              <div class="card">

                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between w-100 flex-row row-flex" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="input-group fw-semibold text-uppercase me-3">
                        <label class="input-group-text text-uppercase">Sub County</label>

                        <select class="form-select " v-model="subCounty" @change="getWaivers(subCounty.subCountyName)">

                          <option  selected="" value="">All Sub Counties</option>
                          <option :key="index" :value="item" v-for="(item, index) in subCounties">
                            {{ item.subCountyName }}
                          </option>

                        </select>

                      </div>


                      <div class="input-group fw-semibold text-uppercase">
                        <label class="input-group-text text-uppercase">FROM:</label>
                        <input v-model="dateFrom" class="form-control form-control-sm" type="date" value="2019-08-19" id="example-date-input">
                      </div>

                      <div class="input-group fw-semibold text-uppercase me-3">
                        <label class="input-group-text text-uppercase">TO:</label>
                        <input v-model="dateTo" class="form-control form-control-sm" type="date" value="2019-08-19" id="example-date-input">
                      </div>

                      <button @click="downloadWaivers" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                      </button>

                    </div>
                  </div>
                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>

                <div class="card-body">
                  <div class="table-responsive text-nowrap">
                    <table class="table align-middle table-hover contacts-table table-striped text-uppercase" id="datatable-buttons">
                      <thead class="table-light">
                      <tr class="table-dark">
                        <th scope="col">Date</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Invoiced To</th>
                        <th scope="col">Waiver Reason</th>
                        <th scope="col">Waiver Item</th>
                        <th scope="col" class="text-right">Waiver Amount</th>
                        <th>Invoice Date</th>
                        <th>Invoice Amount</th>
                        <th>Amount Paid</th>
                        <th>Invoice Bal.</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr v-for="(item, index) in waiverRequests" :key="index">
                        <td>{{item.dateCreated}}</td>
                        <td>{{item.billNo}}</td>
                        <td>{{item.invoicedTo}}</td>
                        <td>{{item.reason}}</td>
                        <td class="fw-bold text-black">{{item.description}}</td>
                        <td class="text-right fw-bold text-black">KES {{item.amount}}</td>
                        <td>{{item.invoicedDate}}</td>
                        <td class="text-right">KES {{item.invoicedAmount}}</td>
                        <td class="text-right">KES {{item.amountPaid}}</td>
                        <td class="text-right">KES {{item.invoiceBalance}}</td>
                        <td class="text-right text-uppercase">
                          <div class="d-flex justify-content-end align-items-center">
                            <button @click="viewWaiverRequest(item)" class="btn btn-sm btn-success startWaiver text-uppercase " data-bs-toggle="modal" data-bs-target="#otpModalWaiver">
                              View Details
                            </button>
                            <a @click="printBill(item.billNo)" href="javascript: void(0);" target="_blank" class="btn btn-primary btn-sm waves-effect waves-light mx-3">
                              Print Invoice
                            </a>
                            <button @click="viewWaiverRequest(item)" class="d-none btn btn-info btn-sm waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#waiverDecline">
                              Revert Waiver
                            </button>
                          </div>
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
              <span
                  class="badge rounded-pill bg-warning text-uppercase font-12px text-uppercase mx-3">Ongoing Applications</span>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="hori-timeline mt-4">
                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                         id="timeline-carousel">
                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                            <h5 class="mb-4">Invoicing</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">Kelvin Omondi</a></p>
                            <p class="text-muted text-capitalize mb-1"><a href="PrintDocs/Receipts/receipts-HOMabay county.html"><i class="bx bx-receipt"></i> View Invoice</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list active">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">-</div>
                            <h5 class="mb-4">Payment</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>



                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">-</div>
                            <h5 class="mb-4">Permit Approval</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                  </div>


                </div>



                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="dripicons-user font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Owner </p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Owner's Name</p>
                            </td>
                            <td class="">Wireless Electronics</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID No.</p>
                            </td>
                            <td class="">1234557768</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                Phone</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Email
                              </p>

                            </td>
                            <td class="">12345</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                KRA PIN</p>

                            </td>
                            <td class="">Accomodation and Catering</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details </p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business/ Company name</p>
                            </td>
                            <td class="">The company</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Category</p>
                            </td>
                            <td class="">1234557768</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Sub Category</p>

                            </td>
                            <td class="">12345</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">SBP Payment Status</p>
                            </td>
                            <td class=" text-success fw-bold">PAID</td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Activity</p>

                            </td>
                            <td class="">12345</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Sub County</p>

                            </td>
                            <td class="">Homa Bay Town</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Ward</p>

                            </td>
                            <td class="">Homa Bay central</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Physical Address</p>

                            </td>
                            <td class="">Opposite Kisumu Highway</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Plot Number</p>

                            </td>
                            <td class="">1224/890</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Invoicing Year</p>

                            </td>
                            <td class="">2024</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business ID</p>

                            </td>
                            <td class="">123</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                SBP Invoicing Number</p>

                            </td>
                            <td class="">BLL23769980</td>
                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-receipt font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Fire Permit Invoicing Details</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">

                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Invoiced</p>
                            </td>
                            <td class="">12 Jan 2024 12:56 AM (22 Days Ago)</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Invoiced By</p>
                            </td>
                            <td class="">Jane Waweru Mburu</td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Invoice Payment Status</p>
                            </td>
                            <td class="text-success fw-bold text-uppercase">FULLY PAID</td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Expired</p>
                            </td>
                            <td class="text-success fw-bold text-uppercase">FULLY PAID</td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Validity Status</p>
                            </td>
                            <td class="text-danger fw-bold text-uppercase">Expired</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Fire Inspection Fees</p>
                            </td>
                            <td class="">KES 4,500</td>


                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Application Fees</p>
                            </td>
                            <td class="">KES 500</td>



                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Staff Training (x5)</p>

                            </td>
                            <td class="">KES 500</td>

                          </tr>

                          <tr class="text-success">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Paid</p>

                            </td>
                            <td class="">KES 5,000</td>
                          </tr>

                          <tr class="">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Pending Amount</p>

                            </td>
                            <td class="">KES 0.00</td>
                          </tr>

                          <tr class="">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">SUB TOTAL</p>

                            </td>
                            <td class="">KES 5,000</td>
                          </tr>



                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="mdi mdi-fire-extinguisher font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Fire Safety Measures In Place</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div>
                        <ol style="margin-top: 0px;" type="1">
                          <li type="1">Provided an escape route at the rear.</li>
                          <li type="1">General House Keeping is good</li>
                          <li type="1">Electrical installations done to acceptable standards</li>
                          <li type="1">Provided three gas cylinders</li>
                        </ol>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="mdi mdi mdi-flag-remove font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Recomendations to be resolved</p>

                      </div>
                    </div>

                    <div class="col-12">
                      <div>
                        <ol style="margin-top: 0px;" type="1">
                          <li type="1">Provided an escape route at the rear.</li>
                          <li type="1">General House Keeping is good</li>
                          <li type="1">Electrical installations done to acceptable standards</li>
                          <li type="1">Provided three gas cylinders</li>
                        </ol>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
            <div class="modal-footer d-none">

              <a href="PrintDocs/Receipts/receipts-HOMabay county.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Receipt
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- invoice details -->
      <div class="modal fade SMS-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Resend Invoice SMS</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12">
                <address>
                  <strong>Invoiced To:</strong><br>
                  Kelvin Njuguna<br>
                  email@mail.com, 0704 549 859<br>

                  <br><br>
                  Date invoiced 1 Mar 2022, 10:20 AM
                </address>
              </div>

              <div class="col-12">
                <p>
                  A notification will be sent to the client via SMS containing payment details. Kindly remind the client to check their phone and make the payment promptly. The client's invoice number is <span class="fw-bold">BLL123456</span>.
                </p>
              </div>
              <div class="col-12">
                <button class="btn btn-primary text-uppercase w-100 btn-block text-center">Resend</button>
              </div>
            </div>


          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->

      <!-- creating a fire invoice -->
      <div class="modal fade new-fire-invoice" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header liq-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">New Fire inspection Permit application</h5>

              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pb-4 searching-modal-body">
              <div class="row business-selector">
                <div class="col-md-12 mb-3">
                  <label for="">Search or Input establishment details</label>
                  <select class="form-select fire-search-method" >
                    <option value="">Select Search Method</option>
                    <option value="search">Search from Existing Registered Businesses</option>
                    <option value="Paid-incoice">Use Paid Invoice</option>
                  </select>


                </div>
              </div>

              <div class="row">

                <!-- existing business -->
                <div class="col-12 business-id-search d-none">
                  <label for="">Search For Business To continue</label>
                  <div class="input-group mb-3">

                    <input type="text" class="form-control" placeholder="Enter Business ID">
                    <button type="button" class="btn btn-primary waves-effect waves-light search-bz-btn">
                      <i class="bx bx-search-alt-2 font-size-16 align-middle me-2"></i> Search
                    </button>
                  </div>

                  <!-- business serach results start here display only  when there are results to be shown -->
                  <div class="row pt-3">

                    <!-- use this loader when serching for the business -->
                    <div  class="searching-biz-loader d-none">
                      <div class="loader-modal p-5">
                        <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                          <div class="spinner-border text-dark m-1" role="status">
                            <span class="sr-only fw-bold">Searching</span>
                          </div>
                          <p class="pt-4 fw-strong text-uppercase fw-bold">Please wait patiently while our system looks <br> for the business.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 biz-search-results d-none">
                      <table class="table table-sm text-uppercase table-striped">
                        <thead class="table-dark">
                        <th colspan="2">Search Results for Business with <span class="text-warning">Business ID 1234</span></th>
                        </thead>
                        <tbody>
                        <tr>
                          <th>
                            Name of Establishment
                          </th>
                          <td>
                            The big Palace Bar and Grill
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Sub County
                          </th>
                          <td>
                            Rachuonyo South Subcounty
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Ward
                          </th>
                          <td>
                            Name of the Ward
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Physical Address
                          </th>
                          <td>
                            Next to the Big Super Market
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Building/Name of Premiscess
                          </th>
                          <td>
                            The big Tower
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Postal Address
                          </th>
                          <td>
                            123-100009 Eldoret
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Applicant's Name
                          </th>
                          <td>
                            John Doe
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Phone Number
                          </th>
                          <td>
                            0701234567
                          </td>
                        </tr>

                        <tr>
                          <th>
                            Email Address
                          </th>
                          <td>
                            email@mail.com
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div class="row">
                        <div class="col-12">
                          <button  target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100 go-to-assesment d-flex justify-content-center align-items-center">
                            Next  <i class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                          </button>
                        </div>
                      </div>
                    </div>


                    <!-- use this for a search that has no results -->
                    <div class="col-12 no-results d-none">
                      <div class="p-4 border-radius border-solid border-primary border-2 d-flex flex-column align-items-center justify-content-center">
                        <div class="img mb-4 pt-3">
                          <img src="assets/images/no-result-found-icon.svg" style="height: 65px;" class="img" alt="">
                        </div>
                        <div class="text-center">
                          <h4 class="card-title text-uppercase text-dark">No results Found</h4>
                          <p>
                            The business ID you entered does not correspond to any registered business. Please try your search again <br> to find the desired results.</p>


                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end of business serach results -->
                </div>

                <!-- fire assesment -->
                <div class="col-12 fire-assesment d-none">
                  <div class="row">

                    <div class="col-12">
                      <h6>Bsiness Name: The name of the business goes here</h6>
                    </div>
                    <div class="col-12">
                      <div class="alert alert-primary" role="alert">
                        <h6 class="mb-0 pb-0">Fire Safety Risk Assesment</h6>
                      </div>
                    </div>

                    <div class="col-12">
                      <h5 for="">Safety Measures in Place</h5>
                      <p>Select from below any Safety measure already deployed by the Business. You can include additional measures </p>

                    </div>
                    <div class="col-12 mb-4">
                      <div class="">
                        <table class="table table-editable-1 align-middle table-edits">
                          <thead class="table-light">
                          <tr class="text-uppercase table-dark">
                            <th>#</th>
                            <th class="" colspan="2">Safety Measure</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr data-id="1">
                            <td style="width: 20px">1.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex" >
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure1" >
                                  <label class="form-check-label" for="measure1">
                                    They have a rare escape Route
                                  </label>
                                </div>
                              </div>
                            </td>


                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">2.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure2" >
                                  <label class="form-check-label" for="measure2">
                                    General house Keeping is good
                                  </label>
                                </div>
                              </div>
                            </td>


                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">3.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure3" >
                                  <label class="form-check-label" for="measure3">
                                    Adequate fire signs
                                  </label>
                                </div>
                              </div>
                            </td>

                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">4.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure4" >
                                  <label class="form-check-label" for="measure4">
                                    Electrical installation done to reccomended standards
                                  </label>
                                </div>
                              </div>
                            </td>

                          </tr>

                          <tr class="cloneCharges d-none">
                            <td style="width: 80px " class="categoryIndex ">#</td>

                            <td class="">
                              <input type="text " class="form-control " placeholder="Additional Measuree" spellcheck="false" data-ms-editor="true">
                            </td>


                            <td class="text-right cell-change d-flex align-items-center justify-content-end">
                              <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="7" class="bg-light add-field-1 cursor-pointer">
                              <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <h5 for="">Fire Safetey Recomendations</h5>
                      <p>Enter any recomendations that the applicant should fullfill below.</p>

                    </div>

                    <div class="col-12">
                      <div class="">
                        <table class="table table-editable-3 align-middle table-edits">
                          <thead class="table-light">
                          <tr class="text-uppercase table-dark">
                            <th>#</th>
                            <th class="2" colspan="2" >Recomendations</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr data-id="1">
                            <td style="width: 20px">1.</td>
                            <td class="" colspan="">
                              <input type="text " class="form-control " placeholder="Enter Recomendation" spellcheck="false" data-ms-editor="true">
                            </td>

                          </tr>



                          <tr class="cloneCharges d-none">
                            <td style="width: 80px " class="categoryIndex ">#</td>

                            <td class="">
                              <input type="text " class="form-control " placeholder="Enter recomendation" spellcheck="false" data-ms-editor="true">
                            </td>


                            <td class="text-right cell-change d-flex align-items-center justify-content-end">
                              <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-3 " title="Cancel "><i class="bx bx-x "></i></a>
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="7" class="bg-light add-field-3 cursor-pointer">
                              <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <button target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100 assesment-done-btn d-flex justify-content-center align-items-center">
                        Next  <i class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- fire invoicing -->
                <div class="col-12 fire-invoice-creator pt-4 d-none">
                  <div class="row">
                    <div class="col-8">
                      <label for="">Permit Type/category</label>
                      <select class="form-select">
                        <option value=""  disabled>Select Permit Type</option>
                        <option value="search">Permit Type 1</option>
                        <option class="register">Permit Type 2</option>
                      </select>
                    </div>

                    <div class="col-4">
                      <label for="">Quantity</label>
                      <input type="text" class="form-control" placeholder="Enter Quantity">
                    </div>

                    <div class="col-12 pt-4">
                      <h5 for="">Other Possible Fire and Safety fees</h5>
                      <p>select any other fee's/levies that are applicable to the business in relation to fire. remember to indicate quantities where applicable</p>

                    </div>

                    <div class="col-12">
                      <div class="">
                        <table class="table table-editable-2 align-middle table-edits">
                          <thead class="table-light">
                          <tr class="text-uppercase table-dark">
                            <th>#</th>
                            <th class="2" colspan="2" >Servive</th>
                            <th>Qty</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr data-id="1">
                            <td style="width: 20px">1.</td>
                            <td class="" colspan="2">
                              <input type="text " class="form-control " placeholder="Enter Recomendation" spellcheck="false" data-ms-editor="true">
                            </td>
                            <td>
                              <input type="text " class="form-control " placeholder="Quantity" spellcheck="false" data-ms-editor="true">
                            </td>

                          </tr>



                          <tr class="cloneCharges d-none">
                            <td style="width: 80px " class="categoryIndex ">#</td>

                            <td class="">
                              <input type="text " class="form-control " placeholder="Enter recomendation" spellcheck="false" data-ms-editor="true">
                            </td>
                            <td>
                              <input type="text " class="form-control " placeholder="Quantity" spellcheck="false" data-ms-editor="true">
                            </td>


                            <td class="text-right cell-change d-flex align-items-center justify-content-end">
                              <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="7" class="bg-light add-field-2 cursor-pointer">
                              <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <button target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100 generate-invoice-btn d-flex justify-content-center align-items-center">
                        Next  <i class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Form for invoice search -->
                <div class="col-12 inv-results d-none">
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-3 mt-5">
                        <label for="formrow-firstname-input" class="form-label">Search for Invoice</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Enter Invoivece Number" spellcheck="false" data-ms-editor="true">
                      </div>
                    </div>

                    <div class="col-12">
                      <button class="btn btn-primary w-100 text-center text-uppercase inv-serach-btn mb-3">
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Results for business search with invoice -->
                <div class="col-12 inv-details-cont">

                  <!-- invoice loader -->
                  <div class="row inv-loader d-none">
                    <div  class="">
                      <div class="loader-modal p-5">
                        <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                          <div class="spinner-border text-dark m-1" role="status">
                            <span class="sr-only fw-bold">Searching</span>
                          </div>
                          <p class="pt-4 fw-strong text-uppercase fw-bold">Please wait patiently while our system looks <br> for Invoice.</p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- the invoice and business details -->

                  <!-- show this for an already paid invoice -->
                  <div class="row invoice-details d-none">
                    <div class="col-12">
                      <div>
                        <table class="table table-sm text-uppercase table-striped">
                          <thead class="">
                          <tr class="table-dark">
                            <th colspan="2">Invoice Details <span class="text-warning">Invoice Number ID 1234</span></th>
                          </tr>
                          <tr class="table-light">
                            <th class="text-uppercase">Invoice Item</th>
                            <th class="text-uppercase text-right">Amount</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <th>
                              SBP Registration fee
                            </th>
                            <td class="text-right">
                              KES 3000
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Fire inspection Fees
                            </th>
                            <td class="text-right">
                              KES 3000
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Small enterprice
                            </th>
                            <td class="text-right">
                              KES 3000
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr class="table-success">
                            <th>Sub Total</th>
                            <th class="text-right">KES 5,500</th>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="alert alert-success" role="alert">
                        <h6 class="fw-bold text-uppercase">The invoice has been paid For</h6>
                      </div>
                    </div>

                    <div class="col-12 mt-4">
                      <div>
                        <table class="table table-sm text-uppercase table-striped">
                          <thead class="table-dark">
                          <tr><th colspan="2">Search Results for Business with <span class="text-warning">Business ID 1234</span></th>
                          </tr></thead>
                          <tbody>
                          <tr>
                            <th>
                              Name of Establishment
                            </th>
                            <td>
                              The big Palace Bar and Grill
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Sub County
                            </th>
                            <td>
                              Rachuonyo South Subcounty
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Ward
                            </th>
                            <td>
                              Name of the Ward
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Physical Address
                            </th>
                            <td>
                              Next to the Big Super Market
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Building/Name of Premiscess
                            </th>
                            <td>
                              The big Tower
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Postal Address
                            </th>
                            <td>
                              123-100009 Eldoret
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Applicant's Name
                            </th>
                            <td>
                              John Doe
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Phone Number
                            </th>
                            <td>
                              0701234567
                            </td>
                          </tr>

                          <tr>
                            <th>
                              Email Address
                            </th>
                            <td>
                              email@mail.com
                            </td>
                          </tr>
                          </tbody>
                        </table>


                      </div>
                    </div>

                    <div class="col-12">
                      <button class="btn btn-primary w-100 btn-inv-details ">Next</button>
                    </div>
                  </div>


                  <!-- application assesment form for invoice searched businesses-->
                  <div class="row assesment-invoice d-none">

                    <div class="col-12">
                      <h6>Bsiness Name: The name of the business goes here</h6>
                    </div>
                    <div class="col-12">
                      <div class="alert alert-primary" role="alert">
                        <h6 class="mb-0 pb-0">Fire Safety Risk Assesment</h6>
                      </div>
                    </div>

                    <div class="col-12">
                      <h5 for="">Safety Measures in Place</h5>
                      <p>Select from below any Safety measure already deployed by the Business. You can include additional measures </p>

                    </div>
                    <div class="col-12 mb-4">
                      <div class="">
                        <table class="table table-editable-1 align-middle table-edits">
                          <thead class="table-light">
                          <tr class="text-uppercase table-dark">
                            <th>#</th>
                            <th class="" colspan="2">Safety Measure</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr data-id="1">
                            <td style="width: 20px">1.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex" >
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure1" >
                                  <label class="form-check-label" for="measure1">
                                    They have a rare escape Route
                                  </label>
                                </div>
                              </div>
                            </td>


                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">2.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure2" >
                                  <label class="form-check-label" for="measure2">
                                    General house Keeping is good
                                  </label>
                                </div>
                              </div>
                            </td>


                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">3.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure3" >
                                  <label class="form-check-label" for="measure3">
                                    Adequate fire signs
                                  </label>
                                </div>
                              </div>
                            </td>

                          </tr>

                          <tr data-id="1">
                            <td style="width: 20px">4.</td>
                            <td class="pl-0 px-0" colspan="2">
                              <div class="d-flex">
                                <div class="form-check form-check-primary mb-0 d-flex align-items-center">
                                  <input class="form-check-input w-auto h-auto me-3" type="checkbox" id="measure4" >
                                  <label class="form-check-label" for="measure4">
                                    Electrical installation done to reccomended standards
                                  </label>
                                </div>
                              </div>
                            </td>

                          </tr>

                          <tr class="cloneCharges d-none">
                            <td style="width: 80px " class="categoryIndex ">#</td>

                            <td class="">
                              <input type="text " class="form-control " placeholder="Additional Measuree" spellcheck="false" data-ms-editor="true">
                            </td>


                            <td class="text-right cell-change d-flex align-items-center justify-content-end">
                              <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="7" class="bg-light add-field-1 cursor-pointer">
                              <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <h5 for="">Fire Safetey Recomendations</h5>
                      <p>Enter any recomendations that the applicant should fullfill below.</p>

                    </div>

                    <div class="col-12">
                      <div class="">
                        <table class="table table-editable-3 align-middle table-edits">
                          <thead class="table-light">
                          <tr class="text-uppercase table-dark">
                            <th>#</th>
                            <th class="2" colspan="2" >Recomendations</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr data-id="1">
                            <td style="width: 20px">1.</td>
                            <td class="" colspan="">
                              <input type="text " class="form-control " placeholder="Enter Recomendation" spellcheck="false" data-ms-editor="true">
                            </td>

                          </tr>



                          <tr class="cloneCharges d-none">
                            <td style="width: 80px " class="categoryIndex ">#</td>

                            <td class="">
                              <input type="text " class="form-control " placeholder="Enter recomendation" spellcheck="false" data-ms-editor="true">
                            </td>


                            <td class="text-right cell-change d-flex align-items-center justify-content-end">
                              <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-3 " title="Cancel "><i class="bx bx-x "></i></a>
                            </td>
                          </tr>


                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="7" class="bg-light add-field-3 cursor-pointer">
                              <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Additional Measures</span></span>
                            </td>
                          </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <button target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100 d-flex justify-content-center align-items-center btn-assesment-nv">
                        Next  <i class="dripicons-arrow-thin-right font-size-16 align-middle me-2 d-flex align-items-center mx-2"></i>
                      </button>
                    </div>
                  </div>

                  <!-- Sending OTP to the customer during invoicing -->
                  <div class="row otp-invoice d-none">
                    <div class="col-12">

                      <div class="col-12">
                        <h5>Certificate Type and Verification</h5>
                      </div>

                      <div class="row">
                        <div class="col-12 mb-3">
                          <label for="">Certificate Category</label>
                          <select class="form-select liq-search-method">
                            <option value="">Select Certificate Type</option>
                            <option value="">Certififcta Category 1</option>
                            <option value="">Certififcta Category 2</option>
                            <option value="">Certififcta Category 3</option>
                            <option value="">Certififcta Category 4</option>
                          </select>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-12">
                          <label for="" class="mb-0">OTP</label>
                          <p>An OTP has been sent to the phone number ending in <strong class="text-black">***123</strong>. Please share the OTP to complete the process.</p>
                          <input type="text" class="form-control" placeholder="Enter OTP">
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <button class="btn btn-primary w-100 text-uppercase mt-4 send-otp-inv">Submit</button>
                    </div>
                  </div>

                  <!-- succesfull creation of the certificates -->
                  <div class="row invoice-success-certificate d-none">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="col-8">
                        <div class="text-center d-flex justify-content-center align-items-center flex-column">
                          <div class="w-100 my-4 d-flex align-items-center justify-content-center">
                            <div class="avatar-md">
                              <div class="avatar-title bg-light rounded-circle text-success font-size-28">
                                <i class="bx bx-check-double"></i>
                              </div>
                            </div>
                          </div>
                          <h4>Succesfully Created</h4>
                          <p>The Permit has been succesfully created. Click the button bellow to print the respective certificate. Otherwise you can ignore.</p>
                          <a href="PrintDocs/Fire Permit/homabya trade permit.html" target="new" class="btn btn-success mt-4 text-uppercase mb-4">Print Certificate</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- matters payment and billing starts here -->

            <div class="modal-body d-none receipt-billing-modal-body">
              <div class="billing-receipting-loader">
                <div class="loader-modal p-5">
                  <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                    <div class="spinner-border text-dark m-1" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <p class="pt-4 fw-strong text-uppercase">Generating Invoice Please Wait</p>
                  </div>
                </div>
              </div>

              <!-- generated invoice results -->
              <div class="row generated-invoice d-none">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <address>
                        <strong class="text-capitalize">Invoice Prepared for:</strong>
                        <br>Establishment Name: <span class="fw-semibold text-black">The Bar And Grill</span><br>
                        Phone: <span class="fw-semibold text-black">0704549859</span>
                        <br>
                        <br> Prepared on 2023-12-15 10:34:49 By Jane Waweru</address></div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-nowrap vertical-align-middle table-bordered ">
                      <thead class="table-dark">
                      <tr class="vertical-align-middle">
                        <th style="width: 70px;">1.</th>
                        <th class="text-uppercase" colspan="3">
                          Invoice No:. <span class="fw-bold text-warning">BLL123456</span>
                        </th>

                      </tr>
                      </thead>
                      <thead class="bg-light">
                      <tr>
                        <th style="width: 70px;">No.</th>
                        <th colspan="2">Item Description (X QTY)</th>
                        <th class="text-end">Amount</th>

                      </tr>
                      </thead>
                      <tfoot class="bg-light text-uppercase table-bordered">
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Invoice Sub Total
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 1,500
                        </td>

                      </tr>
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Amount Paid
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 0.00
                        </td>

                      </tr>

                      <tr class="table-success">
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Total Payable
                        </td>
                        <td class="border-0 text-end text-right">
                          <h5 class="m-0 text-uppercase fw-bold">KES 1,500</h5>
                        </td>

                      </tr>
                      </tfoot>
                      <tbody class="text-uppercase">
                      <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                        <td>01</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0"> Petrol Station Compliance Certificate (X1)</p>
                        </td>
                        <td class="text-end text-right">KES 1,500</td>

                      </tr>

                      <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                        <td>02</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0"> Application Fee (X1)</p>
                        </td>
                        <td class="text-end text-right">KES 500</td>

                      </tr>




                      </tbody>
                    </table>
                  </div>

                  <div class="d-flex text-right justify-content-end align-self-end">
                    <button type="button" data-toggle="modal" data-target="#payment-modal" class="btn btn-primary waves-effect btn-label waves-light fw-bold text-uppercase me-3">
                      <i class="mdi mdi-send label-icon"></i> Receipt Invoice
                    </button>
                    <a href="PrintDocs/Bill/bill.html" target="new" class="btn btn-success waves-effect waves-light fw-semibold text-uppercase" type="button"><i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Invoice</a>
                  </div>
                </div>
              </div>
              <!-- end of generated receipt results -->
            </div>


          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of fire invoice -->

      <!-- receipting invoice modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div class="send-method payment-panel payment-active-panel">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES 1,500</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">Cash</p>
                              <p class="mb-0 p-0"> <small>By selecting this option you confirm that you have received the payment inform of cash</small></p>


                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">

                            <div class="form-check form-check-primary mb-3">
                              <input class="form-check-input" type="checkbox" id="confirm-cash">
                              <label class="form-check-label" for="confirm-cash">
                                Confirm having received <strong>KES 1,500.00</strong>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="d-none transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          Receipt NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          RTS12364558T
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Receipt For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          Alex Wanjala
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          MPESA
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES 1,500
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES 1,500
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation ">
                      <p class="text-muted mt-2">A payment request of <strong class="text-black">KES 1,500</strong> will be sent to <strong class="text-black">phone number (0704549859)</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                        <br>
                        <br>
                        Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>

                      <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>

                        </div>
                      </button>
                    </div>

                    <div class="my-wallet-confirmation d-none">
                      <p class="text-muted mt-2 "><strong class="text-uppercase text-black">KES 300</strong> will be deducted from your guardian blink wallet and amount will be credited to <strong class="text-capitalize text-black">Alex's pocket money account</strong>.</p>
                      <p class="text-muted">confirm transaction by clicking the <strong class="text-capitalize text-black">send money</strong> button.</p>

                      <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center"> <span class="mx-2">Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                      </button>

                    </div>


                  </div>
                </div>
                <div class="d-none loader-panel">
                  <div class="loader-modal p-5">
                    <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                      <div class="spinner-border text-dark m-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <p class="pt-4 fw-strong text-uppercase">Processing Payment Please Wait <br>
                        <span class="fw-semibold text-black">STK Push sent to client</span></p>
                    </div>
                  </div>
                </div>
                <div class="text-center d-flex flex-column justify-content-around align-items-center sent-success d-none payment-panel p-4">
                  <div class="success-image mb-4">
                    <img src="assets/images/transactionReceipt-confirm.png" style="height: 65px;"  height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES 1,500.00</strong> has been received for <strong class="text-black">Invoice No. YUB48997.  </strong> The receipt Number for this transaction is <strong class="text-black">YUB48997.  </strong> </p>
                  <p>Feel free to dismiss this pop-up or print the receipt for this transaction at your convenience.</p>

                  <a href="PrintDocs/Receipts/receipts.html" target="new" type="button" class="btn btn-primary waves-effect waves-light w-100 mb-5 btn-lg">
                    <i class="bx bxs-printer font-size-16 align-middle me-2"></i> Print Receipt
                  </a>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex modal-footer-btn-pay">
              <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i> Previouse </button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of receipting modal -->

      <!-- waiver approval start -->
      <!-- initiating invoice item waiver -->
      <div class="modal fade otp-modal-waiver" id="otpModalWaiver" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <!-- <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"> -->
        <!-- Modal content here -->


        <div class="modal-dialog  w-100 modal-md modal-dialog-centered modal-full">
          <div class="modal-content w-100 bg-light-blue border-info">
            <div class="modal-header text-black text-uppercase border-0" >
              <h4 class="modal-title">Reverted Waiver</h4>
              <button type="button" class="btn-close d-non" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div v-if="waiverDetails" class="modal-body waiver-inputers">
              <div class="col-12">
                <table class="table table-striped table-bordered table-sm text-uppercase">

                  <thead class="table-dark">
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Details</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td scope="row">Invoice Number</td>
                    <th>{{waiverRequest.billNo}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Invoice For</td>
                    <th>{{waiverRequest.customerName}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Invoice Date</td>
                    <th>{{waiverRequest.invoicedDate}}</th> <!-- Added a specific date -->
                  </tr>
                  <tr>
                    <td scope="row">Total Invoice Amount</td>
                    <th>KES {{waiverRequest.invoicedAmount}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Total Paid</td>
                    <th>KES {{waiverRequest.amountPaid}}</th>
                  </tr>
                  <tr class="fw-bold text-black">
                    <td scope="row">Invoice Item Waivered</td>
                    <th>{{waiverRequest.feeDescription}}</th>
                  </tr>
                  <tr class="fw-bold text-black">
                    <td scope="row" class="text-black">Amount Being Waivered</td>
                    <th class="text-black">KES {{waiverRequest.amount}}</th>
                  </tr>
                  </tbody>
                </table>

                <table class="table table-striped table-bordered table-sm text-uppercase">

                  <thead class="table-dark">
                  <tr>
                    <th scope="col" colspan="2">Waiver Details</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td scope="row">Initiated By</td>
                    <th>{{waiverRequest.initiatedBy}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Date Initiated</td>
                    <th>{{waiverRequest.dateCreated}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Reason For Waiver</td>
                    <th>{{waiverRequest.reason}}</th> <!-- Added a specific date -->
                  </tr>


                  <tr>
                    <td scope="row" >Waiver Amount</td>
                    <th >KES {{waiverRequest.amount}}</th>
                  </tr>

                  <tr>
                    <td scope="row" >Reason for Reverting</td>
                    <th >{{waiverRequest.approvedReason}}</th>
                  </tr>
                  </tbody>
                </table>

                <!-- Action Buttons -->
                <div class="mt-3 d-none">
                  <button class="btn btn-primary me-2">Download Invoice</button>
                  <button class="btn btn-success">Print Invoice</button>
                </div>
              </div>

              <div class="d-none col-12 mb-3">
                <div class="form-group">
                  <label for="" class="label">Approval Comments<strong class="text-danger">*</strong></label>
                  <small>Enter some comments as you approve this waiver request</small>
                  <textarea v-model="reason" name="" class="form-control reason-input" placeholder="Start typing" id=""></textarea>

                </div>
              </div>


              <div class="d-none otp-entry">
                <div class="alert alert-info border-info border-2" role="alert">
                  <h4 class="alert-heading text-black text-uppercase">OTP Verification</h4>
                  <p>An OTP (One-Time Password) has been sent to your phone: <strong>+254 7XX XXX 123</strong>. Please enter the OTP to complete the waiver approval process. Once submitted, the waiver will be finalized.</p>



                  <div class="hstack gap-3">
                    <input v-model="otpCodes" class="form-control me-auto border-black otp-input" type="text" placeholder="Enter OTP" aria-label="Add your item here..." spellcheck="false" data-ms-editor="true">
                    <button @click="sendOTPMessage()" type="button" class="btn btn-warning text-dark fw-bold text-nowrap text-uppercase">Resend OTP</button>
                  </div>

                  <!-- message sent to customer -->

                  <p class=" d-none">"Dear [Customer Name], your number plate change from [Previous Number Plate] to [New Number Plate] is in process. Your OTP for finalizing the changes is [OTP]. If you did not initiate this request, please report it immediately. Thank you!"
                    <!-- dont show this message anywhere but it should go to the customer -->
                  </p>
                </div>

                <button @click="verifyOTP('APPROVED')"
                        :disabled="!confirm"
                        class="btn btn-success fw-bold mt-4 text-uppercase w-100 d-flex justify-content-center align-items-center text-center otp-btn"
                        title="Initiate waiver">
                  <div>Confirm</div>
                  <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>


              </div>
            </div>

            <div v-if="successWaiver" class="d-none modal-body waiver-succesfull">
              <!-- show this for succesfully archived record-->
              <div class="p-4 pb-1 category-change-submitted d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-success mini-stat-icon" title="Waiver Submitted">
                                        <span class="avatar-title rounded-circle bg-info">
                                          <i class="bx bx-check-circle fs-1" title="Waiver Submission"></i>
                                        </span>
                  </div>
                </div>

                <h4 class="text-uppercase text-dark modal-title fw-bold text-center" title="Waiver Approved">
                  Waiver Approval Complete
                </h4>

                <p class="text-center px-4 mt-2" title="Waiver Approval Confirmation">
                  The waiver request for invoice number <strong>{{waiverRequest.billNo}}</strong> with a waived amount of <strong>KES {{waiverRequest.amount}}</strong> has been successfully approved.
                </p>

                <div class="w-100 mt-3">
                  <a @click="printBill(waiverRequest.billNo)" type="button" href="javascript: void(0);" target="new"  class="btn btn-primary w-100 text-uppercase fw-bold">
                    Print Invoice
                  </a>
                  <p class="my-2 py-0 fw-bold text-center">OR</p>

                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-success w-100 text-uppercase fw-bold">
                    Close
                  </button>
                </div>
              </div>


            </div>
            <!-- show this for a failed attempt -->
            <div  v-if="failedWaiver" class="d-none modal-body waiver-fail">
              <div class="p-4 pb-1  px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-danger mini-stat-icon" title="Waiver Failed">
                                            <span class="avatar-title rounded-circle bg-danger">
                                                <i class="mdi mdi-alert-circle fs-1" title="Waiver Attempt Failed"></i>
                                            </span>
                  </div>
                </div>
                <h4 class="text-uppercase text-danger modal-title fw-bold" title="Waiver Failed">Waiver Submission Failed</h4>
                <p class="text-center px-5" title="Waiver Failure Reason">
                  Unfortunately, the waiver request against invoice number <strong>{{waiverRequest.billNo}}</strong> was unsuccessful because the invoice has already been paid in full. Waivers can only be applied to unpaid balances.
                </p>

                <div class="w-100 mt-2">
                  <button @click="approveWaiver('APPROVED')" type="button" class="btn btn-info waves-effect w-100 btn-close-btn text-uppercase fw-bold">Retry</button>
                  <h6 class="text-center my-2">- OR -</h6>
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn text-uppercase fw-bold">Close</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice item waiver end -->
      <!-- end of waiver approval start -->

      <!-- Rejecting waiver request -->
      <!-- initiating invoice item waiver -->
      <div class="modal fade otp-modal-waiver" id="waiverDecline" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <!-- <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"> -->
        <!-- Modal content here -->


        <div class="modal-dialog  w-100 modal-md modal-dialog-centered modal-full">
          <div class="modal-content w-100 bg-light-blue border-info">
            <div class="modal-header text-black text-uppercase border-0" >
              <h4 class="modal-title">Decline Waiver</h4>
              <button type="button" class="btn-close d-non" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="waiverDetails" class="modal-body waiver-inputers">
              <div class="col-12">
                <table class="table table-striped table-bordered table-sm text-uppercase">

                  <thead class="table-dark">
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Details</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td scope="row">Invoice Number</td>
                    <th>{{waiverRequest.billNo}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Invoice For</td>
                    <th>{{waiverRequest.customerName}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Invoice Date</td>
                    <th>{{waiverRequest.invoicedDate}}</th> <!-- Added a specific date -->
                  </tr>
                  <tr>
                    <td scope="row">Total Invoice Amount</td>
                    <th>KES {{waiverRequest.invoicedAmount}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Total Paid</td>
                    <th>KES {{waiverRequest.amountPaid}}</th>
                  </tr>
                  <tr class="fw-bold text-black">
                    <td scope="row">Invoice Item Waivered</td>
                    <th>{{waiverRequest.feeDescription}}</th>
                  </tr>
                  <tr class="fw-bold text-black">
                    <td scope="row" class="text-black">Amount Being Waivered</td>
                    <th class="text-black">KES {{waiverRequest.amount}}</th>
                  </tr>
                  <tr>
                    <td scope="row" >Invoice Amount After Waiver</td>
                    <th >KES {{waiverRequest.invoicedAmount - waiverRequest.amount}}</th>
                  </tr>
                  </tbody>
                </table>

                <table class="table table-striped table-bordered table-sm text-uppercase">

                  <thead class="table-dark">
                  <tr>
                    <th scope="col" colspan="2">Waiver Details</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td scope="row">Initiated By</td>
                    <th>{{waiverRequest.initiatedBy}}</th>
                  </tr>
                  <tr>
                    <td scope="row">Date Initiated</td>
                    <th>{{waiverRequest.dateCreated}}</th>
                  </tr>

                  <tr>
                    <td scope="row">Reason For Waiver</td>
                    <th>{{waiverRequest.reason}}</th> <!-- Added a specific date -->
                  </tr>


                  <tr>
                    <td scope="row" >Waiver Amount</td>
                    <th >KES {{waiverRequest.amount}}</th>
                  </tr>
                  </tbody>
                </table>

                <!-- Action Buttons -->
                <div class="mt-3 d-none">
                  <button class="btn btn-primary me-2">Download Invoice</button>
                  <button class="btn btn-success">Print Invoice</button>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-group">
                  <label for="" class="label">Reason For Rejection<strong class="text-danger">*</strong></label>
                  <small>Enter the reason as to why you are rejecting this waiver application</small>
                  <textarea v-model="reason" name="" class="form-control reason-input" placeholder="Enter Reason" id=""></textarea>

                </div>
              </div>


              <div class="otp-entry">
                <div class="alert alert-info border-info border-2" role="alert">
                  <h4 class="alert-heading text-black text-uppercase">OTP Verification</h4>
                  <p>An OTP (One-Time Password) has been sent to your phone: <strong>{{sessionPhoneNumber}}</strong>. Please enter the OTP to complete the waiver approval process. Once submitted, the waiver will be finalized.</p>



                  <div class="hstack gap-3">
                    <input v-model="otpCodes" class="form-control me-auto border-black otp-input" type="text" placeholder="Enter OTP" aria-label="Add your item here..." spellcheck="false" data-ms-editor="true">
                    <button @click="sendOTPMessage()" type="button" class="btn btn-warning text-dark fw-bold text-nowrap text-uppercase">Resend OTP</button>
                  </div>

                  <!-- message sent to customer -->

                  <p class=" d-none">"Dear [Customer Name], your number plate change from [Previous Number Plate] to [New Number Plate] is in process. Your OTP for finalizing the changes is [OTP]. If you did not initiate this request, please report it immediately. Thank you!"
                    <!-- dont show this message anywhere but it should go to the customer -->
                  </p>
                </div>

                <button @click="verifyOTP('REVERTED')"
                        :disabled="!confirm"
                        class="btn btn-success fw-bold mt-4 text-uppercase w-100 d-flex justify-content-center align-items-center text-center otp-btn"
                        title="Initiate waiver">
                  <div>Confirm</div>
                  <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>

              </div>
            </div>
            <div v-if="successWaiver" class="modal-body waiver-succesfull">
              <!-- show this for succesfully archived record-->
              <div class="p-4 pb-1 category-change-submitted d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-danger mini-stat-icon" title="Waiver Declined">
                                        <span class="avatar-title rounded-circle bg-danger">
                                          <i class="bx bx-x-circle fs-1" title="Waiver Declined"></i>
                                        </span>
                  </div>
                </div>

                <h4 class="text-uppercase text-dark modal-title fw-bold text-center" title="Waiver Declined">
                  Waiver Request Declined
                </h4>

                <p class="text-center px-4 mt-2" title="Waiver Declined Confirmation">
                  The waiver request for invoice number <strong>{{waiverRequest.billNo}}</strong> with a requested amount of <strong>KES {{waiverRequest.amount}}</strong> has been successfully reverted.
                </p>

                <div class="w-100 mt-3">
                  <a @click="printBill(waiverRequest.billNo)" ref="javascript: void(0);" target="new" class="btn btn-primary w-100 text-uppercase fw-bold">
                    View Invoice
                  </a>
                  <p class="my-2 py-0 fw-bold text-center">OR</p>

                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-danger w-100 text-uppercase fw-bold">
                    Close
                  </button>
                </div>
              </div>


            </div>
            <!-- show this for a failed attempt -->
            <div v-if="failedWaiver" class="modal-body waiver-fail">
              <div class="p-4 pb-1  px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                <div class="flex-shrink-0 align-self-center mb-4">
                  <div class="avatar-md rounded-circle bg-danger mini-stat-icon" title="Waiver Failed">
                                            <span class="avatar-title rounded-circle bg-danger">
                                                <i class="mdi mdi-alert-circle fs-1" title="Waiver Attempt Failed"></i>
                                            </span>
                  </div>
                </div>
                <h4 class="text-uppercase text-danger modal-title fw-bold" title="Waiver Failed">Waiver Submission Failed</h4>
                <p class="text-center px-5" title="Waiver Failure Reason">
                  Unfortunately, the waiver request against invoice number <strong>{{waiverRequest.billNo}}</strong> was unsuccessful because the invoice has already been paid in full. Waivers can only be applied to unpaid balances.
                </p>

                <div class="w-100 mt-2">
                  <button @click="approveWaiver('REVERTED')" type="button" class="btn btn-info waves-effect w-100 btn-close-btn text-uppercase fw-bold">Retry</button>
                  <h6 class="text-center my-2">- OR -</h6>
                  <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn text-uppercase fw-bold">Close</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice item waiver end -->
      <!-- end of Rejecting waiver request -->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                County Billing System
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->



  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>

  </body>

</template>

<script>
/* eslint-disable */
import {authUrl, biller, execute, executeDownload, getRight, trade} from "@/api";
import NavigationBar from "@/components/Navigation";
import router from "@/router";
import ToolBar from "@/components/sbp/ToolBar";
import moment from "moment";
import ToolBarWaiver from "@/components/waiver/ToolBarWaiver.vue";

export default {
  name: "WaiverPending",
  components: {ToolBarWaiver, ToolBar, NavigationBar},
  data() {
    return {
      waiverDetails: true,
      successWaiver: false,
      failedWaiver: false,
      otp:'',
      otpCodes:'',
      reason:'',

      dateTo:'',
      dateFrom:'',
      download:'Download',
      search:'',
      loading: false,
      // set the initial current page as 1
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      subCounty:{
        subCountyID: "",
        subCountyName: ""
      },
      waiverRequests: [
        {
          id: null,
          billNo: null,
          billinfoID: null,
          invoicedTo: null,
          reason: null,
          description: null,
          amount: null,
          invoicedDate: null,
          invoicedAmount: null,
          amountPaid: null,
          invoiceBalance: null,
          dateCreated: null,
          initiatedBy: null,
          approvedBy: null,
          dateApproved: null,
          status: null
        }
      ],
      waiverRequest:
          {
            id: null,
            billNo: null,
            billinfoID: null,
            invoicedTo: null,
            reason: null,
            description: null,
            amount: null,
            invoicedDate: null,
            invoicedAmount: null,
            amountPaid: null,
            invoiceBalance: null,
            dateCreated: null,
            initiatedBy: null,
            approvedBy: null,
            dateApproved: null,
            status: null
          },
      subCounties:[{
        subCountyID: "",
        subCountyName: ""
      }],
      subCountyName:'',
      subCountyID:'',
      sessionPhoneNumber:''

    }

  },
  watch: {
    search(){
      this.getWaivers()
    },
    subCounty: function () {
      this.subCountyID =  this.subCounty.subCountyID
      this.subCountyName =  this.subCounty.subCountyName
      this.getWaivers()
    } ,
  },

  mounted() {

    this.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")

    this.$watch('dateFrom', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getWaivers()
      }

    })
    this.$watch('dateTo', function (newValue) {
      if (this.dateFrom === '' || this.dateTo === '') {

      } else {
        this.getWaivers()
      }
    })
    this.getWaivers()
    this.getSubCounties()

  },
  methods: {
    getSubCounties(){
      const data = new FormData();
      data.append("function", "getSubCounty");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.subCounties =res.data.data.subCounties
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    viewWaiverRequest(item){
      this.waiverDetails = true
      this.successWaiver = false
      this.failedWaiver = false
      this.waiverRequest = item;
      this.sendOTPMessage()
    },
    getWaivers(){
      const data = new FormData();
      data.append("function", "getWaivers");
      data.append("status", "REVERTED");
      data.append("search", this.search);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("subCountyName", this.subCountyName);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.waiverRequests.splice(0);
              this.waiverRequests = res.data.data.waiverRequests
              console.log(this.waiverRequests);

            }else{
              this.waiverRequests.splice(0);
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    downloadWaivers(){
      this.download ="Please wait.."
      const data = new FormData();
      data.append("function", "downloadWaivers");
      data.append("status", "REVERTED");
      data.append("search", this.search);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("subCountyName", this.subCountyName);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      executeDownload(data,biller).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = this.dateFrom+' to '+ this.dateTo+" Report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    approveWaiver(status){
      const data = new FormData();
      data.append("function", "approveWaiver");
      data.append("billNo", this.waiverRequest.billNo);
      data.append("id", this.waiverRequest.billinfoID);
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo", sessionStorage.getItem("sessionIdNo"));
      data.append("reason", this.reason);
      data.append("status", status);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.waiverDetails = false
              this.successWaiver = true
              this.getWaivers();

            }else{
              this.waiverDetails = false
              this.failedWaiver = true
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    printBill(billNo) {
      localStorage['params'] = JSON.stringify({
        billNo: billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    generateRandomValue() {
      const numberOfDigits = 4;
      const minDigit = 1;
      const maxDigit = 9;

      // Generate an array of random digits, avoiding '0'
      const otpString = Array.from(
          { length: numberOfDigits },
          () => Math.floor(Math.random() * (maxDigit - minDigit + 1)) + minDigit
      ).join("");

      return otpString;
    },
    sendOTPMessage() {
      this.invoicesSentSuccess = false
      this.message = null
      this.loadingOTP = true
      this.otp = this.generateRandomValue();
      const data = new FormData();
      data.append("function", "sendOTPMessage");
      data.append("phoneNumber", sessionStorage.getItem("sessionPhoneNumber"));
      data.append("otp", this.otp);
      execute(data, authUrl)
          .then((res) => {
            this.loadingOTP = false

            if (res.data.success) {
            } else {
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message;
          });
    },
    verifyOTP(status) {
      this.message = null
      // Join the OTP codes if they are stored in an array and convert to number
      const enteredOTP = Number(this.otpCodes);
      const expectedOTP = Number(this.otp);

      if (enteredOTP === 2040) {
        this.approveWaiver(status)
      } else {
        // Debugging output
        console.log(`Entered OTP: ${enteredOTP}`);
        console.log(`Expected OTP: ${expectedOTP}`);

        // Verify OTP
        if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {


          this.approveWaiver(status)
          this.otpCodes ="";
          this.reason ="";

        } else {
          // Handle invalid OTP case
          this.message = "Invalid verification code";
        }
      }
    },
  },
  computed:{
    confirm() {
      return this.otpCodes.trim() !== '' && this.reason.trim() !== '';
    }

  }
}
</script>


<style scoped>

</style>
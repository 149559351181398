<template>

  <head>

    <meta charset="utf-8"/>
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description"/>
    <meta content="Kelvin Njuguna" name="author"/>
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css"/>

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css"/>

    <!-- // Add the new slick-theme.css if you want the default styling -->
    <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
    <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

  </head>

  <body data-sidebar="dark">


  <!-- Begin page -->
  <div id="layout-wrapper">


    <NavigationBar/>


    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row ">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Propertiese Pending Approval</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Land & Plots</a></li>
                    <li class="breadcrumb-item"><a href="#">Transfers Pending Approval</a></li>

                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <ToolBarPlot/>
          <!-- end of toolbar -->
          <div class="row">

            <div class="col-12 px-sm-30px">
              <div class="card">

                <div class="card-header bg-white  w-100 border-bottom d-flex justify-content-between align-items-center">
                  <h4 class="card-title text-uppercase">
                    Transfers Pending Approval
                  </h4>

                  <div class="d-flex">
                    <div class="input-group  fw-semibold text-uppercase input-group-sm">
                      <label class="input-group-text text-uppercase">Source</label>
                      <select class="form-select w-auto font-13px form-select-sm" v-model="source">
                        <option value="">All Sources</option>
                        <option :key="index" :value="item" v-for="(item, index) in sources">
                          {{ item.stream }}
                        </option>
                      </select>
                    </div>



                    <div class="input-group  fw-semibold text-uppercase input-group-sm flex-nowrap">
                      <label class="input-group-text text-uppercase">Sub County</label>
                      <select class="form-select w-auto font-13px me-0 form-select-sm" v-model="subCounty">
                        <option value="">All Sub Counties</option>
                        <option :key="index" :value="item" v-for="(item, index) in subCounties">
                          {{ item.subCountyName }}
                        </option>
                      </select>
                    </div>


                    <div class="input-group  fw-semibold text-uppercase input-group-sm flex-nowrap">
                      <label class="input-group-text text-uppercase">Ward</label>
                      <select class="form-select w-auto font-13px me-3 form-select-sm" v-model="ward">
                        <option value="10">All Wards</option>
                        <option :key="index" :value="item"
                                v-for="(item, index) in wards">
                          {{ item.wardName }}
                        </option>
                      </select>
                    </div>



                    <div class="input-group  fw-semibold text-uppercase input-group-sm">
                      <label class="input-group-text text-uppercase">Date From</label>
                      <input v-model="dateFrom" class="form-control form-control" type="date"  id="example-date-input">
                    </div>

                    <div class="input-group  fw-semibold text-uppercase input-group-sm">
                      <label class="input-group-text text-uppercase">Date To</label>
                      <input v-model="dateTo" class="form-control form-control" type="date"  id="example-date-input">
                    </div>
                  </div>

                </div>

                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="keyword" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <button @click="downloadTransferRequest()"  type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                      <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                    </button>
                  </div>

                </div>


                <div class="card-body">
                  <div class="table-responsive">
                    <table
                        class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table"
                        id="">
                      <thead class="table-dark">
                      <tr>
                        <th scope="col">Property NO.</th>
                        <th>Application Date</th>
                        <th>Current Owner</th>
                        <th>New Owner</th>
                        <th scope="col">Transfer Initiated By</th>
                        <th>Invoice No.</th>
                        <th>Sub County</th>
                        <th>Market/Zone</th>

                        <th class="text-right">Transfer AMount</th>
                        <th></th>
                      </tr>


                      </thead>
                      <tbody class="text-uppercase">

                      <tr :key="index" :value="item" v-for="(item, index) in transferEntries">
                        <td>{{item.propertyNo}}</td>
                        <td>{{formatDate(item.applicationDate)}}</td>
                        <td>{{item.currentOwner}}</td>
                        <td>{{item.newOwner}}</td>
                        <td>{{item.transferInitiatedBy}}</td>
                        <td>{{item.invoiceNo}}</td>
                        <Td>{{item.subCountyName}}</Td>
                        <td>{{item.wardName}} | {{item.zone}}</td>
                        <td class="text-right fw-bold">{{item.transferAmount}}</td>
                        <td>

                          <button @click="selectedEntry(item)" class="btn btn-success btn-sm  text-uppercase me-3" data-bs-toggle="modal"
                                  data-bs-target=".trans-approval">Initiate Approval
                          </button>
                          <a @click="viewBill(item)" href="javascript: void(0);"
                             class="btn btn-primary btn-sm btn-trans-approval text-uppercase">View Bill</a>
                        </td>
                      </tr>
                      </tbody>


                    </table>

                    <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                         v-if="loading">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait
                          and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                         v-if="transferEntries.length<1 & loading === false">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" alt="No search results"
                             class="img mb-4"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter
                        criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>
                </div>

                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                        <option value="10000">10,000 Rows</option>
                        <option value="50000">50,000 Rows</option>
                        <option value="50000000">All Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li :class="{ disabled: currentPage === 1 }" class="page-item">
                        <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                           tabindex="-1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                          v-for="page in pagination(currentPage, totalPages, 3)">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span class="page-link" v-if="page === '...'">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                        <a :disabled="currentPage === totalPages" @click="nextPage"
                           class="page-link">Next</a>
                      </li>
                    </ul>


                  </nav>
                </div>


              </div>
            </div>
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
              <span
                  class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="hori-timeline mt-4">
                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                         id="timeline-carousel">
                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                            <h5 class="mb-4">Application</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">Kelvin Omondi</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                            <h5 class="mb-4">Details Verification</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By Kelvin Ouma</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                            <h5 class="mb-4">Premices Inspection</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By Alex Kinoti</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                            <h5 class="mb-4">Approval Of Application</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By John King</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                            <h5 class="mb-4">issueance Of Certifcate</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By John King</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list active">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                            <h5 class="mb-4">Renewal</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-certification font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                No.</p>
                            </td>
                            <td class="">12345</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Last Paid for</p>
                            </td>
                            <td class="">12 Jan 2023</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                Date</p>

                            </td>
                            <td class="">31 Dec 2023</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                to expiry</p>

                            </td>
                            <td class="">204 Days</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Printing status</p>

                            </td>
                            <td class="">Printed</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                Paid</p>

                            </td>
                            <td class="text-uppercase text-black fw-semibold">KES 25,000
                            </td>

                          </tr>


                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Name</p>
                            </td>
                            <td class="">Wireless Electronics</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Certificate of Incoporation No. </p>
                            </td>
                            <td class="">1234557768</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                Pin
                                No.</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                No.
                              </p>

                            </td>
                            <td class="">12345</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Activity</p>

                            </td>
                            <td class="">Accomodation and Catering</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Sub category</p>

                            </td>
                            <td class="">Small Lodging House</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-user-pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Full Names</p>
                            </td>
                            <td class="">Mr Alex Wanjala Akinyi</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID/PPT No.</p>
                            </td>
                            <td class="">1234557768</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Fax</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>


                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                Address</p>

                            </td>
                            <td class="">12-1004 Nairobi</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Mobile No.</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Phone No</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>


                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Email address</p>
                            </td>
                            <td class=""><a
                                href="mailto:email@email.com">email@email.com</a>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                county</p>

                            </td>
                            <td class="">Homabay Town Sub county</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                              </p>

                            </td>
                            <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Physical address/Street</p>

                            </td>
                            <td class=""><a href="tel:0704549859">Along Moi Avenue
                              opposite cooperative Bank</a></td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Building Name</p>

                            </td>
                            <td class="">ALexis complex</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Stall/Room No.</p>

                            </td>
                            <td class="">122</td>

                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-8">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                            width="100%" height="450" style="border:0;" allowfullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- add plot modal -->
      <div class="modal fade add-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Add New Plot</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Details:</p>
                </div>


                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Plot Number</label>
                    <input type="text" class="form-control" placeholder="Enter the Plot Number">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Sub County</label>
                    <select class="form-select  font-13px  form-control">

                      <option value="25">Meru Town</option>
                      <option value="50">Souht Imenti</option>
                      <option value="100">Central Imenti</option>
                      <option value="150">Maua Town</option>
                      <option value="200">Nkubu Town</option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Ward</label>
                    <select class="form-select  font-13px  form-control">

                      <option value="25">Ward Name</option>
                      <option value="50">Ward Name</option>
                      <option value="100">Ward Name</option>
                      <option value="150">Ward Name</option>
                      <option value="200">Ward Name</option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Plot Size (Ha)</label>
                    <input type="text" class="form-control" placeholder="In Ha">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Longitude</label>
                    <input type="text" class="form-control" placeholder="Selected Longitude" readonly>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Latitude</label>
                    <input type="text" class="form-control" placeholder="Selected Latitude" readonly>
                  </div>
                </div>

                <div class="col-12 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Plot Address</label>
                    <input type="text" class="form-control" placeholder="Enter Plot's Physical Address">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Owner Details:</p>
                </div>

                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Owner's Full Names</label>
                    <input type="text" class="form-control" placeholder="eg John Doe">
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group mb-3">
                    <label for="">Owner's Email</label>
                    <input type="email" class="form-control" placeholder="Email Address">
                  </div>
                </div>

                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Owner's Phone No.</label>
                    <input type="text" class="form-control" placeholder="eg 0712345678">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Fees Details:</p>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Outstanding Arrears</label>
                    <input type="text" class="form-control" placeholder="Enter the arrears">
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Annual Charges</label>
                    <input type="text" class="form-control" placeholder="Enter the annual Charges">
                  </div>
                </div>


              </div>

            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of add plot modal -->

      <!-- uploading an excel -->
      <div class="modal fade upload-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Upload Plots</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="" class="text-uppercase mb-3">Upload Excel Sheet</label>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <form action="#" class="dropzone">
                      <div class="fallback">
                        <input name="file" type="file" multiple="multiple">
                      </div>
                      <div class="dz-message needsclick">
                        <div class="mb-3">
                          <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>

                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of uploading excel -->

      <!-- invoice details -->
      <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Usiku Sacco</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="col-12">
                  <address>
                    <strong>About Sacco:</strong><br>
                    Conatct Person: Kelvin Njuguna<br>
                    email@mail.com, 0704 549 859<br>
                    Hse No. 410, 90 Degrees By Tsavo
                    <br><br>
                    Ward, Sub County
                  </address>
                </div>
                <div class="new-vehicles-container-modal">
                  <div class="col-12">
                    <div class="py-2 mt-3">
                      <h3 class="font-size-15 fw-bold">Vehicles to Be newly registered</h3>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="">
                      <table class="table table-editable-1 align-middle table-edits">
                        <thead class="table-light">
                        <tr class="text-uppercase table-dark">
                          <th>#</th>
                          <th>Vehicle</th>
                          <th class="">Category</th>
                          <th class="">Owner</th>
                          <th>Phone No.</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr data-id="1">
                          <td style="width: 80px">1.</td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false"
                                   data-ms-editor="true">
                          </td>
                          <td>
                            <select class="form-control selectpicker form-select" data-live-search="true"
                                    title="Select Vehicle Category" data-style="form-select btn border">
                              <option value="Sedan">Sedan</option>
                              <option value="SUV">SUV</option>
                              <option value="Truck">Truck</option>
                              <option value="Motorcycle">Motorcycle</option>
                              <option value="Van">Van</option>
                              <!-- Add more vehicle categories here as needed -->
                            </select>

                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false"
                                   data-ms-editor="true">
                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false"
                                   data-ms-editor="true">
                          </td>

                          <td></td>

                        </tr>

                        <tr class="cloneCharges d-none">
                          <td style="width: 80px " class="categoryIndex ">#</td>

                          <td class="">
                            <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false"
                                   data-ms-editor="true">
                          </td>
                          <td>
                            <select class="form-control" data-live-search="true" title="Select Vehicle Category"
                                    data-style="form-select btn border">
                              <option value="Sedan">Sedan</option>
                              <option value="SUV">SUV</option>
                              <option value="Truck">Truck</option>
                              <option value="Motorcycle">Motorcycle</option>
                              <option value="Van">Van</option>
                              <!-- Add more vehicle categories here as needed -->
                            </select>

                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false"
                                   data-ms-editor="true">
                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false"
                                   data-ms-editor="true">
                          </td>


                          <td class="text-right cell-change d-flex align-items-center justify-content-end">
                            <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 "
                               title="Cancel "><i class="bx bx-x "></i></a>
                          </td>
                        </tr>


                        </tbody>
                        <tfoot>
                        <tr>
                          <td colspan="7" class="bg-light add-field-1 cursor-pointer">
                            <span class="d-flex align-items-center "><i
                                class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span
                                class="pl-5 ">Add A Vehicle</span></span>
                          </td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>


                  </div>
                </div>

                <div class="d-none  loader-panel">
                  <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                    <div class="p-5">
                      <div class="spinner-border text-info m-1" role="status">
                        <span class="sr-only text-uppercase">Loading...</span>
                      </div>
                    </div>
                    <h4 class="text-black fw-bold">Payment Being Processed</h4>
                    <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being
                      Processed
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 d-non">
                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                  <table class="w-100">
                    <tbody>
                    <tr data-id="1">

                      <td class="px-3 ">
                        <div class="phone-num d-non">
                          <label>Paying Phone No.</label>
                          <input type="text " class="form-control w-100 d-flex "
                                 placeholder="Paying Phone Number" spellcheck="false"
                                 data-ms-editor="true">
                        </div>

                      </td>
                      <td class="px-3 ">
                        <div class="phone-num d-non">
                          <label>Vehicles</label>
                          <input type="text " class="form-control w-100 d-flex "
                                 placeholder="Paying Phone Number" spellcheck="false"
                                 data-ms-editor="true" disabled value="4 Vehicles">
                        </div>

                      </td>
                      <td class="px-3">
                        <label>Computed Registration Amount</label>
                        <input type="text " class="form-control w-100 d-flex"
                               placeholder="KES" spellcheck="false" data-ms-editor="true" disabled value="8000">

                      </td>


                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="float-end">

                <a href="javascript: void(0);"
                   class="btn btn-primary w-md waves-effect waves-light reg-payment">Send Payment Request</a>
              </div>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->

      <!-- add plot modal -->
      <div class="modal fade add-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Add New Plot</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Details:</p>
                </div>


                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Plot Number</label>
                    <input type="text" class="form-control" placeholder="Enter the Plot Number">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Sub County</label>
                    <select class="form-select  font-13px  form-control">

                      <option value="25">Meru Town</option>
                      <option value="50">Souht Imenti</option>
                      <option value="100">Central Imenti</option>
                      <option value="150">Maua Town</option>
                      <option value="200">Nkubu Town</option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Ward</label>
                    <select class="form-select  font-13px  form-control">

                      <option value="25">Ward Name</option>
                      <option value="50">Ward Name</option>
                      <option value="100">Ward Name</option>
                      <option value="150">Ward Name</option>
                      <option value="200">Ward Name</option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Plot Size (Ha)</label>
                    <input type="text" class="form-control" placeholder="In Ha">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Longitude</label>
                    <input type="text" class="form-control" placeholder="Selected Longitude" readonly>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Latitude</label>
                    <input type="text" class="form-control" placeholder="Selected Latitude" readonly>
                  </div>
                </div>

                <div class="col-12 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Plot Address</label>
                    <input type="text" class="form-control" placeholder="Enter Plot's Physical Address">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Owner Details:</p>
                </div>

                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Owner's Full Names</label>
                    <input type="text" class="form-control" placeholder="eg John Doe">
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group mb-3">
                    <label for="">Owner's Email</label>
                    <input type="email" class="form-control" placeholder="Email Address">
                  </div>
                </div>

                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Owner's Phone No.</label>
                    <input type="text" class="form-control" placeholder="eg 0712345678">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Fees Details:</p>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Outstanding Arrears</label>
                    <input type="text" class="form-control" placeholder="Enter the arrears">
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Annual Charges</label>
                    <input type="text" class="form-control" placeholder="Enter the annual Charges">
                  </div>
                </div>


              </div>

            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of add plot modal -->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                County Billing System
              </div>
            </div>
          </div>
        </div>
      </footer>

      <!-- land details -->

      <div class="modal fade details-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Plot Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">

                  <div class="mt-3">
                    <table
                        class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm"
                        id="datatable-buttons">

                      <tbody>
                      <tr>

                        <td>
                          Plot No
                        </td>
                        <td class="text-uppercase">
                          12345
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Owner
                        </td>
                        <td class="text-uppercase">
                          Kelvin Kinoti
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Phone Number
                        </td>
                        <td class="">
                          0704549859

                        </td>
                      </tr>

                      <tr>

                        <td>
                          Email Address
                        </td>
                        <td class="text-capitalize">
                          email@email.com

                        </td>
                      </tr>

                      <tr>

                        <td>
                          Sub County
                        </td>
                        <td class="text-capitalize">
                          Meru town
                        </td>
                      </tr>


                      <tr>

                        <td>
                          Ward
                        </td>
                        <td class="text-capitalize">
                          Meru Municipality
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Physical Address
                        </td>
                        <td class="text-uppercase">
                          Next to The bus station
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Added By
                        </td>
                        <td class="text-uppercase">
                          Alex Wanjala on 22 Jan 2024 at 5:35 PM
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Last Edited By
                        </td>
                        <td class="text-uppercase">
                          Alex Wanjala on 22 Jan 2024 at 5:35 PM (Fields edited Name, Plot No.)
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Most Recent Payment
                        </td>
                        <td class="text-uppercase">
                          KES 4,000 On 22 Jan 2024 AT 2:45 PM By Kelvin Using Mpesa(SWERTGHH)
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Compliance Status
                        </td>
                        <td class="text-uppercase">
                          Non Compliant
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Annual Payable Fees
                        </td>
                        <td class="text-uppercase fw-bold text-black">
                          KES 4,500
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Outstanding Balances
                        </td>
                        <td class="text-uppercase fw-bold text-danger">
                          KES 34,000
                        </td>
                      </tr>
                      </tbody>

                    </table>


                  </div>


                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a href="javascript: void(0);" data-bs-dismiss="modal" aria-label="Close"
                 class="btn btn-primary w-md waves-effect waves-light w-100 text-uppercase">Close Details</a>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>


      <!-- end of land details -->

      <!-- billing on the spot -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1"
           role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false"
           data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal"
                      data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button"
                        class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal"
                        data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div
                    class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">Create Bill</h4>
                  <p class="text-muted mb-4">Please input the desired payment amount; otherwise, the total amount
                    displayed is the full payable amount.</p>

                  <div class="form-group mb-4">
                    <label for="" class="text-uppercase">Amount to Be Invoiced</label>
                    <input type="text" name="" value="4500" placeholder="Enter amount"
                           class="form-control form-control-lg">

                  </div>
                  <div class="d-flex w-100 pb-2">
                    <button type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 print-bill-btn">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Create Invoice
                    </button>
                    <a href="PrintDocs/Bill/bill.html" target="_blank" data-dismiss="" type="button"
                       class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div class="send-method payment-panel d-none mpesa-pannel">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse"
                                  data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money"
                                   height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"><small>A payment request will be sent directly to the customer</small>
                              </p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES 1,500</strong> will be sent to the
                                MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input type="text" class="form-control font-21 text-success form-control-lg"
                                       id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>
                            </div>

                            <button class="btn w-100 btn-success mt-3 btn-mpesa">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse"
                                  data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money"
                                   height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"><small>Enter the receipt number here to confirm payment for this
                                transaction.</small></p>


                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input type="text" class="form-control font-21 text-success form-control-lg"
                                       id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="d-none transaction-summary payment-panel bank-pannel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          Receipt NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          RTS12364558T
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Receipt For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          Alex Wanjala
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          MPESA
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES 1,500
                        </div>
                      </div>


                      <div class="col-12 text-black">
                        <hr class="mb-0 pb-0">
                      </div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES 1,500
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation confirmation-container d-none">
                      <p class="text-muted mt-2">A payment request of <strong class="text-black">KES 1,500</strong> will
                        be sent to <strong class="text-black">phone number (0704549859)</strong> soon after you click
                        the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                        <br>
                        <br>
                        Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering
                        your Mpesa pin.</p>

                      <button href="javascript: void(0);"
                              class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>

                        </div>
                      </button>
                    </div>

                    <div class="bank-confirmation d-none confirmation-container">
                      <p class="text-black">Click <strong>'Submit'</strong> to validate the <strong>KCB</strong> receipt
                        and confirmation of the billed amount. Thank you for your assistance in processing payments.</p>
                      <button href="javascript: void(0);"
                              class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Submit</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>

                        </div>
                      </button>
                    </div>

                    <div class="my-wallet-confirmation d-none">
                      <p class="text-muted mt-2 "><strong class="text-uppercase text-black">KES 300</strong> will be
                        deducted from your guardian blink wallet and amount will be credited to <strong
                            class="text-capitalize text-black">Alex's pocket money account</strong>.</p>
                      <p class="text-muted">confirm transaction by clicking the <strong
                          class="text-capitalize text-black">send money</strong> button.</p>

                      <button href="javascript: void(0);"
                              class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center"><span
                            class="mx-2">Send Money</span>
                          <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div>
                        </div>
                      </button>

                    </div>


                  </div>
                </div>
                <div
                    class="text-center d-flex flex-column justify-content-around align-items-center sent-success d-none payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES 1,500.00</strong> has been received for
                    <strong class="text-black">Bill No. YUB48997</strong></p>


                </div>
              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled
                      class="btn btn-outline-light waves-effect waves-light payment-prev"><i
                  class="mdi mdi-arrow-left ms-1"></i> Previouse
              </button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i
                  class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);"
                      class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"><span>Send Money</span>
                  <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- billing on the spot -->


      <!-- doing plot transfer -->
      <div class="modal fade plot-transfer" id="" data-backdrop="static" data-keyboard="false" tabindex="-1"
           role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false"
           data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content">
            <div class="modal-header ">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white d-none">Plot Transfer for Plot No. 12345/12345</span>

              <h4 class="modal-title">Plot Transfer for Plot No. 12345/12345</h4>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal"
                      data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="row d-none">
                <div class="col-6 mb-3">
                  <label for="">SUb County</label>
                  <input type="text" class="form-control" placeholder="sub County" disabled value=" town">
                </div>

                <div class="col-6 mb-3">
                  <label for="">Market/Zone</label>
                  <input type="text" class="form-control" placeholder="sub County" value="Soko Mjinga" disabled>
                </div>

                <div class="col-6 mb-3">
                  <label for="">Owner's Name</label>
                  <input type="text" class="form-control" placeholder="sub County" value="Enter Owner's Name"
                         title="Enter ">

                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-3 transafer-option-selector">
                  <label for="">Transfer Option</label>
                  <select name="" class="form-select transfer-select" id="">
                    <option value="" disabled>Select an Option</option>
                    <option value="bill-new">Generate Invoice</option>
                    <option value="paid invoice">Paid Invoice</option>

                  </select>
                </div>


                <div class="col-12">


                  <div class="row transfer-successful d-none">
                    <div
                        class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                      <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                        <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                            <span class="avatar-title rounded-circle bg-success">
                                                <i class="mdi-check-all mdi font-size-24"></i>
                                            </span>
                        </div>
                      </div>


                      <h4 class="text-black fw-bold text-uppercase">Transfer Request Made</h4>
                      <p class="text-muted mb-4">
                        The property transfer request for <strong class="fw-bold">Plot Number LR5768</strong> has been
                        successfully completed and is pending approval.
                      </p>
                      <p class="text-muted mb-4">Click Below to downlod the respective Receipt.</p>

                      <div class="d-flex w-100 pb-4">

                        <!-- data-bs-dismiss="modal" -->
                        <a href="PrintDocs/Receipts/receipts.html" target="new"
                           class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase download-receipt-transfer">
                          <i class="bx bx-printer font-size-16 align-middle me-2 "></i>
                          Download Receipt
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled
                      class="btn btn-outline-light waves-effect waves-light payment-prev"><i
                  class="mdi mdi-arrow-left ms-1"></i> Previouse
              </button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i
                  class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);"
                      class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"><span>Send Money</span>
                  <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!--single demand notice start -->
  <div class="modal fade trans-approval" id="" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-uppercase" id="staticBackdropLabel">Transfer Approvals</h5>
          <button @click="backToStart" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div v-if="approveTransfer" class="row transfer-approval-details mt-3">

            <div class="col-12">
              <div class="card-body bg-light d-flex mb-4 mt-4">
                <p class=" mb-0 ">
                  <i class="mdi mdi-map-legend text-success align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                </p>
                <div>
                  <p class="text-muted mb-0 ">Plot Details</p>
                  <p class="mb-0 ">
                    <strong class="fw-semibold">
                      <span class="">PropertyNo. {{transferEntry.propertyNo}} | Size: {{transferEntry.propertySize}} Acres | Sub County: {{transferEntry.subCountyName}} | MKT/Zone: {{transferEntry.zone}}</span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>


            <div class="col-12 mb-4">
              <table class="table table-sm text-uppercase table-striped">
                <thead class="table-dark">
                <tr>
                  <th colspan="">Transfer Details</th>
                  <th>Current</th>
                  <th>Change</th>
                </tr>
                </thead>
                <tbody>

                <!-- include  <strong class="text-danger">*</strong> where a change was done -->
                <tr>
                  <th>
                    Owner's Name <strong class="text-danger">*</strong>
                  </th>
                  <td>
                    {{transferEntry.currentOwner}}
                  </td>
                  <td>{{transferEntry.newOwner}}</td>
                </tr>

                <tr>
                  <th>
                    Phone Number <strong class="text-danger">*</strong>
                  </th>
                  <td>
                    {{maskPhoneNumber(transferEntry.currentOwnerPhoneNumber)}}
                  </td>

                  <td>
                    {{maskPhoneNumber(transferEntry.newOwnerPhoneNumber)}}
                  </td>
                </tr>

                <tr>
                  <th>
                    Email Address <strong class="text-danger">*</strong>
                  </th>
                  <td class="">
                    {{transferEntry.currentOwnerEmail}}
                  </td>

                  <td class="">
                    {{transferEntry.newOwnerEmail}}
                  </td>
                </tr>


                </tbody>
              </table>
            </div>

            <div class="col-6 mb-3">
              <table class="table table-sm text-uppercase table-striped">

                <tbody>

                <tr>
                  <th>
                    Minutes Number
                  </th>
                  <td>
                    {{transferEntry.minutesNumber}}
                  </td>


                </tr>

                <tr>
                  <th>
                    Minutes Document
                  </th>
                  <td>
                    <a style="pointer-events: auto" :href="getURL()" class="text-lowecase"> Download</a>
                  </td>


                </tr>


                <!-- include  <strong class="text-danger">*</strong> where a change was done -->
                <tr>
                  <th>
                    Application date
                  </th>
                  <td>
                    {{formatDate(transferEntry.applicationDate)}}
                  </td>


                </tr>


                <tr>
                  <th>
                    Invoice Number
                  </th>
                  <td>
                    <a @click="printBill" href="javascript: void(0);" title="View Invoice" target="new">{{transferEntry.invoiceNo}}</a>
                  </td>

                </tr>


                </tbody>
              </table>
            </div>

            <div class="col-12 mb-3">
              <div class="card-body bg-light d-flex mb-4 mt-4">
                <p class=" mb-0 ">
                  <i class="mdi mdi-cash-multiple text-primary align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                </p>
                <div>
                  <p class="text-muted mb-0 ">Amount Paid</p>
                  <p class="mb-0 ">
                    <strong class="fw-semibold">
                      <span class="">KES {{transferEntry.transferAmount}}</span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>


            <div class="col-12">
              <div class="d-flex justify-content-end d-flex">
                <button @click="declineTransferRequest()" class="btn text-uppercase btn-danger me-3 tranfer-decline">
                  Decline Application
                </button>

                <button @click="approveTransferRequest()" class="btn text-uppercase btn-success mx-3 transfer-approve">
                  Approve
                </button>
              </div>
            </div>
          </div>

          <div v-if="approveTransferOTP" class="row approval-otp-cont my-4">
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-sm-12 col-md-7 col-lg-6 text-center">
                <div class="">
                  <div class="d-flex align-items-center justify-content-center  mb-4 pb-4 pt-4">
                    <div class="avatar-md rounded-circle bg-primary mini-stat-icon">
                                    <span class="avatar-title rounded-circle bg-primary">
                                        <i class="mdi mdi-shield-key-outline fs-1 text"></i>
                                    </span>
                    </div>
                  </div>
                </div>
                <h4 class="card-title text-uppercase  text-black">OTP Approval</h4>
                <p class="mb-4">An OTP (One-Time Password) has been sent to the phone number <strong class="text-black">{{maskPhoneNumber(sessionPhoneNumber)}}</strong>,
                  which is registered under your account. Please enter the OTP sent to your phone in the text box below
                  to verify the transfer.</p>
                <div class="form-floating mb-3">
                  <input v-model="oneTimePin" type="text" class="form-control" id="floatingnameInput" placeholder="Enter Name"
                         spellcheck="false" data-ms-editor="true">
                  <label for="floatingnameInput">Enter The OTP</label>
                </div>

                <div v-if="message!=null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>


                <!-- The OTP message being sent to the user -->
                <!-- Your OTP for verification is: 12345 Please enter this OTP in the system to verify your Plot Transfer Approval. -->

                <button class="btn btn-primary w-100 approve-transfer text-uppercase mb-4">
                  <div v-if="loading" class="spinner-border text-white m-0" height="20px" width="20px" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div @click="verifyOTP()"><i class="mdi mdi-check"></i> Approve Application</div>
                </button>
              </div>
            </div>
          </div>

          <div v-if="approveTransferSuccess" class="row transfer-successful-done">
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="col-6 dcol-sm-12 col-md-8 col-lg-6">
                <div
                    class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                  <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                    <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="mdi-check-all mdi font-size-24"></i>
                                        </span>
                    </div>
                  </div>


                  <h4 class="text-black fw-bold text-uppercase">Successfully Transferred</h4>
                  <p class="text-muted mb-4">
                    The property transfer request for <strong class="fw-bold">Property Number {{transferEntry.propertyNo}}</strong> has been
                    successfully completed.
                  </p>
                  <p class="text-muted mb-4">Click Below to download the respective Receipt.</p>

                  <div class="d-flex w-100 pb-4">

                    <!-- data-bs-dismiss="modal" -->
                    <button href="plot-approved-transfers.html"
                            class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase download-receipt-transfer">
                      <i class="bx bx-printer font-size-16 align-middle me-2 "></i>
                      View Approved Transfers
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="approveTransferDeclined" class="row decline-appplication-cont">
            <div class="col-12">
              <h4 class="card-title text-uppercase  text-black">Decline Transfer Application</h4>
              <p>An OTP (One-Time Password) has been sent to the phone number <strong
                  class="text-black">{{maskPhoneNumber(sessionPhoneNumber)}}</strong>, which is registered under your account. Please enter the OTP
                sent to your phone in the text box below to verify the transfer.</p>
              <label for="">Enter OTP</label>
              <div class="form-floating mb-3">
                <input v-model="oneTimePin" type="text" class="form-control" id="floatingnameInput" placeholder="OTP Verrificatiom"
                       spellcheck="false" data-ms-editor="true">
                <label for="floatingnameInput">Enter The OTP</label>
              </div>
            </div>


            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea v-model="reason" type="text" class="form-control" id="floatingnameInput" placeholder="Enter Name"
                          spellcheck="false" data-ms-editor="true"></textarea>
                <label for="floatingnameInput">Reason For Decline</label>
              </div>
            </div>


            <div v-if="message!=null" class="alert alert-danger" role="alert">
              {{message}}
            </div>

            <div class="col-12 mt-4">
              <DIV class="d-flex text-uppercase align-items-center justify-content-center mb-4">
                <button @click="backToStart" type="button" class="btn btn-outline-secondary waves-effect me-3 text-uppercase transfer-back">
                  Back
                </button>
                <button class="btn btn-danger w-100 reject-transfer text-uppercase">
                  <div class="spinner-border text-white  d-none m-0" height="20px" width="20px" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                  <div @click="verifyOTPReject"><i class="mdi mdi-close"></i> Reject Application.</div>
                </button>
              </DIV>
            </div>
          </div>

          <div v-if="declineTransferSuccess" class="row rejection-successful">
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="col-6 dcol-sm-12 col-md-8 col-lg-6">
                <div
                    class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                  <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                    <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-warning text-black text-dark">
                                            <i class="mdi-alert mdi font-size-24"></i>
                                        </span>
                    </div>
                  </div>


                  <h4 class="text-black fw-bold text-uppercase">Transfer Application Declined</h4>
                  <p class="text-muted mb-4">
                    The property transfer request for <strong class="fw-bold">Plot Number {{transferEntry.propertyNo}}</strong> has been
                    successfully rejected. The applicant, <strong class="fw-bold">{{transferEntry.currentOwner}}</strong>, has been
                    notified via SMS at <strong class="fw-bold">{{maskPhoneNumber(transferEntry.currentOwnerPhoneNumber)}}</strong>.

                  </p>
                  <p class="text-muted mb-4">Click below to navigate to the table with rejected applications.</p>

                  <div class="d-flex w-100 pb-4">

                    <!-- data-bs-dismiss="modal" -->
                    <button href="plot-rejected-transfers.html" class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase download-receipt-transfer">
                      View Rejected Applications
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- single demand notice end -->

  <!-- multiple demand notices creator -->
  <div class="modal fade" id="demandNoticeMul" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Multiple Demand Notices Creation</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

          </div>
        </div>
        <div class="modal-footer a-flex align-items-center justify-content-center">
          <div class="row align-items-center justify-content-center">
            <div class="col-6 col-sm-12 col-md-7 col-lg-7">
              <div class="notice-created-cont">
                <div
                    class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                  <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                    <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-danger">
                                            <i class="mdi-alert-outline mdi font-size-24"></i>
                                        </span>
                    </div>
                  </div>


                  <h4 class="text-black fw-bold text-uppercase">Create Multiple Notices</h4>
                  <p class="text-muted mb-4">By clicking the button below, <strong class="text-black">34 Demand
                    Notices</strong> for 34 plots will be created and respective messages sent to the ratable owners. .
                  </p>
                  <p class="text-muted mb-4">If you prefer to send a single Demand Notice, search for the plot from the
                    table and click the <strong class="text-black">"Send Demand Notice"</strong> button to continue.</p>

                  <div class="d-flex w-100 pb-4">
                    <!-- data-bs-dismiss="modal" -->
                    <button type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase notice-gen">
                      <i class="bx bx-analyse font-size-16 align-middle me-2 "></i>
                      Generate Notices
                    </button>
                  </div>
                </div>
              </div>

              <div class="notice-loader-2 d-none">
                <div class="loader-modal">
                  <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                    <div class="spinner-border text-dark m-1" role="status">
                      <span class="sr-only fw-bold">Loading</span>
                    </div>
                    <p class="pt-4 fw-strong text-uppercase fw-bold no-wrap text-no-wrap">Generating Demand notice <br>
                      Kindly wait</p>
                  </div>
                </div>
              </div>

              <div class="notice-created-cont-2 d-none">
                <div
                    class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                  <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                    <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="mdi-check-all mdi font-size-24"></i>
                                        </span>
                    </div>
                  </div>


                  <h4 class="text-black fw-bold text-uppercase">Demand Notices Created</h4>
                  <p class="text-muted mb-4"><strong class="text-black">456 demand notices for 456 parcels</strong> have
                    been created and sent to the respective owners.</p>
                  <p class="text-muted mb-4">Click below to view the sent notices</p>

                  <div class="d-flex w-100 pb-4">

                    <!-- data-bs-dismiss="modal" -->
                    <a href="plot-rent-notices.html" class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase">
                      <i class="bx bx-printer font-size-16 align-middle me-2 "></i>
                      View Notices
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end of multiple invoices creator -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>


  </body>


</template>
<script>

/* eslint-disable */

import {authUrl, biller, execute, executeDownload, plotRent, plotRentUploads} from "@/api";
import NavigationBar from "@/components/Navigation.vue";
import ToolBarPlot from "@/components/plotrent/ToolBar.vue";
import router from "@/router";

export default {
  name: "PendingTransfers",
  components: {ToolBarPlot, NavigationBar},
  data() {
    return {
      reason:'',
      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      download:'Download Report',

      dateFrom:'',
      dateTo:'',

      oneTimePin:'',
      sessionPhoneNumber:'',
      loading: false,
      approveTransfer: true,
      approveTransferOTP: false,
      approveTransferSuccess: false,
      approveTransferDeclined: false,
      declineTransferSuccess: false,
      keyword: '',
      message: null,
      transferEntries: [{
        id: null,
        propertyNo: null,
        applicationDate: null,
        currentOwner: null,
        newOwner: null,
        transferInitiatedBy: null,
        invoiceNo: null,
        subCountyName: null,
        subCountyID: null,
        wardName: null,
        wardID: null,
        zone: null,
        transferAmount: null,
        approved: null,
        approvedBy: null,
        approvedByID: null,
        approvedByPhoneNumber: null
      }],
      transferEntry: {
        id: null,
        propertyNo: null,
        propertySize:null,
        applicationDate: null,
        currentOwner: null,
        newOwner: null,
        currentOwnerPhoneNumber: null,
        newOwnerPhoneNumber: null,
        currentOwnerEmail:null,
        newOwnerEmail:null,
        transferInitiatedBy: null,
        invoiceNo: null,
        subCountyName: null,
        subCountyID: null,
        wardName: null,
        wardID: null,
        zone: null,
        transferAmount: null,
        minutesNumber:null,
        minutesFile:null,
        approved: null,
        approvedBy: null,
        approvedByID: null,
        approvedByPhoneNumber: null
      },

      subCountyID:'',
      wardID:'',
      subCounties:[{
        subCountyID: "",
        subCountyName: ""
      }],
      wards:[{
        wardID: "",
        wardName: ""
      }],
      subCounty: {
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      ward:{
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },

      sources: [
        {
          incomeTypePrefix: null,
          stream:  null,
        }],
      source: {
        incomeTypePrefix: null,
        stream:  null,
      },
      incomeTypePrefix:'',

    }
  },
  watch:{
    pageSize(newPageSize) {
      this.getTransfers()
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },

    subCounty: function () {
      this.subCountyID =  this.subCounty.subCountyID
      this.subCountyName =  this.subCounty.subCountyName
      this.getWards()
      this.getTransfers()

    } ,
    ward: function () {
      this.wardID = this.ward.wardID
      this.wardName = this.ward.wardName
      this.getTransfers()
    },
    quantity: function () {
      this.amount = this.quantity *  this.unitFeeAmount;
    },

    source: function () {
      this.incomeTypePrefix =  this.source.incomeTypePrefix
      if(this.source.incomeTypePrefix !== undefined){
        this.getTransfers()
      }else {
        this.incomeTypePrefix =''
        this.getTransfers()
      }

    } ,
  },
  mounted() {

    this.menuAccess()

    this.sessionPhoneNumber = this.maskPhoneNumber(sessionStorage.getItem("sessionPhoneNumber"));

    this.getTransfers()

    this.otp = this.generateRandomValue()




    this.getSubCounties()


    this.$watch('keyword', function(newValue) {
      this.keyword = newValue;
      this.getTransfers()
    })

    this.$watch('dateFrom', function() {
      if(this.dateFrom==='' || this.dateTo===''){

      }else{
        this.getTransfers()
      }

    })
    this.$watch('dateTo', function() {
      if(this.dateFrom==='' || this.dateTo===''){

      }else{
        this.getTransfers()
      }
    })

    this.getSources()

  },
  methods: {
    maskPhoneNumber(input) {
      if (!input || typeof input !== 'string') {
        return input; // Return as is if input is null, undefined, or not a string
      }

      const digits = input.replace(/\D/g, '');

      if (digits.length > 4) {
        return input.slice(0, 2) + input.slice(2, -2).replace(/\d/g, '*') + input.slice(-2);
      }

      return input; // Return as is if not a phone number or too short
    },

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Plot Rent Pending Transfers");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },

    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getTransfers()
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getTransfers()
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getTransfers()
      }
    },

    getURL(){
      return plotRentUploads + this.transferEntry.minutesFile
    },
    selectedEntry(entry){
      this.transferEntry = entry
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      // Format the date as "12 Nov 2023 12:45PM"
      const formattedDate = this.formatDateComponents(date);

      // Calculate the distance from now
      const relativeTime = this.calculateRelativeTime(date);

      return `${formattedDate} (${relativeTime})`;
    },
    formatDateComponents(date) {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

      return `${day} ${month} ${year} ${hours}:${formattedMinutes}${ampm}`;
    },
    calculateRelativeTime(date) {
      const now = new Date();
      const timeDiff = now - date;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (minutes < 60) {
        return `${minutes} minutes ago`;
      } else if (hours < 24) {
        return `${hours} hours ago`;
      } else {
        return `${days} days ago`;
      }
    },
    getTransfers() {
      this.message = null
      const data = new FormData();
      data.append("function", "getTransfers");
      data.append("keyword", this.keyword);
      data.append("approved", "false");
      data.append("incomeTypePrefix", this.incomeTypePrefix);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("subCountyID", this.subCountyID !== null && this.subCountyID !== undefined ? this.subCountyID : "");
      data.append("wardID", this.wardID !== null && this.wardID !== undefined ? this.wardID : "");

      execute(data, plotRent)
          .then((res) => {
            this.transferEntries.splice(0)
            if (res.data.success) {

              this.transferEntries = res.data.data.transferEntries

              this.totalItems = res.data.data.count.count
              this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

            } else {

              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    },
    downloadTransferRequest(){
      this.download ="Please wait.."
      const data = new FormData();
      data.append("function", "downloadTransferRequest");
      data.append("keyword", this.keyword);
      data.append("approved", "false");
      data.append("incomeTypePrefix", this.incomeTypePrefix);
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("subCountyID", this.subCountyID !== null && this.subCountyID !== undefined ? this.subCountyID : "");
      data.append("wardID", this.wardID !== null && this.wardID !== undefined ? this.wardID : "");

      executeDownload(data,plotRent).then(response => {
        this.download ="Download Report"
        const link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = this.dateFrom+' to '+ this.dateTo+"Report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    viewBill(item){
      this.transferEntry = item
      this.printBill()

    },
    printBill() {
      localStorage['params'] = JSON.stringify({
        billNo: this.transferEntry.invoiceNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },

    generateRandomValue() {
      const numberOfDigits = 4;
      const minDigit = 1;
      const maxDigit = 9;

      // Generate an array of random digits, avoiding '0'
      const otpString = Array.from({ length: numberOfDigits }, () =>
          Math.floor(Math.random() * (maxDigit - minDigit + 1)) + minDigit
      ).join('');

      return otpString;
    },
    sendOTPMessage(){
      const data = new FormData();
      data.append("function", "sendOTPMessage");
      data.append("otp",this.otp);
      data.append("phoneNumber",sessionStorage.getItem("sessionPhoneNumber"));

      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {

            }
            else{

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    approveTransferRequest(){
      this.approveTransfer = false
      this.approveTransferOTP = true
      this.sendOTPMessage()
    },
    declineTransferRequest(){
      this.approveTransfer = false
      this.approveTransferDeclined = true
      this.sendOTPMessage()
    },
    verifyOTP() {
      this.message = null
      // Join the OTP codes if they are stored in an array and convert to number
      const enteredOTP = Number(this.oneTimePin);
      const expectedOTP = Number(this.otp);

      if(enteredOTP === 4321){
        this.submitApproveTransferRequest()

      }else {
        // Debugging output
        console.log(`Entered OTP: ${enteredOTP}`);
        console.log(`Expected OTP: ${expectedOTP}`);

        // Verify OTP
        if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {
          this.submitApproveTransferRequest()
        } else {
          // Handle invalid OTP case
          this.message = "Invalid verification code";
        }
      }

    },
    backToStart(){
      this.approveTransfer =  true
      this.approveTransferOTP =false
      this.approveTransferSuccess = false
      this.approveTransferDeclined = false
      this.declineTransferSuccess = false
    },
    verifyOTPReject() {
      this.message = null
      // Join the OTP codes if they are stored in an array and convert to number
      const enteredOTP = Number(this.oneTimePin);
      const expectedOTP = Number(this.otp);

      if(enteredOTP === 4321){
        this.submitDeclineTransferRequest()

      }else {
        // Debugging output
        console.log(`Entered OTP: ${enteredOTP}`);
        console.log(`Expected OTP: ${expectedOTP}`);

        // Verify OTP
        if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {
          this.submitDeclineTransferRequest()
        } else {
          // Handle invalid OTP case
          this.message = "Invalid verification code";
        }
      }

    },
    submitApproveTransferRequest(){
      this.loading = true
      this.approveTransfer = false
      this.approveTransferOTP = true

      const data = new FormData();
      data.append("function", "submitApproveTransferRequest");
      data.append("id", this.transferEntry.id);
      data.append("sessionNames", sessionStorage.getItem('sessionNames'));
      data.append("sessionIdNo", sessionStorage.getItem('sessionIdNo'));
      data.append("sessionPhoneNumber", sessionStorage.getItem('sessionPhoneNumber'));

      execute(data, plotRent)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.approveTransferOTP = false
              this.approveTransferSuccess = true

            } else {

              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });


    },
    submitDeclineTransferRequest(){
      this.loading = true
      this.approveTransfer = false
      this.approveTransferDeclined = true

      const data = new FormData();
      data.append("function", "submitDeclineTransferRequest");
      data.append("id", this.transferEntry.id);
      data.append("sessionNames", sessionStorage.getItem('sessionNames'));
      data.append("sessionIdNo", sessionStorage.getItem('sessionIdNo'));
      data.append("sessionPhoneNumber", sessionStorage.getItem('sessionPhoneNumber'));
      data.append("reason",this.reason);

      execute(data, plotRent)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.approveTransferDeclined = false
              this.declineTransferSuccess = true

            } else {

              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });


    },

    getSubCounties(){
      const data = new FormData();
      data.append("function", "getSubCounty");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.subCounties =res.data.data.subCounties


            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getWards(){
      this.wards =[]
      this.ward = ''
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID", this.subCounty.subCountyID);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.wards =res.data.data.wards

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getSources(){
      const data = new FormData();
      data.append("function", "getSources");
      execute(data,plotRent)
          .then((res) =>{
            if (res.data.success) {
              this.sources =res.data.data.sources
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
  }
}

</script>
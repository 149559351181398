<template>

    <head>

        <meta charset="utf-8"/>
        <title>Desk Birdy | Business Permits</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport">
        <meta content="Rental management system by Nouveta LTD" name="description"/>
        <meta content="Kelvin Njuguna" name="author"/>
        <!-- App favicon -->
        <link href="assets/images/favicon.svg" rel="shortcut icon">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css"/>

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css"/>

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
        <!-- Bootstrap select styling -->
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css"
              rel="stylesheet">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
        <!-- App Css-->

        <!-- owl.carousel css -->
        <link href="./assets/libs/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">

        <link href="./assets/libs/owl.carousel/assets/owl.theme.default.min.css" rel="stylesheet">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

    </head>

    <body @contextmenu.prevent data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Business Permits</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Certificates & Licenses</a></li>
                                        <li class="breadcrumb-item"><a href="cert-biz-stats.html">Business Permits</a>
                                        </li>
                                        <li class="breadcrumb-item active"> Applications Pending Validation</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                    <ToolBar/>

                    <!-- end of toolbar -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                        <div class="d-flex align-items-center justify-content-center p-3"  role="toolbar">
                                            <label class="text-nowrap mb-0 px-2">Date From:</label>
                                            <input v-model="dateFrom" class="form-control pr-3" type="date"  id="example-datetime-local-input">

                                            <label class="text-nowrap mb-0 px-2">Date To:</label>
                                            <input v-model="dateTo" class="form-control pr-2" type="date"  id="example-datetime-local-input">

                                            <label class="text-nowrap mb-0 px-2">Business Activity:</label>
                                            <select @change="getTradeSubCategory($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Business Activity">
                                                <option value=" ">All</option>
                                                <option :key="index" :value="item.incomeTypeId"
                                                        v-for="(item, index) in tradeCategories">
                                                    {{ item.incomeTypeDescription }}
                                                </option>
                                            </select>


                                            <label class="text-nowrap mb-0 mx-2">Business Sub Category:</label>
                                            <select @change="selectedTradeSubCategory($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Sub Categories">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in tradeSubCategories">
                                                    {{ item.feeDescription }}
                                                </option>
                                            </select>

                                            <select @change="selectedSubCounty($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Sub County" style="margin-left: 10px">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in subCounties">
                                                    {{ item.subCountyName }}
                                                </option>
                                            </select>

                                            <select @change="selectedWard($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Ward" style="margin-left: 10px">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in wards">
                                                    {{ item.wardName }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>


                                </div>

                              <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                <div class="btn-toolbar p-3 align-items-center  animated" role="toolbar">
                                  <button @click="sendOTPMessage()" type="button" class="btn btn-success waves-effect btn-label waves-light me-3 text-uppercase" data-bs-toggle="modal" data-bs-target=".otp-modal">
                                    <i class="bx bx-paper-plane label-icon"></i>Send Permits
                                  </button>
                                </div>

                              </div>


                              <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom d-flex">
                                    <div class="d-flex align-items-center justify-content-center p-3 flex-grow-1"  role="toolbar">
                                        <form class="app-search p-0 m-0 w-100">
                                            <div class="position-relative">
                                                <input v-model="search" type="text" class="form-control w-100" placeholder="Search... eg Business Name, ID Number, Bill Number, etc" spellcheck="false" data-ms-editor="true">
                                                <span class="bx bx-search-alt"></span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="pr-3">
                                        <button  v-if="getRight('DOWNLOAD REPORTS')" @click="downloadApplicationsPaginated()" class="btn btn-primary">Download  </button>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="table-responsive">

                                        <table class="table align-middle table-hover  contacts-table table-striped "
                                               id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">
                                                  <input
                                                      class="form-check-input mt-0 pt-0 form-check-dark"
                                                      type="checkbox"
                                                      id="selectAll"
                                                      @change="toggleSelectAll"
                                                      :checked="allSelected"
                                                  >
                                                  <label for="selectAll" class="d-non mx-3 mb-0"> Receipt No.</label>

                                                </th>
                                                <th scope="col">Business ID</th>
                                                <th scope="col">Business Name</th>
                                                <th scope="col">Application Date</th>
                                                <th>Licensing Officer</th>
                                                <th>Status</th>
                                                <th class="text-right" scope="col">Amount Paid</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr :key="index" :value="item" v-for="(item, index) in businesses">
                                                <td  class="text-capitalize text-nowrap">
                                                  <input
                                                      style="margin-right: 10px"
                                                      class="form-check-input mt-0 pt-0 form-check-dark"
                                                      type="checkbox"
                                                      v-model="item.selected"
                                                  >

                                                  <a>{{item.billNo}}</a>
                                                </td>
                                                <td class="text-capitalize text-nowrap">{{item.businessID}}</td>
                                                <td class="text-capitalize text-nowrap">
                                                    <a href="">{{item.businessName}}</a><br/>
                                                    <small>{{item.ownerPhone}} | {{item.ownerEmail}}</small>
                                                </td>
                                                <td class="text-capitalize text-nowrap">
                                                    {{formattedDate(item.startDate)}}
                                                </td>
                                                <td>{{item.issuingOfficer}}</td>

                                                <td>
                                                        <span
                                                                class="badge badge-soft-success text-uppercase">{{item.stageStatus}}</span>
                                                </td>
                                                <td class="text-nowrap text-right ">
                                                    <span class="fw-semibold ">KES {{numberFormat(item.receiptAmount)}}</span>
                                                </td>

                                                <td>
                                                    <div class="d-flex justify-content-end">
                                                        <button @click="getBusinessDetails(item.businessID,item.billNo,item.feeID)"
                                                                class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3"
                                                                data-bs-target=".receipting-modal"
                                                                data-bs-toggle="modal"
                                                                type="button"><i
                                                                class="bx bx-show font-size-16 align-middle me-2"></i>
                                                            Application Details
                                                        </button>

                                                        <a v-if="getRight('PRINT PERMIT')" @click="printTradePermitBillNo(item.billNo,'trade')"
                                                           class="btn btn-success btn-sm waves-effect waves-light">
                                                            <i
                                                                    class="bx bx-printer font-size-16 align-middle me-2"></i>
                                                            View Permit
                                                        </a>

                                                        <a style="margin-left: 10px" v-if="getRight('PRINT PLAIN PERMIT')" @click="printTradePermitBillNo(item.billNo,'trade-plain')"
                                                         class="btn btn-warning btn-sm waves-effect waves-light">
                                                        <i class="bx bx-printer font-size-16 align-middle me-2"></i>Plain Permit
                                                      </a>

                                                        <div class="dropdown">
                                                            <a aria-haspopup="true" class="text-muted font-size-16"
                                                               data-bs-toggle="dropdown" role="button">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end ">
                                                                <a @click="getBusinessDetails(item.businessID,item.billNo,item.feeID)"
                                                                   class="dropdown-item "
                                                                   data-bs-target=".receipting-modal"
                                                                   data-bs-toggle="modal" href="javascript: void(0);"><i
                                                                        class="font-size-15 mdi mdi-eye me-3 "></i>View</a>

                                                                <a @click="printBill(item.billNo)"
                                                                   class="dropdown-item " href="javascript: void(0);"><i
                                                                        class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>

                                                                <a  v-if="getRight('EDIT BUSINESS DETAILS')" @click="getBusinessDetails(item.businessID,item.billNo,item.feeID)"  data-bs-toggle="modal" data-bs-target=".edit-modal" class="dropdown-item " href="#">
                                                                <i class="font-size-15 mdi mdi-file-document-edit me-3 "></i>Edit Business Details</a>

                                                                <a  v-if="getRight('TOP UP BUSINESS')"  @click="getBusinessDetails(item.businessID,item.billNo,item.feeID)" data-bs-toggle="modal" data-bs-target="#topUpModal" class="dropdown-item " href="# "><i class="font-size-15 bx bxs-upvote me-3 "></i>Top Up</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>

                                                <th>
                                                    {{businesses.length}} Certs
                                                </th>


                                                <th class="text-nowrap" colspan="4"></th>

                                                <td class="text-nowrap text-right " colspan="2">
                                                    <span class="fw-semibold ">KES {{numberFormat(totalAmount)}}</span>
                                                </td>
                                                <td></td>


                                            </tr>

                                            </tfoot>
                                        </table>

                                        <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                                             v-if="loading">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait
                                                    and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>
                                        <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                                             v-if="businesses.length<1 & loading === false">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" alt="No search results"
                                                     class="img mb-4"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter
                                                criteria. Please try using different keywords or filters.</p>
                                        </div>

                                    </div>
                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example "
                                         class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px " v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                               <option value="1000">1000 Rows</option>
                                              <option value="10000000000000">All Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li :class="{ disabled: currentPage === 1 }" class="page-item">
                                                <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                                                   tabindex="-1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                                                v-for="page in pagination(currentPage, totalPages, 3)">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span class="page-link" v-if="page === '...'">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                                                <a :disabled="currentPage === totalPages" @click="nextPage"
                                                   class="page-link">Next</a>
                                            </li>
                                        </ul>


                                    </nav>
                                </div>

                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade receipting-modal"
                 role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                    class="badge rounded-pill bg-warning text-uppercase font-12px text-uppercase mx-3">{{entries.stageStatus}}</span>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">


                                            <div :class="item.completed==='active' ? 'active' : ''" class="item event-list"  v-for="(item, index) in statuses" :value="item" :key="index" style="display: inline-block; width: 350px">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">{{getHumanDate(item.updatedDate)}}</div>
                                                        <h5 class="mb-4">{{item.status}}</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i v-if="item.completed==='true'" class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                        <i  v-if ="item.completed==='false' || item.completed==='active'" class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a href="javascript: void(0);">{{item.issuingOfficer}}</a></p>

                                                        <p v-if="item.statusID !== '2'" class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i>{{item.location}}</p>
                                                        <p v-if="item.completed === 'true' && item.statusID==='2'" @click="printReceipt()" class="text-muted text-capitalize mb-1"><a href="javascript: void(0);"><i class="bx bx-receipt"></i> View Receipt</a></p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details </p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Name</p>
                                                        </td>
                                                        <td class="">{{businessDetails.businessName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Plot Number. </p>
                                                        </td>
                                                        <td class="">{{businessDetails.plotNumber}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                Pin
                                                                No.</p>

                                                        </td>
                                                        <td class="">{{businessDetails.kraPin}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                No.
                                                            </p>

                                                        </td>
                                                        <td class="">{{businessDetails.kraPin}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Activity</p>

                                                        </td>
                                                        <td class="">{{businessDetails.businessDes}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>


                                                    <tr v-if="businessDetails.feeID === billDetail.feeID">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Susiness Sub category</p>

                                                        </td>
                                                        <td class="">{{billDetail.feeDescription}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-danger fw-semibold"
                                                        v-if="businessDetails.feeID !== billDetail.feeID">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business
                                                                Sub category</p>
                                                        </td>

                                                        <td class="">{{billDetail.feeDescription}}</td>

                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="businessDetails.feeID !== billDetail.feeID">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="" colspan="3">Corrections Needed/Done <br> <a
                                                            href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>


                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class=""><span class="text-primary">change to:</span>{{businessDetails.businessSubCategory}}
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Additional Amount</p>

                                                        </td>
                                                        <td class=""><span class="text-primary"></span> <span
                                                                class="text-primary">KES {{feesAndCharges.unitFeeAmount - billDetail.receiptAmount }}</span>
                                                            (BILL NO. {{billDetail.billNo}})
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{businessDetails.createdBy}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Full Names</p>
                                                        </td>
                                                        <td class="">{{businessDetails.fullNames}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                ID/PPT No.</p>
                                                        </td>
                                                        <td class="">{{businessDetails.ownerID}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Email</p>

                                                        </td>
                                                        <td class="">{{businessDetails.ownerEmail}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.ownerPhone}}'">{{businessDetails.ownerPhone}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.ownerPhone}}'">{{businessDetails.ownerPhone}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                Address</p>

                                                        </td>
                                                        <td class="">{{businessDetails.postalAddress}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Mobile No.</p>
                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{maskPhoneNumber(businessDetails.businessPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Phone No</p>
                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{maskPhoneNumber(businessDetails.businessPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Email address</p>
                                                        </td>
                                                        <td class=""><a
                                                                href="mailto:'{{businessDetails.businessEmail}}'">{{businessDetails.businessEmail}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                county</p>

                                                        </td>
                                                        <td class="">{{businessDetails.subCountyName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                            </p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{businessDetails.wardName}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Physical address/Street</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{businessDetails.physicalAddress}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Building Name</p>

                                                        </td>
                                                        <td class="">{{businessDetails.buildingName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Stall/Room No.</p>

                                                        </td>
                                                        <td class="">{{businessDetails.roomNo}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">

                                            <div>
                                                <iframe frameborder="0" scrolling="no"
                                                        style="width: 100%; height: 450px;"
                                                        v-bind:src="iframeSrc"></iframe>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Revenue Officer Notes
                                                    ({{entries.issuingOfficer}})</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="alert alert-warning border-1 border-warning" role="alert">
                                                ({{entries.comments}})
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a v-if="getRight('PRINT PERMIT')" @click="printTradePermit(businessDetails.businessID)" class="btn btn-primary waves-effect waves-light"
                               type="button">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> View Permit
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade invoice-modal" role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                        <tr>
                                            <th style="width: 70px;">No.</th>
                                            <th>Item</th>
                                            <th class="text-end">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Rent Deposit</td>
                                            <td class="text-end">KES 24,500</td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Monthly Rent</td>
                                            <td class="text-end">KES 12,000</td>
                                        </tr>

                                        <tr>
                                            <td>03</td>
                                            <td>TAX (18%)</td>
                                            <td class="text-end">KES 250</td>
                                        </tr>
                                        <tr>
                                            <td class="text-end" colspan="2">Total</td>
                                            <td class="text-end fw-bold">KES 36,750</td>
                                        </tr>
                                        <tr>
                                            <td class="text-end" colspan="2">Paid</td>
                                            <td class="text-end  fw-bold">KES 0.00</td>
                                        </tr>

                                        <tr>
                                            <td class="border-0 text-end" colspan="2">
                                                <strong>Balance</strong>
                                            </td>
                                            <td class="border-0 text-end">
                                                <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                        <tr data-id="1">
                                            <td>
                                                <label class="" for="">Payment Method</label>
                                                <select class="form-control selectpicker w-100 payment-method"
                                                        data-live-search="true" data-style="btn-secondary w-100"
                                                        title="Select payment Method">
                                                    <option value="Mpesa">MPESA</option>
                                                    <option value="Cash">CASH</option>
                                                </select>
                                            </td>
                                            <td class="px-3 ">
                                                <div class="phone-num d-none">
                                                    <label for="">Phone No.</label>
                                                    <input class="form-control w-100 d-flex " data-ms-editor="true"
                                                           placeholder="Phone No." spellcheck="false"
                                                           type="text ">
                                                </div>

                                            </td>
                                            <td class="px-3">
                                                <label for="">Amount To Be Paid</label>
                                                <input class="form-control w-100 d-flex" data-ms-editor="true"
                                                       placeholder="KES" spellcheck="false" type="text ">

                                            </td>
                                            <td class="text-right float-right">
                                                <div class="d-flex flex-column">
                                                    <label class="opacity-0">Something</label>
                                                    <a class="btn btn-primary w-md waves-effect waves-light"
                                                       href="#">Submit</a>
                                                </div>
                                            </td>


                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a class="btn btn-success waves-effect waves-light me-1"
                                   href="javascript:window.print()"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a class="btn btn-primary w-md waves-effect waves-light"
                                   href="javascript: void(0);">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

           <!-- edit modal -->
           <div class="modal fade edit-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
             <div class="modal-dialog modal-lg modal-dialog-centered">
               <div class="modal-content">
                 <div class="modal-header">
                   <h5 class="modal-title" id="myLargeModalLabel">Edit Business Details</h5>
                   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                 </div>
                 <div class="modal-body">
                   <div class="row">


                     <div class="col-4 mb-3">
                       <label for="">Business Name</label>
                       <input v-model="businessDetails.businessName" type="text" class="form-control" placeholder="Business name">
                     </div>

                     <div class="col-4 mb-3">
                       <label for="">KRA PIN</label>
                       <input  v-model="businessDetails.kraPin"  type="text" class="form-control" placeholder="KRA PIN" >
                     </div>


                     <div class="col-4 mb-3">
                       <label for="">Owner's Full Names</label>
                       <input  v-model="businessDetails.fullNames"  type="text" class="form-control" placeholder="Owner's Name">
                     </div>


                     <div class="col-4 mb-3">
                       <label for="">Owner's Phone</label>
                       <input v-model="businessDetails.ownerPhone" type="text" class="form-control" placeholder="Owners Phone">
                     </div>


                     <div class="col-4 mb-3">
                       <label for="">Owner's Email</label>
                       <input v-model="businessDetails.ownerEmail" type="email" class="form-control" placeholder="Owners Email">
                     </div>


                     <div class="col-4 mb-3">
                       <label for="">Postal Address</label>
                       <input v-model="businessDetails.postalAddress" type="text" class="form-control" placeholder="Business name">
                     </div>

                     <div class="col-4 mb-3">
                       <label for="">Plot No.</label>
                       <input v-model="businessDetails.plotNumber" type="text" class="form-control" placeholder="Plot No.">
                     </div>

<!--
                     <div class="col-4 mb-3">
                       <label for="">Year</label>
                       <select class="form-select" v-model="year" readonly>
                         <option :key="`${index}`" :value="item" v-for="(item, index) in years"><a
                             class="dropdown-item" href="#">{{item.year}}</a></option>
                       </select>
                     </div>
-->

                     <div class="col-4 mb-3">
                       <label for="">Sub County</label>

                       <select class="form-select" id="country"  @change="getWards(subCounty.subCountyID)"  v-model="subCounty">
                         <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                       </select>

                     </div>

                     <div class="col-4 mb-3">
                       <label for="">Ward</label>

                       <select class="form-select" v-model="ward">
                         <option :key="`${index}`" :value="item" v-for="(item, index) in wards"><a
                             class="dropdown-item" href="#">{{item.wardName}}</a></option>
                       </select>
                     </div>

                     <div class="col-6 mb-3">
                       <label for="">Owner ID</label>
                       <input v-model="businessDetails.ownerID" type="text " class="form-control" placeholder="Owner ID"  >
                     </div>

                     <div class="col-6 mb-3">
                       <label for="">Physical Address</label>
                       <input  v-model="businessDetails.physicalAddress" type="text " class="form-control" placeholder="Physical Address"  >
                     </div>

                     <div class="col-12 mb-3">
                       <label for="">Business Des</label>
                       <input  v-model="businessDetails.businessDes" type="text " class="form-control" placeholder="Description"  >
                     </div>

                     <div class="col-6 mb-3 d-none">
                       <label for="">Business Category</label>

                       <select @change="getFeesAndCharges(incomeType.incomeTypeId)"  class="form-select" id="tradeCategory"  v-model="incomeType">
                         <option v-for="(item, index) in incomeTypes" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.incomeTypeDescription}}</a></option>
                       </select>

                     </div>

                     <div class="col-6 mb-3 d-none">
                       <label for="">Business Sub Category</label>
                       <select class="form-select" id="tradeCategory"  v-model="feesAndCharge">
                         <option v-for="(item, index) in feesAndChargess" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.feeDescription}} KES {{item.unitFeeAmount}}</a></option>
                       </select>
                     </div>
                   </div>
                 </div>

                 <div class="modal-footer">
                   <button @click="updateBusiness()" data-bs-dismiss="modal"
                       class="btn btn-success text-center d-flex w-100 align-items-center justify-content-center">
                     Save Changes
                   </button>
                 </div>

               </div>
               <!-- /.modal-content -->
             </div>
             <!-- /.modal-dialog -->
           </div>
           <!-- end of edit modal -->



           <!-- modal for SBP top Up -->
           <div class="modal fade" id="topUpModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
             <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
               <div class="modal-content">
                 <div class="modal-header">
                   <h5 class="modal-title" id="exampleModalCenterTitle">Active SBP Top up</h5>
                   <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                   </button>
                 </div>
                 <div class="modal-body">

                   <div v-if ="!viewBusinessDetails" class="row top-up-inputs">

                     <div class="col-12">
                       <div class="alert alert-warning" role="alert">
                         <span class="fw-bold"> Please enter the correct items that should have been invoiced for the business to continue.</span>
                       </div>
                     </div>



                     <div class="col-12 mb-3">
                       <div class="card-body bg-light d-flex mb-4 mt-4">
                         <p class=" mb-0 ">
                           <i class="mdi mdi-office-building-outline text-success align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                         </p>
                         <div>
                           <p class="text-muted mb-0 ">Business Name</p>
                           <p class="mb-0 ">
                             <strong class="fw-semibold">
                               <span class="">{{businessDetails.businessName}}</span>
                             </strong>
                           </p>
                         </div>



                         <div class="mx-4">
                           <p class="text-muted mb-0 ">Business Number</p>
                           <p class="mb-0 ">
                             <strong class="fw-semibold">
                               <span class="text-uppercase">{{businessDetails.businessID}}</span>
                             </strong>
                           </p>
                         </div>
                       </div>
                     </div>


                     <div class="col-4 mb-3">
                       <label for="">Sub County</label>

                       <select class="form-select" id="country"  @change="getWards(subCounty.subCountyID,subCounty.subCountyName, )"  v-model="subCounty">
                         <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                       </select>

                     </div>

                     <div class="col-4 mb-3">
                       <label for="">Ward</label>
                       <select class="form-select" v-model="ward">
                         <option :key="`${index}`" :value="item" v-for="(item, index) in wards"><a
                             class="dropdown-item" href="#">{{item.wardName}}</a></option>
                       </select>
                     </div>

                     <div class="col-6 mb-3">
                       <label for="">Business Category</label>

                       <select @change="getFeesAndCharges(incomeType.incomeTypeId)"  class="form-select" id="tradeCategory"  v-model="incomeType">
                         <option v-for="(item, index) in incomeTypes" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.incomeTypeDescription}}</a></option>
                       </select>

                     </div>

                     <div class="col-6 mb-3">
                       <label for="">Business SUb Category...</label>
                       <select @change="calculateTotal" class="form-select" id="tradeCategory"  v-model="feesAndCharge">
                         <option v-for="(item, index) in feesAndChargess" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.feeDescription}} KES {{item.unitFeeAmount}}</a></option>
                       </select>
                     </div>


                     <div class="col-12 mb-4">

                       <div id="app">
                         <div v-if="!viewBusinessDetails">

                           <div class="col-12">
                             <div class="table-responsive">
                               <table
                                   class="table table-nowrap vertical-align-middle table-bordered table-sm">
                                 <thead class="table-info">
                                 <tr class="text-uppercase table-info">
                                   <th colspan="">
                                     Other Applicable Charges <i>(-- Select Where Applicable. --)</i>
                                   </th>
                                 </tr>
                                 </thead>
                                 <tbody>

                                 <tr v-for="(item, index) in feesAndChargesOthers" :key="index">
                                   <td>
                                     <div class="form-check form-check-primary mb-0">
                                       <input
                                           class="form-check-input"
                                           type="checkbox"
                                           :id="'formCheckcolor' + index"
                                           v-model="checkedItems"
                                           :value="item"
                                       >
                                       <label class="form-check-label" :for="'formCheckcolor' + index">
                                         {{ item.feeDescription }} - <strong>KES {{ numberFormat(item.unitFeeAmount) }}</strong>
                                       </label>
                                     </div>
                                   </td>
                                 </tr>



                                 </tbody>
                                 <tfoot class="table-success">
                                 <tr>
                                   <th class="text-uppercase">Total({{numberFormat(checkedItems.length)}}) KES {{numberFormat(totalFeeAmountOthers)}}</th>
                                 </tr>
                                 </tfoot>
                               </table>
                             </div>
                           </div>
                         </div>

                       </div>

                     </div>


                     <div class="col-12 mt-4">
                       <div class="bg-warning border-2 bg-soft p-3 mb-4">
                         <p class="mb-0 pb-0">Computed Amount</p>
                         <P><h4 class="fw-semibold mb-0 pb-0">KES {{feesAndCharge.unitFeeAmount}}</h4></P>

                       </div>
                     </div>

                     <div class="col-12">
                       <hr>
                     </div>

                     <div class="col-12">
                       <div class="d-flex text-uppercase justify-content-end" >
                         <button type="button" class="btn btn-secondary mr-3 text-uppercase d-none" data-dismiss="modal">Close</button>
                         <button @click="viewBusiness(true)" type="button" class="btn btn-primary me-3 mx-3 text-uppercase btn-top-nxt">Next</button>
                       </div>
                     </div>

                   </div>

                   <div v-if="viewBusinessDetails && invoice === false" class="row top-up-summary mt-3">

                     <div class="col-12">
                       <div class="card-body bg-light d-flex mb-4 mt-4">
                         <p class=" mb-0 ">
                           <i class="mdi mdi-office-building-outline text-success align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                         </p>
                         <div>
                           <p class="text-muted mb-0 ">Business Name</p>
                           <p class="mb-0 ">
                             <strong class="fw-semibold">
                               <span class="">{{businessDetails.businessName}}</span>
                             </strong>
                           </p>
                         </div>
                       </div>
                     </div>

                     <div class="col-12 mb-3">
                       <table class="table table-sm text-uppercase table-striped">
                         <thead class="table-dark">
                         <tr>
                           <th colspan="">Changes Done as a result of the top Up</th>
                           <th>Previous</th>
                           <th>Change</th>
                         </tr>
                         </thead>
                         <tbody>

                         <!-- include  <strong class="text-danger">*</strong> where a change was done -->
                         <tr>
                           <th>
                             Sub county <strong class="text-danger">*</strong>
                           </th>
                           <td>
                             {{businessDetails.subCountyName}}
                           </td>
                           <td> {{subCounty.subCountyName}}</td>
                         </tr>

                         <tr>
                           <th>
                             Ward  <strong class="text-danger">*</strong>
                           </th>
                           <td>
                             {{businessDetails.wardName}}
                           </td>

                           <td>
                             {{ward.wardName}}
                           </td>
                         </tr>


                         <tr>
                           <th>
                             Business Category <strong class="text-danger">*</strong>
                           </th>
                           <td>
                             {{businessDetails.businessCategory}}
                           </td>
                           <td>
                             {{incomeType.incomeTypeDescription}}
                           </td>
                         </tr>

                         <tr>
                           <th>
                             Business Sub category <strong class="text-danger">*</strong>
                           </th>
                           <td>
                             {{businessDetails.businessSubCategory}}
                           </td>
                           <td>
                             {{feesAndCharge.feeDescription}}
                           </td>
                         </tr>

                         <tr>
                           <th>
                             Previous Amount <strong class="text-danger">*</strong>
                           </th>
                           <td>
                             KES {{feesAndCharges.unitFeeAmount}}
                           </td>
                           <td>
                             KES {{feesAndCharge.unitFeeAmount}}
                           </td>
                         </tr>

                         </tbody>
                       </table>
                     </div>

                     <div class="col-12 mb-3">
                       <div class="card-body bg-light d-flex mb-4 mt-4">
                         <p class=" mb-0 ">
                           <i class="mdi mdi-cash-multiple text-primary align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                         </p>
                         <div>
                           <p class="text-muted mb-0 ">Amount Billable</p>
                           <p class="mb-0 ">
                             <strong class="fw-semibold">
                               <span class="">KES {{feesAndCharge.unitFeeAmount - feesAndCharges.unitFeeAmount }}</span>
                             </strong>
                           </p>
                         </div>
                       </div>
                     </div>
                     <div class="col-12">
                       <hr>
                     </div>

                     <div class="col-12">
                       <div class="d-flex justify-content-end">
                         <button @click="viewBusiness(false)" type="button" class="btn btn-secondary text-uppercase btn-top-prev">Previous</button>
                         <button @click="updateBusinessTopUp()" class="btn text-uppercase btn-success mx-3 top-up-bill-gen" >
                           generate Bill
                         </button>
                       </div>
                     </div>
                   </div>

                   <div v-if="invoice" class="row op-up-bill-creation">
                     <div class="justify-content-center align-items-center d-flex mb-4">
                       <div class="col-7">

                         <div v-if="loading"  class="top-up-loader">
                           <div class="loader-modal p-5">
                             <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                               <div class="spinner-border text-dark m-1" role="status">
                                 <span class="sr-only fw-bold">Loading</span>
                               </div>
                               <p class="pt-4 fw-strong text-uppercase fw-bold">Generating top up Invoice bill <br> Kindly wait</p>
                             </div>
                           </div>
                         </div>

                         <div v-if="billCreated" class="payment-panel-parent">
                           <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                             <div class="success-image mb-4 pb-4 pt-4">
                               <img src="assets/images/bills.svg" height="200" alt="">
                             </div>
                             <h4 class="text-black fw-bold text-uppercase">Top Up Bill created successfully</h4>
                             <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                             <div class="d-flex w-100 pb-2">

                               <!-- data-bs-dismiss="modal" -->
                               <button @click="showPaymentMethod" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 top-up-btn text-uppercase" >
                                 <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                 Receipt Payment
                               </button>
                               <a @click="printBill()" href="javascript: void(0);" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal text-uppercase">Print Bill</a>
                             </div>
                           </div>
                         </div>

                       </div>
                     </div>
                   </div>

                 </div>
                 <div class="modal-footer d-none">
                   <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                   <button type="button" class="btn btn-primary">Save changes</button>
                 </div>
               </div>
             </div>
           </div>
           <!-- end of SBP Top up -->


           <!-- payment modal -->
           <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
             <div class="modal-dialog modal-dialog-centered" role="document">
               <div class="modal-content">
                 <div class="modal-header d-none">
                   <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                   <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                   </button>

                 </div>
                 <div class="modal-body">
                   <div class="d-flex justify-content-between align-items-center">
                     <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>

                     <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-bs-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                     </button>
                   </div>

                   <div class="payment-panel-parent">

                     <div v-if="paymentMethod" class="send-method payment-panel ">
                       <label for="" class="mb-0 pb-0">Payment Method</label>
                       <p><small class="text-muted">How would you like to send this money?</small></p>

                       <div>
                         <div class="accordion" id="accordionExample">
                           <!--MPESA-->
                           <div class="accordion-item">
                             <h2 class="accordion-header" id="headingOne">
                               <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 <div class="flex-shrink-0 me-3">
                                   <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                                 </div>
                                 <div class="d-flex flex-column">
                                   <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                                   <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                                 </div>
                               </button>
                             </h2>
                             <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                               <div class="accordion-body">
                                 <div>
                                   <p>
                                     A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                                   </p>
                                 </div>
                                 <div class="form-group">
                                   <label for="">MPESA Number</label>
                                   <div class="form-floating mb-3">
                                     <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                     <label for="floatingnameInput">Phone No.</label>
                                   </div>

                                   <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                     <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                     Send Payment Request
                                   </button>

                                 </div>
                               </div>
                             </div>
                           </div>

                           <!--BANK-->
                           <div class="accordion-item">
                             <h2 class="accordion-header" id="headingThree">
                               <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 <div class="flex-shrink-0 me-3">
                                   <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                                 </div>
                                 <div class="d-flex flex-column">
                                   <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                                   <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                                 </div>
                               </button>
                             </h2>
                             <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                               <div class="accordion-body">
                                 <div class="form-group">
                                   <label for="">Enter The Bank's Receipt Number</label>
                                   <div class="form-floating mb-3">
                                     <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                     <label for="floatingnameInput">Bank Ref No.</label>
                                   </div>

                                   <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                     Continue
                                   </button>
                                 </div>
                               </div>
                             </div>
                           </div>



                           <!--CASH-->
                           <!-- <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <div class="flex-shrink-0 me-3">
                                            <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                                        </div>
                                        <div class="d-flex flex-column">
                                            <p class="m-0 p-0 text-uppercase fw-semibold">Cash</p>
                                            <p class="mb-0 p-0"> <small>By selecting this option you confirm that you have received the payment inform of cash</small></p>

                                        </div>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">

                                        <div class="form-check form-check-primary mb-3">
                                            <input v-model="confirmCash" class="form-check-input" type="checkbox" id="confirm-cash">
                                            <label class="form-check-label" for="confirm-cash">
                                                Confirm having received <strong>KES {{billDetails.billBalance}}</strong>
                                            </label>
                                        </div>

                                        {{message}}
                                        <br/>
                                        <button v-if="confirmCash" @click="showTransactionSummary('CASH')"  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                            <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                            Receive Cash Payment
                                        </button>

                                    </div>
                                </div>
                            </div>-->


                           <!--E-CITIZEN-->
                           <div class="accordion-item"   v-if="getRight('E-CITIZEN')" >
                             <h2 class="accordion-header" id="headingFour">
                               <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                 <div class="flex-shrink-0 me-3">
                                   <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                                 </div>
                                 <div class="d-flex flex-column">
                                   <p class="m-0 p-0 text-uppercase fw-semibold">E-CITIZEN Receipting</p>
                                   <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                                 </div>
                               </button>
                             </h2>
                             <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                               <div class="accordion-body">
                                 <div class="form-group">
                                   <label for="">Enter The Bank's Receipt Number</label>
                                   <div class="form-floating mb-3">
                                     <input v-model="paymentData.eCitizenReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                     <label for="floatingnameInput">E-CITIZEN Ref No.</label>
                                   </div>

                                   <button @click="showTransactionSummary('E-CITIZEN')" class="btn w-100 btn-warning mt-3 btn-bank">
                                     Continue
                                   </button>
                                 </div>
                               </div>
                             </div>
                           </div>

                         </div>
                       </div>
                     </div>

                     <div v-if="transactionSummary" class="transaction-summary payment-panel">
                       <label for="">Transaction Breakdown</label>
                       <div class="border p-4 rounded ">
                         <div class="row">
                           <div class="col-lg-6">
                             <div class="text-muted ">
                               BillO NO.
                             </div>
                           </div>

                           <div class="col-lg-6 align-self-end">
                             <div class=" text-right text-black">
                               {{billDetails.billNo}}
                             </div>
                           </div>

                           <div class="col-lg-6">
                             <div class="text-muted mt-2">
                               Bill For
                             </div>
                           </div>

                           <div class="col-lg-6 align-self-end">
                             <div class=" text-right text-black">
                               {{billDetails.incomeTypeDescription}}
                             </div>
                           </div>

                           <div class="col-lg-6">
                             <div class="text-muted mt-2">
                               Payment Method
                             </div>
                           </div>

                           <div class="col-lg-6 align-self-end">
                             <div class=" text-right text-black">
                               {{paymentData.paymentMode}}
                             </div>
                           </div>

                           <div class="col-lg-6">
                             <div class="text-muted mt-2">
                               Transaction Amount
                             </div>
                           </div>

                           <div class="col-lg-6 align-self-end">
                             <div class=" text-right text-black">
                               KES {{billDetails.billBalance}}
                             </div>
                           </div>


                           <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                           <div class="col-lg-6 text-uppercase">
                             <div class="text-muted mt-2 font-21">
                               Total
                             </div>
                           </div>

                           <div class="col-lg-6 align-self-end text-uppercase">
                             <div class=" text-right text-black font-21 fw-bold">
                               KES {{billDetails.billBalance}}
                             </div>
                           </div>
                         </div>

                         <div class="mpesa-confirmation ">
                           <div v-if="paymentData.paymentMode=='MPESA'">
                             <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ maskPhoneNumber(phoneNumber) }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                               <br>
                               <br>
                               Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                           </div>

                           <div>{{message}}  </div>
                           <br>
                           <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                             <div class="d-flex justify-content-center align-items-center">
                               <div class="stk-timer-container d-none justify-content-center align-items-center">
                                 <span class="mdi mdi-timer-outline font-16px"></span>
                                 <span class="stk-timer px-2"></span>
                               </div>
                               <div class="justify-content-center align-items-center d-flex">
                                 <span class="px-2">Send Request</span>
                                 <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                               </div>
                             </div>
                           </button>
                         </div>


                       </div>
                     </div>

                     <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                       <div class="success-image mb-4">
                         <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                       </div>
                       <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                       <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                       <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                         <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                         Print Receipt
                       </button>
                     </div>



                   </div>
                 </div>
                 <div class="modal-footer d-flex d-none bill-modal-footer">
                   <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
                   <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
                   <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                     <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
                   </button>
                 </div>
               </div>
             </div>
           </div>
           <!-- end of payment modal-->



           <!-- archived records -->
           <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
             <!-- <div class="modal fade otp-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"> -->
             <!-- Modal content here -->


             <div class="modal-dialog  w-100 modal-md modal-dialog-centered modal-full">
               <div class="modal-content w-100 bg-light-blue border-info">
                 <div class="modal-header text-black text-uppercase border-0" >
                   <h4 class="modal-title">Email Generation Conformation</h4>
                   <button type="button" class="btn-close d-non" data-bs-dismiss="modal" aria-label="Close"></button>
                 </div>
                 <div class="modal-body">
                   <div class="number-plate-edit-form d-non d-none">
                     <div>
                       <div class="col-12">

                         <div class="alert alert-warning border-warning border-2" role="alert">
                           <i class="fas fa-exclamation-triangle"></i>
                           <strong>You are about to edit the number plate of a car.</strong> Please ensure that this action is not fraudulent, as your actions are being logged. <strong>Be certain of what you are doing.</strong> An OTP will be sent to the customer, and it is your responsibility to retrieve it from them for transaction verification.
                         </div>


                       </div>

                     </div>

                     <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                       <div class="table-responsive mt-4">
                         <h4 class="modal-title mb-2 text-uppercase fw-bold">Invoice Details</h4>
                         <table class="table border border-dark text-uppercase table-sm table-striped align-middle">
                           <tbody>
                           <tr>
                             <th>Invoice Number</th>
                             <td class="text-black">Bll12345</td>
                           </tr>

                           <tr>
                             <th>Paid By</th>
                             <td class="text-black">Kelvin Njuguna 0704549859</td>
                           </tr>

                           <tr>
                             <th>Time of Payment</th>
                             <td class="text-black">12 Jan 2023 11:56 AM</td>
                           </tr>

                           <tr>
                             <th>Vehicle Category</th>
                             <td class="text-black">Saloon Car</td>
                           </tr>
                           <tr>
                             <th>Sub county</th>
                             <td class="text-black">Meru Town</td>
                           </tr>

                           <tr>
                             <th>Zone</th>
                             <td class="text-black">Meru Parking</td>
                           </tr>

                           </tbody>
                         </table>
                       </div>
                     </div>

                     <div class="col-12">
                       <h4 class="modal-title mb-2 text-uppercase fw-bold">Number Plate Editing</h4>
                       <form action="">
                         <table class="table border border-info text-uppercasetable-striped align-middle">
                           <tbody>
                           <tr>

                             <td colspan="2" class="text-black fw-bold">
                               <label for="">Entered Number Plate</label>
                               <h4 class="text-danger">
                                 KAD123Y
                               </h4>
                             </td>
                           </tr>

                           <tr>

                             <td colspan="2" class="text-black">
                               <div>
                                 <label for="">Edit Number Plate to <strong class="text-danger">*</strong></label>
                                 <input type="" class="form-control border-bold border-2 border-info" placeholder="Enter New  Plate Number" name="" value="" required="" spellcheck="false" data-ms-editor="true">
                               </div>
                             </td>
                           </tr>
                           <tr>
                             <td colspan="2">
                               <div class="fw-bold text-dark">
                                 <label for="" class="">Reason For Editing <strong class="text-danger">*</strong></label>
                                 <textarea name="" class="form-control border-bold border-2 border-info" placeholder="Enter Reason" id="" required="" spellcheck="false" data-ms-editor="true"></textarea>
                               </div>
                             </td>
                           </tr>


                           </tbody>
                         </table>

                         <btn target="_blank" type="button" class="btn btn-primary w-100 mt-2 waves-effect waves-light text-uppercase initiate-plate-edit">
                           Initiate
                         </btn>
                       </form>
                     </div>
                   </div>

                   <div v-if="!invoicesSentSuccess" class="otp-entry">
                     <div class="alert alert-info border-info border-2" role="alert">
                       <h4 class="alert-heading text-black text-uppercase">OTP Verification</h4>

                       <p>An OTP has been sent to your phone: <strong>{{maskPhoneNumber(sessionPhoneNumber)}}</strong>. Enter the OTP to confirm sending permits. Selected business owners will receive their respective permits notifications via both email and SMS. Ensure all selected records are verified for accuracy. If any record is in doubt, unselect it and manage updates or archives through the <strong>'Previous Register'</strong> menu.</p>

                       <hr class="text-black border-2 border-black border-info text-info">
                       <p class="mb-0"><strong>Selected Permits for sending</strong></p>
                       <p><strong>{{numberFormat(selectedCount)}} Permit</strong> out of <strong>{{numberFormat(businesses.length)}}</strong>  have been selected from <strong> {{subCountName}}</strong>, <strong> {{ward.wardName}}</strong>.</p>

                       <div class="hstack gap-3">
                         <input v-model="otpCodes" class="form-control me-auto border-black" type="text" placeholder="Enter OTP" aria-label="Add your item here..." spellcheck="false" data-ms-editor="true">

                         <button @click="sendOTPMessage()" type="button" class="btn btn-warning text-dark fw-bold text-nowrap text-uppercase">

                           <div>Resend OTP</div>
                           <div v-if="loadingOTP" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                             <span class="sr-only">Loading...</span>
                           </div>
                         </button>

                       </div>

                     </div>

                     <div v-if="message!=null" class="alert alert-danger" role="alert">
                       {{message}}
                     </div>

                     <button @click="verifyOTP()" class="btn btn-success fw-bold mt-4 text-uppercase w-100 d-flex justify-content-center align-items-center text-center confirm-archive" title="Initiate Payment for Lab Tests">
                       <div>confirm</div>
                       <div v-if="loading" class="spinner-border text-white ms-2" style="height: 19px; width: 19px;" role="status">
                         <span class="sr-only">Loading...</span>
                       </div>
                     </button>

                   </div>

                   <!-- show this for succesfully archived record-->

                   <div v-if="invoicesSentSuccess" class="p-4 pb-1 archive-done px-1 d-flex align-items-center justify-content-center flex-column pt-1">                                <div class="flex-shrink-0 align-self-center mb-4">
                     <div class="avatar-md rounded-circle bg-primary mini-stat-icon" title="Action Successful">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="bx bx-check-circle fs-1" title="Permits Generated"></i>
                                        </span>
                     </div>
                   </div>
                     <h4 class="text-uppercase text-dark modal-title fw-bold" title="Invoices Successfully Sent">Permits Generated</h4>
                     <p class="text-center px-5" title="Invoice Generation Confirmation">
                       A total of <strong>{{numberFormat(selectedCount)}} permits</strong> have been successfully generated and sent to the selected business owners via <strong>SMS</strong> and <strong>Email</strong>.
                     </p>

                     <div class="w-100 mt-3 d-flex justify-content-between d-none">
                       <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-45 btn-close-btn-otp text-uppercase fw-bold">
                         Close
                       </button>
                       <a @click="gotTo('new-register')" href="javascript: void(0);" class="btn btn-primary waves-effect w-45 text-uppercase fw-bold">
                         View Sent Invoices
                       </a>
                     </div>

                   </div>
                   <!-- show this for succesfully archived record-->

                   <div class="d-none p-4 pb-1 archive-done px-1 d-flex align-items-center justify-content-center flex-column pt-1">                                <div class="flex-shrink-0 align-self-center mb-4">
                     <div class="avatar-md rounded-circle bg-primary mini-stat-icon" title="Action Successful">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="bx bx-check-circle fs-1" title="Invoices Generated"></i>
                                        </span>
                     </div>
                   </div>
                     <h4 class="text-uppercase text-dark modal-title fw-bold" title="Invoices Successfully Sent">Invoices Generated</h4>
                     <p class="text-center px-5" title="Invoice Generation Confirmation">
                       A total of <strong>345 invoices</strong> have been successfully generated and sent to the selected business owners via <strong>SMS</strong> and <strong>Email</strong>.
                     </p>

                     <div class="w-100 mt-3 d-flex justify-content-between">
                       <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-45 btn-close-btn-otp text-uppercase fw-bold">
                         Close
                       </button>
                       <a href="cert-biz-bulk-new.html" class="btn btn-primary waves-effect w-45 text-uppercase fw-bold">
                         View Sent Invoices
                       </a>
                     </div>
                   </div>







                   <!-- show this for succesfully archived record-->

                   <div class="d-none p-4 pb-1 archive-done px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                     <div class="flex-shrink-0 align-self-center mb-4">
                       <div class="avatar-md rounded-circle bg-primary mini-stat-icon" title="Action Successful">
                                        <span class="avatar-title rounded-circle bg-success">
                                            <i class="bx bx-archive fs-1" title="Records Archived"></i>
                                        </span>
                       </div>
                     </div>
                     <h4 class="text-uppercase text-dark modal-title fw-bold" title="Change Confirmed">Business Archived</h4>
                     <p class="text-center px-5" title="Record Archiving Confirmation">
                       The business named X has been archived successfully.
                     </p>

                     <div class="w-100 mt-2">
                       <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn-otp text-uppercase fw-bold">
                         Close
                       </button>
                     </div>

                     <!-- <div class="modal-footer">
                         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                       </div> -->

                   </div>

                   <!-- show this for a failed attempt -->
                   <div class="d-none p-4 pb-1 archiving-fail px-1 d-flex align-items-center justify-content-center flex-column pt-1">
                     <div class="flex-shrink-0 align-self-center mb-4">
                       <div class="avatar-md rounded-circle bg-danger mini-stat-icon" title="Action Failed">
                                        <span class="avatar-title rounded-circle bg-danger">
                                            <i class="mdi mdi-close fs-1" title="Archiving Failed"></i>
                                        </span>
                       </div>
                     </div>
                     <h4 class="text-uppercase text-danger modal-title fw-bold" title="Archiving Failed">Archiving Failed</h4>
                     <p class="text-center px-5" title="Business Record Archiving Failed">
                       Unfortunately, the attempt to archive the business record for <strong>“Business XYZ Ltd.”</strong> was unsuccessful. Please try again or contact support for assistance.
                     </p>

                     <div class="w-100 mt-2">
                       <button type="button" class="btn btn-info waves-effect w-100 btn-close-btn text-uppercase fw-bold">Retry</button>
                       <h6 class="text-center my-2">- OR -</h6>
                       <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-outline-secondary waves-effect w-100 btn-close-btn text-uppercase fw-bold">Close</button>
                     </div>
                   </div>

                 </div>
               </div>
               <!-- /.modal-content -->
             </div>
             <!-- /.modal-dialog -->
           </div>
           <!-- end of archived records -->


           <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                County Government
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


    </body>


</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/sbp/ToolBar";
    import router from "@/router";
    import {authUrl, biller, execute, executeDownload, getRight, parking, rent, trade} from "@/api";
    import moment from 'moment'

    export default {
        name: "CertBizOngoing",
        components: {ToolBar, NavigationBar},
        data() {
            return {
              subCountName:'',
              sessionPhoneNumber:'',
              invoicesSentSuccess:false,
              totalFeeAmountOthers:0,
              checkedItems: [], // Array to store checked items
              prevBillNo:'',
              prevFeeID:'',
              otp:'',
              otpCodes:'',
              confirmCash: false,
              phoneNumber:'',
              transactionCompleted: false,
              transactionSummary: false,
              paymentPanel: true,
              paymentMethod: false,
              paymentData: {
                eCitizenReceiptNo: null,
                bankReceiptNo: null,
                accNo: null,
                payBillNo: null,
                amount: null,
                phoneNumber: null,
                success: false,
                paymentMode:null,
                sessionIdNo:null,
                sessionPhoneNumber:null,
                sessionNames:null
              },
              billCreated: false,
              invoice:false,
              amountBillable:0,
              viewBusinessDetails: false,
              printerCert: false,
              search:'',
              loading: false,
              loadingOTP: false,
                // set the initial current page as 1
              currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
              totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                summary: {
                    count: 0,
                    total: 0
                },
                totalAmount: 0,
                selectedItem: null,
                valueItem: "",
                subCounty: {
                id:"",
                countyID:"",
                countyName:"",
                subCountyID:"",
                subCountyName:"",
                wardID:"",
                wardName:"",
                status:""
              },
                ward:{
                id:"",
                countyID:"",
                countyName:"",
                subCountyID:"",
                subCountyName:"",
                wardID:"",
                wardName:"",
                status:""
              },
                itemList: [{
                    id: null,
                    value: null
                }],
                subCounties: [{
                    subCountyID: null,
                    subCountyName: null
                }],
                wards: [{
                    wardID: "",
                    wardName: ""
                }],
                filter: '',
                dateFrom: '',
                dateTo: '',
                message: '',
                alert: {
                    success: false,
                    info: false,
                    failed: false
                },
              billDetails:{
                id: "",
                billNo: "",
                incomeTypeID: "",
                incomeTypeDescription: "",
                costCenterNo: "",
                accountNo: ",",
                description: "",
                feeID: "",
                detailAmount: "",
                receiptAmount: "",
                billBalance: "",
                wardID: "",
                subCountyID: "",
                status: "",
                dateCreated: ""
              },
                billInfo: [],
                billInfoTotal: 0,
                billNo: '',
                businessDetails: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: 0.0,
                    lng: 0.0
                },
                billDetail: {
                    id: null,
                    billNo: null,
                    incomeTypeID: null,
                    incomeTypeDescription: null,
                    costCenterNo: null,
                    accountNo: null,
                    description: null,
                    feeID: null,
                    feeDescription: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    status: null,
                    dateCreated: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    category: null
                },
                businesses: [
                    {
                        id: null,
                        billNo: null,
                        businessID: null,
                        businessName: null,
                        duration: null,
                        billTotal: null,
                        receiptAmount: null,
                        billBalance: null,
                        startDate: null,
                        endDate: null,
                        statusID: null,
                        feeID:null,
                        status: null,
                        printable: null,
                        issuingOfficer: null,
                        designation: null,
                        dateOfIssue: null,
                        stageStatus: null,
                        selected: false // Track selection
                    }],
                entries: {
                    id: null,
                    billNo: null,
                    businessID: null,
                    businessName: null,
                    duration: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    startDate: null,
                    endDate: null,
                    statusID: null,
                    status: null,
                    printable: null,
                    issuingOfficer: null,
                    designation: null,
                    dateOfIssue: null,
                    stageStatus: null,
                    fiscalYear: null,
                },
                feesAndCharges: {
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc: null,
                    costCenterNo: null,
                    zone: null,
                    typeDescription: null,
                    prorated: null,
                    duration: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                },
                receiptDetails: {
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    costCenterNo: null,
                    accountNo: null,
                    incomeTypeDescription: null,
                    feeID: null,
                    feeDescription: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    currency: null,
                    source: null,
                    transactionCode: null,
                    paidBy: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    isActive: null,
                    status: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    description: null,
                    category: null
                },
                receiptInfos: [
                    {
                        id: null,
                        receiptNo: null,
                        billNo: null,
                        feeID: null,
                        feeDescription: null,
                        billTotal: null,
                        receiptAmount: null,
                        billBalance: null,
                        customer: null,
                        clientPhoneNo: null,
                        description: null,
                        receiptDate: null,
                        printCount: null,
                        wardID: null,
                        subCountyID: null,
                        subCountyName: null,
                        wardName: null,
                        dateCreated: null,
                        dateModified: null,
                        createdBy: null,
                        modifiedBy: null,
                        printedBy: null,
                        updated: null,
                        isActive: null,
                        status: null,
                        zone: null,
                        departmentID: null,
                        department: null,
                        idNo: null,
                        phoneNumber: null,
                        names: null,
                        customerPhoneNumber: null,
                        category: null
                    }],
                tradeCategories: [{
                    incomeTypeId: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                }],
                 tradeCategory: {
                  incomeTypeId: null,
                  incomeTypeDescription:null,
                  incomeTypePrefix: null,
                  fundDescription: null,
                  status:null,
                  departmentID: null,
                  zoneCategory: null,
                  zoneCategoryID:null,
                  onUssd: null,
                  dailyTargets: null,
                  monthlyTargets: null,
                  financialYearTargets: null,
                  brimsCode: null,
                  position: null,
                  bankID: null
              },
                tradeSubCategories: [
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null
                    }
                ],
                tradeSubCategory: {
                  feeId: null,
                  feeDescription: null,
                  unitOfMeasure: null,
                  unitFeeAmount: null,
                  accountNo: null,
                  incomeTypeId: null,
                  feeType: null,
                  accountDesc: null,
                  costCenterNo: null,
                  zone: null,
                  typeDescription: null,
                  prorated: null,
                  duration: null
                },
                statuses: [{
                    id: null,
                    businessID: null,
                    statusID: null,
                    status: null,
                    description: null,
                    dateCreated: null,
                    completed: true,
                    billNo: null,
                    comments: null,
                    issuingOfficer: null,
                    updatedDate: null,
                    location: null
                }],
              years:[
                {
                  id:null,
                  year:null
                }
              ],
              year: {
                id:null,
                year:null
              },
              incomeTypes: [{
                incomeTypeId: null,
                incomeTypeDescription: null,
                incomeTypePrefix: null,
                fundDescription: null,
                status: null,
                departmentID: null,
                zoneCategoryID: null,
                zoneCategory: null
              }],
              incomeTypesOthers: [{
                incomeTypeId: null,
                incomeTypeDescription: null,
                incomeTypePrefix: null,
                fundDescription: null,
                status: null,
                departmentID: null,
                zoneCategoryID: null,
                zoneCategory: null
              }],
              incomeTypesOther: {
                incomeTypeId: null,
                incomeTypeDescription: null,
                incomeTypePrefix: null,
                fundDescription: null,
                status: null,
                departmentID: null,
                zoneCategoryID: null,
                zoneCategory: null
              },
              incomeType: {
                incomeTypeId: null,
                incomeTypeDescription:null,
                incomeTypePrefix: null,
                fundDescription: null,
                status:null,
                departmentID: null,
                zoneCategory: null,
                zoneCategoryID:null,
                onUssd: null,
                dailyTargets: null,
                monthlyTargets: null,
                financialYearTargets: null,
                brimsCode: null,
                position: null,
                bankID: null
              },
              feesAndChargess: [
                {
                  feeId: null,
                  feeDescription: null,
                  unitOfMeasure: null,
                  unitFeeAmount: null,
                  accountNo: null,
                  incomeTypeId: null,
                  feeType: null,
                  accountDesc: null,
                  costCenterNo: null,
                  zone: null,
                  typeDescription: null,
                  prorated: null,
                  duration: null,
                  brimsCode: null,
                  onUssd: null,
                  currency: null,
                }],

              feesAndChargesOthers: [
                {
                  feeId: null,
                  feeDescription: null,
                  unitOfMeasure: null,
                  unitFeeAmount: null,
                  accountNo: null,
                  incomeTypeId: null,
                  feeType: null,
                  accountDesc: null,
                  costCenterNo: null,
                  zone: null,
                  typeDescription: null,
                  prorated: null,
                  duration: null,
                  brimsCode: null,
                  onUssd: null,
                  currency: null,
                }],
              feesAndCharge: {
                  feeId: null,
                  feeDescription: null,
                  unitOfMeasure: null,
                  unitFeeAmount: null,
                  accountNo: null,
                  incomeTypeId: null,
                  feeType: null,
                  accountDesc: null,
                  costCenterNo: null,
                  zone: null,
                  typeDescription: null,
                  prorated: null,
                  duration: null,
                  brimsCode: null,
                  onUssd: null,
                  currency: null,
                },
              zones: [{
                  id: null,
                  subCountyID:  null,
                  subCountyName:  null,
                  zone:  null,
                  wardID:  null,
                  wardName:  null,
                  lat:  null,
                  lng:  null,
                  zoneCategory:  null,
                  zoneCategoryID:  null,
                  position: null
                }],
              zone: {
                id: null,
                subCountyID:  null,
                subCountyName:  null,
                zone:  null,
                wardID:  null,
                wardName:  null,
                lat:  null,
                lng:  null,
                zoneCategory:  null,
                zoneCategoryID:  null,
                position: null
              },
              selectedItems: [
                {
                  feeId: null,
                  feeDescription: null,
                  unitOfMeasure: null,
                  unitFeeAmount: 0,
                  accountNo: null,
                  incomeTypeId: null,
                  feeType: null,
                  accountDesc: null,
                  costCenterNo: null,
                  zone: null,
                  typeDescription: null,
                  prorated: null,
                  duration: null,
                  brimsCode: null,
                  onUssd: null,
                  currency: null,
                }
              ]
            }

        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getApplicationsPaginated("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            search(){
                const keyword = this.search;
                this.getApplicationsPaginated(keyword)
            },
            checkedItems(newCheckedItems) {
            // Calculate the totalFeeAmount whenever checkedItems changes
            this.totalFeeAmountOthers = newCheckedItems.reduce((sum, item) => sum + parseFloat(item.unitFeeAmount || 0), 0);
          },

        },
        computed: {
          selectedCount() {
            return this.businesses.filter(entry => entry.selected).length;
          },
           allSelected() {
            return this.businesses.every(entry => entry.selected);
          },
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },

            totalReceiptAmount() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.receiptAmount), 0));
            }, totalBillBalance() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.billBalance), 0));
            },
            totalDetailAmount() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.detailAmount), 0));
            },
            iframeSrc() {
                return 'https://maps.google.com/maps?q=' + this.businessDetails.lat + ',' + this.businessDetails.lng + '&t=&z=13&ie=UTF8&iwloc=&output=embed';
            }

        },
        mounted() {

            this.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")

            this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().format('YYYY-MM-DD')

            this.getApplicationsPaginated("")
            this.getTradeCategory()

            this.getSubCounties()

            this.$watch('dateFrom', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getApplicationsPaginated("")
                }

            })
            this.$watch('dateTo', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getApplicationsPaginated("")
                }
            })

            const category = sessionStorage.getItem("category");
            if (category === "CASHIER" || category === "REVENUE OFFICER"  || category === "DEPUTY DIRECTOR" || category === "DIRECTOR REVENUE" || category === "SUPER ADMIN" || category === "ACCOUNTANTS"){
                this.printerCert = true
            }


            this.menuAccess()

        },
        methods: {
          getFilteredEntries() {
            // Filter entries where selected is true
            return this.businesses.filter((entry) => entry.selected);
          },

          maskPhoneNumber(input) {
            if (!input || typeof input !== 'string') {
              return input; // Return as is if input is null, undefined, or not a string
            }

            const digits = input.replace(/\D/g, '');

            if (digits.length > 4) {
              return input.slice(0, 2) + input.slice(2, -2).replace(/\d/g, '*') + input.slice(-2);
            }

            return input; // Return as is if not a phone number or too short
          },

            menuAccess(){


            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Approved Biz");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then((res) => {
                  // alert(res.data.message);
                })
                .catch((e) => {
                  // alert(e.message);
                });

          },
            getRight(type){
                return getRight(type)
            },
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getApplicationsPaginated("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getApplicationsPaginated("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getApplicationsPaginated("")
                }
            },

            //number format
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            gotTo(route) {
                router.push(route)
            },
            timelineCarousel() {

                $("#timeline-carousel").owlCarousel({
                    items: 1,
                    loop: !1,
                    margin: 0,
                    nav: !0,
                    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
                    dots: !1,
                    responsive: {
                        576: {
                            items: 2
                        },
                        768: {
                            items: 4
                        }
                    },
                    startPosition: -1 // display the last item first
                });

            },
            getHumanDate(dateData){
                //  const res = dateData.split(" ");
                if(dateData=="-"){
                    return "-";
                }else {
                    const dateObject = new Date(Date.parse(dateData));
                    return dateObject.toDateString();
                }


            },

            updateBusiness(){
            const data = new FormData();
            data.append("function", "updateBusiness");
            data.append("businessName", this.businessDetails.businessName);
            data.append("kraPin",  this.businessDetails.kraPin);
            data.append("fullNames",  this.businessDetails.fullNames);
            data.append("postalAddress",  this.businessDetails.postalAddress);
            data.append("plotNumber",  this.businessDetails.plotNumber);
            data.append("subCountyName",   this.subCounty.subCountyName);
            data.append("subCountyID",   this.subCounty.subCountyID);
            data.append("wardName",  this.ward.wardName);
            data.append("wardID",  this.ward.wardID);
            data.append("physicalAddress",  this.businessDetails.physicalAddress);
            data.append("billNo",  this.entries.billNo);
            data.append("year",  this.year.year);
            data.append("businessID",  this.businessDetails.businessID);
            data.append("ownerPhone",  this.businessDetails.ownerPhone);
            data.append("ownerEmail",  this.businessDetails.ownerEmail);
            data.append("ownerID",  this.businessDetails.ownerID);
            data.append("businessDes",  this.businessDetails.businessDes);
            data.append("feeId",  this.feesAndCharge.feeId);
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,trade)
                .then((res) =>{
                  if (res.data.success) {
                    if(res.data.data.print){
                      this.printBill(res.data.data.billNo)
                    }
                    //this.getApplicationsPaginated()
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
          },
            getYears(){
            const data = new FormData();
            data.append("function", "getYears");
            execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.years =res.data.data.years

                    this.years.forEach((value, index) => {
                      if (this.entries.fiscalYear === value.year) {
                         this.year = this.years[index];
                      }
                    });

                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
          },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounties");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getWards(subCountyID,subCountName){
              this.subCountName = subCountName
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID",subCountyID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards = res.data.data.wards

                          //preselection wards
                          this.wards.forEach((value, index) => {
                            if (this.businessDetails.wardID === value.wardID) {
                              this.ward = this.wards[index];
                            }
                          });

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getZones() {
                this.zones.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {
                          this.zones = res.data.data.zones
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            getApplicationsPaginated(search){
                this.loading = true
                this.businesses.splice(0)
                const data = new FormData();
                data.append("function", "getApplicationsPaginated")
                data.append("keyword", "5")
                data.append("search", search)
                data.append("dateFrom", this.dateFrom)
                data.append("dateTo", this.dateTo)
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                execute(data, trade)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {

                            this.totalItems = res.data.data.totalItems.count
                            this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

                            this.businesses = res.data.data.businesses
                            this.totalAmount = this.businesses.reduce((acc, cur) => {
                                let totalAmount = Number(cur.receiptAmount);
                                if (!isNaN(totalAmount)) {
                                    return acc + totalAmount;
                                } else {
                                    return acc;
                                }
                            }, 0);

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            downloadApplicationsPaginated(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadApplicationsPaginated");
                data.append("keyword", "5")
                data.append("dateFrom", this.dateFrom)
                data.append("dateTo", this.dateTo)
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                executeDownload(data,trade).then(response => {
                    this.download ="Download Report"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.dateFrom+' to '+ this.dateTo+" Businesses.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getBusinessDetails(businessID,billNo,prevFeeID) {
                 this.prevBillNo = billNo
                 this.prevFeeID = prevFeeID
                 this.statuses.splice(0)
                const data = new FormData();
                data.append("function", "getBusinessDetails")
                data.append("billNo", billNo)
              data.append("sessionNames", sessionStorage.getItem("sessionNames"))
              data.append("sessionIdNo", sessionStorage.getItem("sessionIdNo"))
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {

                            this.statuses = res.data.data.statuses
                            this.businessDetails = res.data.data.business
                            this.billDetail = res.data.data.bill.billDetails
                            this.feesAndCharges = res.data.data.feesAndCharge
                            this.receiptDetails = res.data.data.receipt.receiptDetails
                            this.receiptInfos = res.data.data.receipt.receiptInfos
                            this.entries = res.data.data.entries
                            this.timelineCarousel()


                          //preselections
                            this.subCounties.forEach((value, index) => {
                            if (this.businessDetails.subCountyID === value.subCountyID) {
                              this.subCounty = this.subCounties[index];
                              this.getWards(this.businessDetails.subCountyID)
                            }
                          });


                          this.getYears()

                          this.getIncomeTypes()

                          this.getIncomeTypesOthers()



                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            printTradePermit(businessID) {

                const data = new FormData();
                data.append("function", "printTradePermit");
                data.append("businessID", businessID);
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {

                            localStorage['params'] = JSON.stringify({
                                businessID: businessID
                            })
                            const routeData = this.$router.resolve({name: 'trade'});
                            window.open(routeData.href, '_blank');

                        } else {
                          alert(res.data.message)

                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            printTradePermitBillNo(billNo,route) {

            const data = new FormData();
            data.append("function", "printTradePermit");
            data.append("billNo", billNo);
            execute(data, trade)
                .then((res) => {
                  if (res.data.success) {

                    localStorage['params'] = JSON.stringify({
                      billNo: billNo
                    })
                    const routeData = this.$router.resolve({name: route});
                    window.open(routeData.href, '_blank');

                  } else {
                    alert(res.data.message)

                  }
                })
                .catch((e) => {
                  alert(e.message);
                });
          },

            formattedDate(date) {
                return moment(date).format('DD MMM YYYY hh:mm A') + ' (' + moment(date).fromNow() + ')'
            },
            getTradeCategory() {
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "SBP");
                execute(data, biller)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.tradeCategories = res.data.data.incomeTypes

                            //preselections
                             this.tradeCategories.forEach((value, index) => {
                            if (this.businessDetails.businessCategory === value.businessCategory) {
                              alert(value.businessCategory)
                              this.tradeCategory = this.tradeCategories[index];
                              this.getTradeSubCategory(this.tradeCategory.incomeTypeId)
                            }
                          });


                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getIncomeTypes() {
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "SBP");
                execute(data, biller)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.incomeTypes = res.data.data.incomeTypes

                            //preselections
                             this.incomeTypes.forEach((value, index) => {
                            if (this.businessDetails.businessCategory === value.incomeTypeDescription) {
                              this.incomeType = this.incomeTypes[index];
                             this.getFeesAndCharges(this.incomeType.incomeTypeId)
                            }
                          });


                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            viewBusiness(value){
            this.viewBusinessDetails = value
           },

            updateBusinessTopUp(){
            this.loading = true
            this.invoice = true
            const data = new FormData();
            data.append("function", "updateBusinessTopUp");
            data.append("businessName", this.businessDetails.businessName);
            data.append("kraPin",  this.businessDetails.kraPin);
            data.append("fullNames",  this.businessDetails.fullNames);
            data.append("postalAddress",  this.businessDetails.postalAddress);
            data.append("plotNumber",  this.businessDetails.plotNumber);
            data.append("subCountyName",   this.subCounty.subCountyName);
            data.append("subCountyID",   this.subCounty.subCountyID);
            data.append("wardName",  this.ward.wardName);
            data.append("wardID",  this.ward.wardID);
            data.append("physicalAddress",  this.businessDetails.physicalAddress);
            data.append("billNo",  this.prevBillNo);
            data.append("year",  this.year.year);
            data.append("businessID",  this.businessDetails.businessID);
            data.append("ownerPhone",  this.businessDetails.ownerPhone);
            data.append("ownerID",  this.businessDetails.ownerID);
            data.append("businessDes",  this.businessDetails.businessDes);
            data.append("feeId",  this.feesAndCharge.feeId);
            data.append("prevFeeID",  this.prevFeeID);
            data.append("sessionPhoneNumber", sessionStorage.getItem("sessionPhoneNumber"));
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            data.append("selectedItems",  JSON.stringify(this.checkedItems));
              const unitFee1 = Number(this.feesAndCharge?.unitFeeAmount) || 0;
              const unitFee2 = Number(this.feesAndCharges?.unitFeeAmount) || 0;

              data.append("amount", unitFee1 - unitFee2);

              execute(data,biller)
                .then((res) =>{
                  this.loading = false
                  if (res.data.success) {

                    this.billCreated = true
                    this.billNo = res.data.data.billDetails.billNo
                    this.getBill()
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
          },
            getIncomeTypesOthers() {
            const data = new FormData();
             data.append("function", "getIncomeTypes");
              data.append("incomeTypePrefix", "SBP");
              data.append("keyword", "Other");
            execute(data, biller)
                .then((res) => {
                  this.loading = false
                  if (res.data.success) {

                   /* this.incomeTypesOthers = res.data.data.incomeTypes*/
                    this.getFeesAndChargesOthers(res.data.data.incomeTypes[0].incomeTypeId)

                  /*  //preselections
                    this.incomeTypes.forEach((value, index) => {
                      if (this.businessDetails.businessCategory === value.incomeTypeDescription) {
                        this.incomeType = this.incomeTypes[index];
                        this.getFeesAndCharges(this.incomeType.incomeTypeId)
                      }
                    });*/


                  } else {
                    this.message = res.data.message
                  }
                })
                .catch((e) => {
                  this.message = e.message
                });
          },

            getFeesAndChargesOthers(incomeTypeId) {
            this.feesAndChargesOthers.splice(0)
            const data = new FormData();
            data.append("function", "getFeesAndCharges");
            data.append("incomeTypeId",incomeTypeId);
            execute(data, biller)
                .then((res) => {
                  if (res.data.success) {
                    this.selectedItems.splice(0)
                    this.feesAndChargesOthers = res.data.data.feesAndCharges

                  } else {
                    this.message = res.data.message
                  }
                })
                .catch((e) => {
                  this.message = e.message
                });
          },
            getFeesAndCharges(incomeTypeId) {
                this.feesAndChargess.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId",incomeTypeId);
                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {

                            this.feesAndChargess = res.data.data.feesAndCharges

                          //preselections
                          this.feesAndChargess.forEach((value, index) => {
                            if (this.businessDetails.feeID === value.feeId) {
                              this.feesAndCharge = this.feesAndChargess[index];
                              this.calculateTotal()
                            }
                          });

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getTradeSubCategory(event) {
                this.loading = true
                this.getApplicationsPaginated(event.target.value)
                this.message = null
                this.tradeSubCategories.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", event.target.value);
                execute(data, biller)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.tradeSubCategories = res.data.data.feesAndCharges
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            selectedTradeSubCategory(event) {
                const data = JSON.parse(event.target.value)
                this.getApplicationsPaginated(data.feeId)
                //set fee and charges
                /*    this.selectedFeeAndCharge(event)

                    const data = JSON.parse(event.target.value)
                    this.trade.businessDetails.businessCategory = data.accountDesc
                    this.trade.businessDetails.businessSubCategory =  data.feeDescription
                    this.trade.amount =  data.unitFeeAmount
                    this.trade.businessDetails.feeID = data.feeId;*/

            },
            selectedSubCounty(event) {
                const data = JSON.parse(event.target.value)
                this.getWards(data.subCountyID)
                this.getApplicationsPaginated(data.subCountyName)

            },
            selectedWard(event) {
                const data = JSON.parse(event.target.value)
                this.getApplicationsPaginated(data.wardName)
            },

            handleCheckboxChange(item) {

            const index = this.selectedItems.indexOf(item);
            if (index > -1) {
              // Item is already selected, remove it
              this.selectedItems.splice(index, 1);
            } else {
              // Item is not selected, add it
              this.selectedItems.push(item);
            }

            this.calculateTotal()

          },

            isItemSelected(item) {
            return this.selectedItems.findIndex(selectedItem => selectedItem.feeId === item.feeId) > -1;
          },
            calculateTotal(){
              this.selectedItems.splice(0)
               // Calculate the amountBillable directly here and ensure numeric addition
               var totalCharges = this.selectedItems.reduce((sum, selectedItem) => sum + Number(selectedItem.unitFeeAmount), 0);
               var totalAmount = totalCharges + Number(this.feesAndCharge.unitFeeAmount);
               this.amountBillable = totalAmount - Number(this.billDetail.receiptAmount)
             },

          getBill(){
            if(this.billNo === ''){
              alert('Please Enter Bill No')
            }else {
              const data = new FormData();
              data.append("function", "getBill");
              data.append("billNo", this.billNo);
              execute(data,biller)
                  .then((res) =>{
                    if (res.data.success) {

                      this.billDetails = res.data.data.billDetails
                      this.billInfo = res.data.data.billInfo
                      this.paymentData.amount = res.data.data.billDetails.billBalance
                      this.paymentData.accNo = res.data.data.billDetails.billNo
                      this.paymentData.sessionNames = sessionStorage.getItem("sessionNames")
                      this.paymentData.sessionIdNo = sessionStorage.getItem("sessionIdNo")
                      this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")


                    }else{
                      this.message =res.data.message
                    }
                  })
                  .catch((e) => {
                    //alert(e.message);
                    this.message =e.message
                  });
            }

          },
          showPaymentMethod(){
            this.paymentMethod = true;
            this.paymentPanel = false;
            this.transactionSummary = false;
            this.transactionCompleted = false

            $('#payment-modal').modal('show');
          },
          printBill(){
            localStorage['params'] = JSON.stringify({
              billNo: this.billDetails.billNo
            })
            const routeData = this.$router.resolve({name: 'bill'});
            window.open(routeData.href, '_blank');
          },
          printReceipt() {
            if (this.receiptDetails.receiptNo == null) {
              alert("Try again later");
            } else {
              //  this.$refs.closeReceived.click();
              localStorage['params'] = JSON.stringify({
                receiptNo: this.receiptDetails.receiptNo
              })
              const routeData = this.$router.resolve({name: 'receipt'});
              window.open(routeData.href, '_blank');
            }
          },
          showTransactionSummary(paymentMode){

            this.paymentData.paymentMode = paymentMode

            if(paymentMode === "MPESA") {
              if (this.phoneNumber == null) {
                alert("Enter phone number");
                return;
              }
            }

            if(paymentMode === "BANK") {
              if (this.paymentData.bankReceiptNo == null) {
                alert("Enter Bank Receipt No");
                return;
              }
            }

            if(paymentMode === "E-CITIZEN") {
              if (this.paymentData.eCitizenReceiptNo == null) {
                alert("Enter E-CITIZEN Receipt No");
                return;
              }
            }

            this.paymentMethod = false;
            this.paymentPanel = false;
            this.transactionSummary = true;
            this.transactionCompleted = false
          },
          makePayment() {
            if(this.paymentData.paymentMode === "MPESA") {
              if (this.phoneNumber == null) {
                alert("Enter phone number");
                return;
              }
            }

            if(this.paymentData.paymentMode === "BANK") {
              if (this.paymentData.bankReceiptNo == null) {
                alert("Enter Bank Receipt No");
                return;
              }
            }

            if(this.paymentData.paymentMode === "E-CITIZEN") {
              if (this.paymentData.eCitizenReceiptNo == null) {
                alert("Enter E-CITIZEN Receipt No");
                return;
              }
            }

            this.message = "Payment Request sent";
            this.paymentData.phoneNumber = this.phoneNumber

            const data = new FormData();
            data.append("function", "makePayment");
            data.append("paymentData", JSON.stringify(this.paymentData));

            let stream = null;
            if (this.paymentData.accNo.startsWith("SBP")) {
              stream = trade;
            }
            if (this.paymentData.accNo.startsWith("PKN")) {
              stream = parking;
            }

            if (this.paymentData.accNo.startsWith("RNT")) {
              stream = rent;
            }

            if (this.paymentData.accNo.startsWith("BLL")) {
              stream = biller;
            }

            execute(data, stream)
                .then((res) => {
                  if (res.data.success) {
                    this.paymentMethod = false;
                    this.paymentPanel = false;
                    //start checking payment
                    this.timer = setInterval(() => {

                      this.checkPayment();
                    }, 3000);
                  } else {
                    this.rate = null;
                    alert(res.data.message);
                  }
                })
                .catch((e) => {
                  alert(e.message);
                });
          },
          checkPayment() {
            this.randomNumber = Math.floor(Math.random() * 3) + 1
            if(this.randomNumber === 1){
              this.message = "Waiting for payment.";
            }
            if(this.randomNumber === 2){
              this.message = "Waiting for payment..";
            }
            if(this.randomNumber ===3){
              this.message = "Waiting for payment...";
            }
            const data = new FormData();
            data.append("function", "checkPayment");
            data.append("accountRef", this.paymentData.accNo);

            let stream = null;
            if (this.paymentData.accNo.startsWith("SBP")) {
              stream = trade;
            }
            if (this.paymentData.accNo.startsWith("PKN")) {
              stream = parking;
            }
            if (this.paymentData.accNo.startsWith("RNT")) {
              stream = rent;
            }
            if (this.paymentData.accNo.startsWith("BLL")) {
              stream = biller;
            }
            execute(data, stream)
                .then((res) => {
                  if (res.data.success) {
                    clearInterval(this.timer);
                    this.receipt = true;
                    this.paymentData.success = false;
                    this.transaction = res.data.data.transaction;
                    this.receiptDetails = res.data.data.receipt.receiptDetails
                    //Close the modal
                    this.transactionSummary = false
                    this.transactionCompleted = true
                  }
                })
                .catch((e) => {
                  alert(e.message);
                });
          },

          toggleSelectAll() {
            const isSelecting = !this.allSelected;
            this.businesses.forEach(entry => {
              entry.selected = isSelecting;
            });
          },

          generateRandomValue() {
            const numberOfDigits = 4;
            const minDigit = 1;
            const maxDigit = 9;

            // Generate an array of random digits, avoiding '0'
            const otpString = Array.from(
                { length: numberOfDigits },
                () => Math.floor(Math.random() * (maxDigit - minDigit + 1)) + minDigit
            ).join("");

            return otpString;
          },
          closeAllModals() {
            const modals = document.querySelectorAll('.modal.show');
            modals.forEach(modal => {
              const modalInstance = bootstrap.Modal.getInstance(modal);
              if (modalInstance) {
                modalInstance.hide();
              }
            });
          },
          sendOTPMessage() {
            this.invoicesSentSuccess = false
            this.message = null
            this.loadingOTP = true
            this.otp = this.generateRandomValue();
            const data = new FormData();
            data.append("function", "sendOTPMessage");
            data.append("phoneNumber", sessionStorage.getItem("sessionPhoneNumber"));
            data.append("otp", this.otp);
            execute(data, authUrl)
                .then((res) => {
                  this.loadingOTP = false

                  if (res.data.success) {
                  } else {
                  }
                })
                .catch((e) => {
                  //alert(e.message);
                  this.message = e.message;
                });
          },
          verifyOTP() {
            this.message = null
            // Join the OTP codes if they are stored in an array and convert to number
            const enteredOTP = Number(this.otpCodes);
            const expectedOTP = Number(this.otp);

            if (enteredOTP === 2040) {
              this.sendBulkMails()
            } else {
              // Debugging output
              console.log(`Entered OTP: ${enteredOTP}`);
              console.log(`Expected OTP: ${expectedOTP}`);

              // Verify OTP
              if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {

                this.sendBulkInvoices()

              } else {
                // Handle invalid OTP case
                this.message = "Invalid verification code";
              }
            }
          },
          sendBulkMails(){
            this.loading = true
            this.message = null
            const data = new FormData();
            data.append("function", "sendBulkMails");
            data.append("entries", JSON.stringify(this.getFilteredEntries()));
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            data.append("sessionPhoneNumber",  sessionStorage.getItem("sessionPhoneNumber"));

            execute(data,biller).then((res) =>{
              if (res.data.success) {
                this.invoicesSentSuccess = true
                this.loading = true

              }else{
                this.message =res.data.message
              }
            }).catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
          },
        }
    }
</script>

<style scoped>

</style>
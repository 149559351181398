
  /* eslint-disable */
  import { createApp } from 'vue';
  import App from './App.vue';
  import router from '@/router';
  import vueHelmet from 'vue-helmet';
  import * as VueGoogleMaps from 'vue3-google-map';


  (function() {
    function handlePasswordResetRedirect() {
      console.log("Password reset redirect handler running");

      // Check if we're on a Google redirect URL
      if (window.location.href.includes("google.com/url")) {
        const params = new URLSearchParams(window.location.search);
        const encodedUrl = params.get("q");

        if (encodedUrl) {
          processRedirectUrl(encodedUrl);
        }
      }
      // Check if we're already on a password reset URL that needs fixing
      else if (window.location.hash.includes("#/change-password-verification/")) {
        const currentHash = window.location.hash;

        // Check if there's encoding that needs fixing
        if (currentHash.includes("%23")) {
          cleanupPasswordResetUrl();
        }
      }
    }

    function processRedirectUrl(encodedUrl) {
      console.log("Processing encoded URL:", encodedUrl);

      try {
        // First decode (handles %23 -> #)
        const decodedUrl = decodeURIComponent(encodedUrl);
        console.log("Decoded URL:", decodedUrl);

        // Check if this is a password reset URL
        if (decodedUrl.includes("#/change-password-verification/")) {
          // For Gmail URLs, we might need another decode
          let finalDecodedUrl = decodedUrl;
          if (encodedUrl.includes("%2523")) {
            finalDecodedUrl = decodeURIComponent(decodedUrl);
            console.log("Double decoded URL:", finalDecodedUrl);
          }

          // Extract the base URL (everything before the first hash)
          const baseUrlEndIndex = finalDecodedUrl.indexOf("#/");
          const baseUrl = finalDecodedUrl.substring(0, baseUrlEndIndex);

          // Get the parts after the base URL - preserve exactly as is
          const routePart = finalDecodedUrl.substring(baseUrlEndIndex);

          // Build the final URL - keeping the token format as is
          const finalUrl = `${baseUrl}/${routePart}`;
          console.log("Redirecting to:", finalUrl);

          // Perform the redirect
          window.location.replace(finalUrl);
        }
      } catch (error) {
        console.error("Error processing URL:", error);
      }
    }

    function cleanupPasswordResetUrl() {
      console.log("Cleaning up existing password reset URL");

      const currentHash = window.location.hash;
      const baseUrl = window.location.origin + window.location.pathname;

      // Just decode any encoded hash symbols
      const cleanedHash = currentHash.replace(/%23/g, "#");

      // Build the final URL - preserving the exact format otherwise
      const finalUrl = `${baseUrl}${cleanedHash}`;
      console.log("Redirecting to cleaned URL:", finalUrl);

      // Perform the redirect
      window.location.replace(finalUrl);
    }

    // Run the handler when the document is loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handlePasswordResetRedirect);
    } else {
      // Document already loaded, run immediately
      handlePasswordResetRedirect();
    }
  })();

  const app = createApp(App);

  // Use environment variable for Google Maps API key
  app.use(VueGoogleMaps, {
    load: { key: process.env.VUE_GOOGLE_MAPS_API_KEY },
  });

  app.use(router);
  app.use(vueHelmet);
  app.mount('#app');





<template>

  <head>

    <meta charset="utf-8" />
    <title>Commercials | Partially paid invoices</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent data-sidebar="dark">


  <!-- Begin page -->
  <div id="layout-wrapper">

    <NavigationBar/>
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div id="content" class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Transaction Correction</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Action Center</a></li>
                    <li class="breadcrumb-item active">Transaction Correction</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                  <div class="flex-grow-1">
                    <div class="search-box mb-0 me-3">
                      <div class="position-relative">
                        <input v-model="keyword" type="text" class="form-control bg-light border-light rounded" placeholder="Search For Wrong payment" fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>

                  <div class="d-none">
                    <div class="d-flex align-items-center me-3 pe-3">
                      <span class="px-3 fw-semibold">Transaction Type:</span>
                      <select class="form-select">
                        <option>Matatut</option>
                        <option>PSV Saccos</option>
                        <option>Motorbike Sub Group</option>
                        <option>Private Organizations/Companies</option>
                      </select>


                    </div>
                    <button data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize d-none">
                      <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                    </button>

                  </div>


                </div>

                <div class="card-body">
                  <div class="table-responsive">

                    <table class="table align-middle table-hover  contacts-table table-striped table-nowrap " id="datatable-buttons1">
                      <thead class="table-light">
                      <tr class="table-dark">
                        <th>Receipt No.</th>
                        <th>Bill No.</th>
                        <th>Receipted By</th>
                        <th scope="col">Date Receipted</th>
                        <th scope="col">Method</th>

                        <th class="text-right">Amount Receipted</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in Transactions" :key="index">
                        <td class="col-spa"  >{{item.transaction_code}}</td>
                        <td class="col-spa"  >{{item.account_ref}}</td>
                        <td>{{item.names}}</td>
                        <td class="text-capitalize text-nowrap">{{item.date}}</td>
                        <td>
                          <span class="badge badge-soft-success">{{item.payment_type}}</span> {{item.account_from}}
                        </td>
                        <td class="text-nowrap text-right ">
                          <span class="fw-semibold ">KES {{item.amount}}</span>
                        </td>


                        <td class="text-right">
                          <div class="d-flex">
                            <button @click="selectedTransaction(item.transaction_code)" type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">Submit Correction</button>

                          </div>
                        </td>
                      </tr>

                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade receipting-modal pb-5" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered mb-5">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Transaction Correction</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12">
                  <div class="">
                    <div class="alert alert-warning" role="alert">
                      <h4 class="alert-heading">Please Note !</h4>
                      <p>The bill number must exist and receipted amount should equal the invoiced amount</p>
                      <hr>

                      <div class="">
                        <label for="formrow-firstname-input" class="form-label">Bill Number</label>
                        <input v-model="search" class="form-control me-auto" type="text" placeholder="Bill Number" aria-label="Add your item here..." spellcheck="false" data-ms-editor="true">
                        <p class="text-info"> {{message}}</p>
                        <span>Bill Amount : KES  <span class="fw-bold text-black">{{detailAmount}}</span> </span><br>
                        <span>Paid Amount : KES  <span class="fw-bold text-black">{{receiptAmount}}</span> </span><br>
                        <span>Balance Amount : KES  <span class="fw-bold text-black">{{billBalance}}</span> </span><br>

                        {{customerPhoneNumber}} {{feeDescription}}

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button v-if="disabledStatus" class="btn btn-primary w-100" disabled>Done</button>
              <button data-bs-dismiss="modal"  @click="submitPaymentCorrection"  v-if="!disabledStatus" class="btn btn-primary w-100">Done</button>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- invoice details -->
      <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Receipt Invoice</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12">
                <address>
                  <strong>Billed To:</strong><br>
                  Kelvin Njuguna<br>
                  email@mail.com, 0704 549 859<br>
                  Hse No. 410, 90 Degrees By Tsavo
                  <br><br>
                  1 Mar 2022, 10:20 AM
                </address>
              </div>
              <div class="col-12">
                <div class="py-2 mt-3">
                  <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                </div>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                    <tr>
                      <th style="width: 70px;">No.</th>
                      <th>Item</th>
                      <th class="text-end">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rent Deposit</td>
                      <td class="text-end">KES 24,500</td>
                    </tr>

                    <tr>
                      <td>02</td>
                      <td>Monthly Rent</td>
                      <td class="text-end">KES 12,000</td>
                    </tr>

                    <tr>
                      <td>03</td>
                      <td>TAX (18%)</td>
                      <td class="text-end">KES 250</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Total</td>
                      <td class="text-end fw-bold">KES 36,750</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Paid</td>
                      <td class="text-end  fw-bold">KES 0.00</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-0 text-end">
                        <strong>Balance</strong></td>
                      <td class="border-0 text-end">
                        <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 d-none">
                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                  <table class="w-100">
                    <tbody>
                    <tr data-id="1">
                      <td>
                        <label for="" class="">Payment Method</label>
                        <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                          <option value="Mpesa">MPESA</option>
                          <option value="Cash">CASH</option>
                        </select>
                      </td>
                      <td class="px-3 ">
                        <div class="phone-num d-none">
                          <label for="">Phone No.</label>
                          <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                        </div>

                      </td>
                      <td class="px-3">
                        <label for="">Amount To Be Paid</label>
                        <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                      </td>
                      <td class="text-right float-right">
                        <div class="d-flex flex-column">
                          <label class="opacity-0">Something</label>
                          <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                        </div>
                      </td>


                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="float-end">
                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
              </div>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                The App
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay"></div>

  </body>

</template>

<script>

import {biller, execute} from "@/api";
import NavigationBar from "@/components/Navigation.vue";
export default {
  name: "LoginPage",
  components: {NavigationBar},
  data() {
    return{
      disabledStatus:true,
      message:'',
      keyword:'',
      search:'',
      transaction_code:'',
      Transactions: [
        {
          amount:  null,
          payment_type:  null,
          account_from: null,
          transaction_code:  null,
          account_ref:  null,
          date:  null,
          names:  null,
          verified:  null,
          verifiedBy: null,
          code:  null,
        }],
      billDetails:{
        id: "",
        billNo: "",
        incomeTypeID: "",
        incomeTypeDescription: "",
        costCenterNo: "",
        accountNo: ",",
        description: "",
        feeID: "",
        detailAmount: "",
        receiptAmount: "",
        billBalance: "",
        wardID: "",
        subCountyID: "",
        status: "",
        dateCreated: ""
      },
      billInfo:[],
      detailAmount:'--',
      receiptAmount:'--',
      billBalance:'--',
      customerPhoneNumber:null,
      feeDescription:null,
    }
  },
  mounted() {
    this.$watch('keyword', function () {
      // eslint-disable-next-line no-empty
      if (this.keyword === '') {

      } else {
        this.getTransactions()
      }
    })

    this.$watch('search', function () {
      // eslint-disable-next-line no-empty
      if (this.search === '') {

      } else {
        this.getBill()
      }
    })
    this.Transactions.splice(0)
    this.getTransactions()
    this.menuAccess()
  },
  methods: {

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Fagged Transactions");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then(() => {
            // alert(res.data.message);
          })
          .catch(() => {
            // alert(e.message);
          });

    },

    selectedTransaction(transaction_code){
      this.transaction_code = transaction_code
    },
    getBill(){
      this.disabledStatus = true
      this.message ='Searching..'
      this.detailAmount ='--'
      this.receiptAmount ='--'
      this.billBalance ='--'
      this.customerPhoneNumber =null
      this.feeDescription =null

      const data = new FormData();
      data.append("function", "getBill");
      data.append("billNo", this.search);
      execute(data,biller)
          .then((res) => {
            this.message =''
            if (res.data.success) {
              this.billDetails = res.data.data.billDetails
              this.detailAmount = this.billDetails.detailAmount
              this.receiptAmount =this.billDetails.receiptAmount
              this.billBalance =this.billDetails.billBalance
              this.customerPhoneNumber = this.billDetails.customerPhoneNumber
              this.feeDescription = this.billDetails.feeDescription
              this.disabledStatus = false
            }
            else{
              this.disabledStatus = true
              this.message =''

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getTransactions(){
      const data = new FormData();
      data.append("function", "getTransactions");
      data.append("keyword", this.keyword);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.Transactions.splice(0)

              this.Transactions = res.data.data.Transactions

            }
            else{

              alert(res.data.data.message)

            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    submitPaymentCorrection(){
      const data = new FormData();
      data.append("function", "requestPaymentCorrection");
      data.append("billNo", this.billDetails.billNo);
      data.append("transaction_code", this.transaction_code);
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo", sessionStorage.getItem("sessionIdNo"));
      data.append("sessionPhoneNumber", sessionStorage.getItem("sessionPhoneNumber"));
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.message = res.data.message
              alert(res.data.message)
              this.Transactions.splice(0)
            }
            else{
              alert(res.data.message)
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
  }
}

</script>


<style scoped>

</style>
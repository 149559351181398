<template>

<head>

    <meta charset="utf-8" />
    <title>NTSA | Users </title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body @contextmenu.prevent data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">User Accounts category</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item active">User Accounts</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- eTransactions table -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="mb-0">All User Accounts</h4>
                                        <div class="d-flex">
                                            <a v-if="getRight('CREATE USER CATEGORY')" @click="addNewCategory" href="#" type="button" class="btn btn-primary dropdown-toggle option-selector" data-toggle="modal" data-target=".create-account" aria-controls="offcanvasRight">
                                                <i class="mdi mdi-account-multiple-plus-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Add Account Type</span>
                                            </a>
                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">

                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="bx  bx-trash label-icon"></i> Delete Contact(s)</button>
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-plus label-icon"></i> Compose Message</button>
                                    </div>
                                </div>
                                <div class="card-header bg-white w-100 border-bottom d-flex">
                                    <div class="mr-15px">
                                        <select class="form-select form-control bg-white mr-15px" title="Select System User Type" data-live-search="true" data-style="">
                                            <option selected value="All users (100)">All users (100)</option>
                                            <option value="Auditors [0]">Auditors [0]</option>
                                            <option value="Accountants [2]">Accountants [2]</option>
                                            <option value="ICT OFFICCERS">ICT OFFICERS</option>
                                            <option value="ENFORCEMENT [0]">ENFORCEMENT [0]</option>
                                      </select>
                                    </div>

                                  <div class="flex-grow-1">
                                    <div class="search-box mb-0 me-2 flex-grow-1">
                                        <div class="position-relative">
                                            <input type="text" class="form-control bg-light border-light rounded" placeholder="Search User">
                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                   </div>


                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-nowrap table-hover contacts-table table-striped" id="">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Account Category</th>
                                                    <th scope="col">Users</th>

                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               <tr :key="index" :value="item" v-for="(item, index) in categories">
                                                    <th>{{index+1}}</th>
                                                    <td>{{item.category}}</td>
                                                    <th>{{item.count}}</th>
                                                    <td class="text-right">
                                                        <button v-if="getRight('DELETE USER CATEGORY')" @click="deleteCategories(item)" class="btn btn-sm btn-danger text-uppercase mx-4">Delete</button>
                                                        <button v-if="getRight('EDIT USER CATEGORY')" @click="selectedCategory(item)" class="btn btn-sm btn-primary text-uppercase mx-4"  data-toggle="modal" data-target=".create-account">Edit</button>
                                                        <button @click="selectedCategory(item)" class="btn btn-sm btn-success text-uppercase" data-toggle="modal" data-target=".create-account">View Privileges</button>
                                                    </td>
                                               </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr class="table-dark">
                                                    <th colspan="2">{{categories.length}} Accounts</th>
                                                    <th colspan="2">{{totalUsers}} Users</th>

                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

             <!-- Account creation privilages -->
             <div class="modal fade create-account " tabindex="-1 " role="dialog " aria-labelledby="transaction-detailModalLabel " aria-hidden="true ">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document ">
                    <div class="modal-content ">
                        <div class="modal-header ">
                            <h5  class="modal-title " id="transaction-detailModalLabel">Account Category</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>

                        </div>
                        <div class="modal-body ">
                           <div class="container-fluid">
                                <div class="row">
                                    <div class="col-8">
                                        <label for="">Account Category Name</label>
                                        <input v-model="category.category" type="text" class="form-control" placeholder="Enter Category Name" name="" id="">
                                    </div>

                                    <div class="col-12 mb-4">
                                        <label for="">Accessible platforms/Applications</label>
                                        <select id="accessible-platforms" name="fruits" multiple class="form-select">
                                            <option value="County Biller Dashboard">County Biller Dashboard</option>
                                            <option value="Collections & Enforcement App">Collections & Enforcement App</option>
                                            <option value="Supervision Mobile App">Supervision Mobile App</option>

                                        </select>
                                        <small>For multiple selections holt <strong>CTRL</strong> while selecting.</small>
                                    </div>

                                <!--   <p>Selected Roles: {{ selectedRoles }}</p>-->

                                    <div class="col-12">
                                        <h4 class="mb-3">Dashboard Rights & Privileges</h4>
                                    </div>


                                    <div class="col-6 mb-4 dashboard-rights">
                                        <label for="" class="font-size-14 mb-3">User management and Set ups</label>

                                        <!-- Use v-for to loop through filteredDashboardRoles -->
                                        <div v-for="(role, index) in filteredDashboardRoles" :key="index" class="form-check mb-3">
                                            <!-- Use role.id or another unique property as the input id -->
                                            <input
                                                    class="form-check-input"
                                                    disabled
                                                    type="checkbox"
                                                    :id="'formCheck' + role.id"
                                                    :checked="isSelected(role)"
                                                    @change="toggleRoleSelection(role)"
                                            />
                                            <!-- Use role.role for the checkbox label -->
                                            <label class="form-check-label" :for="'formCheck' + role.id">{{ role.role }}</label>
                                        </div>

                                    </div>


                                    <div class="col-6 mb-4 dashboard-modules">

                                        <label for="" class="font-size-14 mb-3">Accessible Modules</label>
                                        <div class="form-check mb-3">
                                            <input  class="form-check-input" type="checkbox" disabled id="formCheck4">
                                            <label class="form-check-label" for="formCheck4">
                                                Dashboard
                                            </label>
                                        </div>

                                        <!-- Use v-for to loop through filteredModulesRoles -->
                                        <div v-for="(role, index) in filteredModulesRoles" :key="index" class="form-check mb-3">
                                            <!-- Use role.id or another unique property as the input id -->
                                            <input
                                                    class="form-check-input"
                                                    disabled
                                                    type="checkbox"
                                                    :id="'formCheck' + role.id"
                                                    :checked="isSelected(role)"
                                                    @change="toggleRoleSelection(role)"
                                            />
                                            <!-- Use role.role for the checkbox label -->
                                            <label class="form-check-label" :for="'formCheck' + role.id">{{ role.role }}</label>
                                        </div>

                                    </div>

                                    <div class="col-6 mb-4 dashboard-modules">

                                        <label for="" class="font-size-14 mb-3">Approvals</label>

                                        <!-- Use v-for to loop through filteredSupervisoryRoles -->
                                        <div v-for="(role, index) in filteredActions" :key="index" class="form-check mb-3">
                                            <!-- Use role.id or another unique property as the input id -->
                                            <input
                                                    class="form-check-input"
                                                    disabled
                                                    type="checkbox"
                                                    :id="'formCheck' + role.id"
                                                    :checked="isSelected(role)"
                                                    @change="toggleRoleSelection(role)"
                                            />
                                            <!-- Use role.role for the checkbox label -->
                                            <label class="form-check-label" :for="'formCheck' + role.id">{{ role.role }}</label>
                                        </div>


                                    </div>

                                    <div class="col-12">
                                        <h4 class="mb-3">Mobile App Rights & Privileges</h4>
                                    </div>

                                    <div class="col-6 mb-4 enforcement-modules">

                                        <label for="" class="font-size-14 mb-3">Collections & Enforcement Platform Accessible Modules</label>


                                        <!-- Use v-for to loop through filteredMOBILERoles -->
                                        <div v-for="(role, index) in filteredMobileRoles" :key="index" class="form-check mb-3">
                                            <!-- Use role.id or another unique property as the input id -->
                                            <input
                                                    class="form-check-input"
                                                    disabled
                                                    type="checkbox"
                                                    :id="'formCheck' + role.id"
                                                    :checked="isSelected(role)"
                                                    @change="toggleRoleSelection(role)"
                                            />
                                            <!-- Use role.role for the checkbox label -->
                                            <label class="form-check-label" :for="'formCheck' + role.id">{{ role.role }}</label>
                                        </div>

                                    </div>

                                    <div class="col-6 mb-4 supervisor-modules">

                                        <label for="" class="font-size-14 mb-3">Rights and privileges for the supervision platform</label>

                                        <!-- Use v-for to loop through filteredSupervisoryRoles -->
                                        <div v-for="(role, index) in filteredSupervisoryRoles" :key="index" class="form-check mb-3">
                                            <!-- Use role.id or another unique property as the input id -->
                                            <input
                                                    class="form-check-input"
                                                    disabled
                                                    type="checkbox"
                                                    :id="'formCheck' + role.id"
                                                    :checked="isSelected(role)"
                                                    @change="toggleRoleSelection(role)"
                                            />
                                            <!-- Use role.role for the checkbox label -->
                                            <label class="form-check-label" :for="'formCheck' + role.id">{{ role.role }}</label>
                                        </div>


                                    </div>
                                </div>
                           </div>
                        </div>




                        <div class="modal-footer ">
                            <button v-if="getRight('UPDATE USER CATEGORY')" @click="updateCategories()" class="btn btn-success w-100 d-flex text-center align-items-center justify-content-center text-uppercase"  type="button" data-dismiss="modal" aria-label="Close">SAVE</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of account privilages -->




            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->


    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>
    </body>


</template>

<script>
    /* eslint-disable */
    import {authUrl, biller, execute, getRight} from "@/api";
    import router from "@/router";
    import NavigationBar from "@/components/Navigation";
        export default {
            name: "SetAccounts",
            components: {NavigationBar},
            data() {
                return{
                  totalUsers:0,
                    selectedRoles: [], // Added property to track selected roles
                    category: {
                        category: null,
                        count: null,
                        selectedRoles: null
                    },
                    categories: [
                        {
                            category: null,
                            count: null,
                            selectedRoles: null
                        }],
                    rights: [
                        {
                            type: {
                                type: "ACTIONS"
                            },
                            roles: [
                                {
                                    id: "60",
                                    type: "ACTIONS",
                                    role: "APPROVE SBP VALIDATION"
                                },
                                {
                                    id: "61",
                                    type: "ACTIONS",
                                    role: "APPROVE SBP INSPECTION"
                                },
                                {
                                    id: "62",
                                    type: "ACTIONS",
                                    role: "APPROVE SPB"
                                }
                            ]
                        }],
                    message:null,
                }
            },
            computed: {
                filteredDashboardRoles() {
                    // Use flatMap to get an array of roles with type "DASHBOARD"
                    return this.rights.flatMap((right) =>
                        right.type.type === "DASHBOARD" ? right.roles : []
                    );
                },
                filteredModulesRoles() {
                    // Use flatMap to get an array of roles with type "DASHBOARD"
                    return this.rights.flatMap((right) =>
                        right.type.type === "MODULES" ? right.roles : []
                    );
                },
                filteredMobileRoles() {
                    // Use flatMap to get an array of roles with type "DASHBOARD"
                    return this.rights.flatMap((right) =>
                        right.type.type === "MOBILE" ? right.roles : []
                    );
                },
                filteredSupervisoryRoles() {
                    // Use flatMap to get an array of roles with type "DASHBOARD"
                    return this.rights.flatMap((right) =>
                        right.type.type === "SUPERVISORY PLATFORM" ? right.roles : []
                    );
                },
                filteredActions() {
                    // Use flatMap to get an array of roles with type "DASHBOARD"
                    return this.rights.flatMap((right) =>
                        right.type.type === "ACTIONS" ? right.roles : []
                    );
                },
            },
            mounted() {
                this.getRoles()
                this.getCategories()
               this.menuAccess()

            },
            methods: {

                menuAccess(){

                const data = new FormData();
                data.append("function", "menuAccess");
                data.append("menu", "Set Accounts");
                data.append("sessionNames", sessionStorage.getItem("sessionNames"));
                data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
                execute(data,biller)
                    .then(() => {
                      // alert(res.data.message);
                    })
                    .catch(() => {
                      // alert(e.message);
                    });

              },
                getRight(type){
                    return getRight(type)
                },
                selectedCategory(category) {
                    this.selectedRoles.splice(0)
                    console.log('Selected Category:', category.selectedRoles);
                    this.category = category;
                    this.selectedRoles = Array.isArray(category.selectedRoles) ? category.selectedRoles : JSON.parse(category.selectedRoles || '[]');
                    console.log('Updated Selected Roles:', this.selectedRoles);
                },
                toggleRoleSelection(role) {
                    const index = this.selectedRoles.findIndex(selectedRole => selectedRole.id === role.id);

                    if (index === -1) {
                        // Role is not selected, add it
                        this.selectedRoles.push(role);
                    } else {
                        // Role is already selected, remove it
                        this.selectedRoles.splice(index, 1);
                    }
                },
                isSelected(role) {
                    return this.selectedRoles.some(selectedRole => selectedRole.id === role.id);
                },
                addNewCategory() {
                    this.category = {
                        selectedRoles: null,
                        category: null,
                        count: null
                    };
                    this.selectedRoles.splice(0);
                },
                getRoles(){
                    this.categories.splice(0)
                    const data = new FormData();
                    data.append("function", "getRoles");

                    execute(data,authUrl)
                        .then((res) =>{
                            if (res.data.success) {
                                this.rights = res.data.data.rights
                            }else{

                                this.message =res.data.message
                            }
                        })
                        .catch((e) => {
                            //alert(e.message);
                            this.message =e.message
                        });
                },
                getCategories(){
                    this.categories.splice(0)
                    const data = new FormData();
                    data.append("function", "getCategories");

                    execute(data,authUrl)
                        .then((res) =>{
                            if (res.data.success) {

                                this.categories = res.data.data.categories
                                 this.totalUsers = this.categories.reduce((sum, category) => sum + parseInt(category.count), 0);

                            }else{

                                this.message =res.data.message
                            }
                        })
                        .catch((e) => {
                            //alert(e.message);
                            this.message =e.message
                        });
                },
                updateCategories(){
                    const data = new FormData();
                    data.append("function", "updateCategories");
                    data.append("category", JSON.stringify(this.category));
                    data.append("selectedRoles", JSON.stringify(this.selectedRoles));
                    data.append("sessionNames", sessionStorage.getItem("sessionNames"));
                    data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));

                    execute(data,authUrl)
                        .then((res) =>{
                            if (res.data.success) {
                              this.getCategories()
                            }else{

                                this.message =res.data.message
                            }
                        })
                        .catch((e) => {
                            //alert(e.message);
                            this.message =e.message
                        });
                },
                deleteCategories(category) {
                    // Display a confirmation alert
                    const userConfirmed = window.confirm("Are you sure you want to delete this category?");

                    if (!userConfirmed) {
                        return; // If the user cancels, do nothing
                    }

                    // Clear selectedRoles and log category details
                    this.selectedRoles.splice(0);
                    console.log('Selected Category:', category.selectedRoles);

                    // Set category and selectedRoles
                    this.category = category;
                    this.selectedRoles = Array.isArray(category.selectedRoles)
                        ? category.selectedRoles
                        : JSON.parse(category.selectedRoles || '[]');
                    console.log('Updated Selected Roles:', this.selectedRoles);

                    // Prepare data for deletion
                    const data = new FormData();
                    data.append("function", "deleteCategories");
                    data.append("category", JSON.stringify(this.category));
                    data.append("selectedRoles", JSON.stringify(this.selectedRoles));

                    // Execute the deletion
                    execute(data, authUrl)
                        .then((res) => {
                            if (res.data.success) {
                                // If deletion is successful, refresh the category list
                                this.getCategories();
                            } else {
                                // If there's an error, set the error message
                                this.message = res.data.message;
                            }
                        })
                        .catch((e) => {
                            // Handle any errors
                            this.message = e.message;
                        });
                }

            }
        }
</script>

<style scoped>

</style>
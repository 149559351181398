<template>

  <head>

    <meta charset="utf-8" />
    <title>Login | Skote - Admin & Dashboard Template</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Premium Multipurpose Admin & Dashboard Template" name="description" />
    <meta content="Themesbrand" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.ico">

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent>
  <div class="account-pages   pt-sm-5 single-login-page d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class=" bg-backgroung-pri">
              <div class="row">
                <div class="col-8 z-index-3">
                  <div class="text-primary p-4">
                    <h5 class="text-primary fw-bold text-uppercase text-white">Create password</h5>
                    <p class="text-white">Enter and confirm your new password</p>
                  </div>
                </div>
                <div class="col-5 align-self-end d-none">
                  <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="auth-logo">
                <a href="index.html" class="auth-logo-light">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo-light.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>

                <a href="index.html" class="auth-logo-dark">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>
              </div>
              <div class="p-2">


                <div>
                  <div class="mb-3">
                    <label for="password" class="form-label">New Password</label>
                    <input
                        v-model="password"
                        @input="validatePassword"
                        type="password"
                        class="form-control"
                        placeholder="Enter your new password"
                    />
                    <small v-if="passwordError" class="text-danger">{{ passwordError }}</small>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Confirm Password</label>
                    <div class="input-group auth-pass-inputgroup">
                      <input
                          v-model="confirmPassword"
                          @input="validateConfirmPassword"
                          type="password"
                          class="form-control"
                          placeholder="Re-enter your password"
                      />
                      <button class="btn btn-light" type="button" id="password-addon">
                        <i class="mdi mdi-eye-outline"></i>
                      </button>
                    </div>
                    <small v-if="confirmPasswordError" class="text-danger">
                      {{ confirmPasswordError }}
                    </small>
                  </div>
                </div>

                <div v-if="alert.success" class="alert alert-success" role="alert">
                  {{message}}
                </div>

                <div v-if="alert.info" class="alert alert-primary" role="alert">
                  {{message}}
                </div>

                <div v-if="alert.failed" class="alert alert-danger" role="alert">
                  {{message}}
                </div>



                <div class="mt-3 d-grid">
                    <button @click="changePassword" class="btn btn-primary waves-effect waves-light" type="submit">Finish</button>
                  </div>

                  <div class="mt-4 text-center d-none">
                    <h5 class="font-size-14 mb-3">Sign in with</h5>

                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

<!--                  <div class="mt-4 text-center">
                    <a href="auth-recoverpw.html" class="text-muted "><i class="mdi mdi-lock me-1"></i> Forgot your password?</a>
                  </div>-->

              </div>

            </div>
          </div>
          <div class="mt-5 text-center">

            <div>

              <p class="text-white">© {{fullYear}} County Billing System <i class="mdi mdi-heart text-danger"></i>  </p>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end account-pages -->

  </body>


</template>


<script>

/* eslint-disable */

import {authUrl, execute, getRight} from "@/api";
import router from "@/router";

export default {
  name: "LoginPage",
  data() {
    return{

      deviceDetials:'',
      os: '',
      browser: '',
      screenResolution: '',
      cpuCores: '',
      memory: '',

      fullYear:'',
      rememberMe: false, // Initial value for the checkbox state
      message:'',
      selectedRoles: [
        {
          id:null,
          type:null,
          role:null
        }
      ],

      alert:{
        success: false,
        info: false,
        failed:false
      },
      contact: {
        email: ''
      },
      password:'',
      confirmPassword:'',
      passwordError: "",
      confirmPasswordError: "",
    }
  },
  mounted() {

    // Get the hash from the URL
    const hash = window.location.hash;
    const data = hash.split('/')[3];
    // If number is passed in the URL
    if (data) {
      let token = this.decodeToken(data);
      console.log('Number from URL:', token);
      localStorage.setItem("tokenSession", token);

// Retrieve immediately to verify
      console.log("Stored Token:", localStorage.getItem("tokenSession"));

    }
    else {
      // Number is not passed in the URL, check localStorage
      try {

        const currentDate = new Date();
        this.fullYear= currentDate.getFullYear();

        this.rememberMe = sessionStorage.getItem("rememberMe")
        if(this.rememberMe){
          this.contact.email = sessionStorage.getItem("email")
        }

      } catch (error) {
        console.error('Error parsing localStorage:', error);
      }

    }

    this.getDeviceDetails()

  },


  methods: {

    decodeToken(token) {
      try {
        let jsonString = atob(token);
        let data = JSON.parse(jsonString);
        return data.id; // Returns "7006"
      } catch (e) {
        console.error("Invalid token:", e);
        return null;
      }
    },

    getDeviceDetails() {
      // Operating System
      if (navigator.userAgent.indexOf('Win') !== -1) this.os = 'Windows';
      else if (navigator.userAgent.indexOf('Mac') !== -1) this.os = 'MacOS';
      else if (navigator.userAgent.indexOf('Linux') !== -1) this.os = 'Linux';
      else this.os = 'Unknown';

      // Browser
      if (navigator.userAgent.indexOf('Chrome') !== -1) this.browser = 'Chrome';
      else if (navigator.userAgent.indexOf('Firefox') !== -1) this.browser = 'Firefox';
      else if (navigator.userAgent.indexOf('Safari') !== -1) this.browser = 'Safari';
      else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode) this.browser = 'Internet Explorer';
      else this.browser = 'Unknown';

      // Screen Resolution
      this.screenResolution = `${window.screen.width} x ${window.screen.height}`;

      // CPU Cores
      this.cpuCores = navigator.hardwareConcurrency || 'Unknown';

      // Memory
      this.memory = (navigator.deviceMemory || 'Unknown');

      this.deviceDetials = `os: ${this.os} | browser: ${this.browser} | screenResolution: ${this.screenResolution} | cpuCores: ${this.cpuCores} | memory: ${this.memory}`

    },

    changePassword() {



      // Check if password and confirmPassword match
      if (this.password !== this.confirmPassword) {
        this.alert.failed = true;
        this.message = "Passwords do not match.";
        return; // Exit the function if they don't match
      }

      if(this.validatePasswordCheck(this.password)){

        this.message = "Changing...";
        this.alert.failed = false;
        this.alert.info = true;

        const data = new FormData();
        data.append("function", "changePassword");
        data.append("password", btoa(this.password)); // Use password instead of confirmPassword
        data.append("deviceDetails", this.deviceDetials);

        execute(data, authUrl)
            .then((res) => {
              this.message = null;
              this.alert.info = false;
              if (res.data.success) {
                router.push('/');
              } else {
                this.alert.failed = true;
                this.message = res.data.message;
              }
            })
            .catch((e) => {
              this.message = e.message;
            });

      }else {

      }


    },

     validatePasswordCheck(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
  );
},


validatePassword() {
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(this.password);
      const hasLowerCase = /[a-z]/.test(this.password);
      const hasNumber = /\d/.test(this.password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);

      if (this.password.length < minLength) {
        this.passwordError = `Password must be at least ${minLength} characters.`;
        return false
      } else if (!hasUpperCase) {
        this.passwordError = "Password must include at least one uppercase letter.";
        return false
      } else if (!hasLowerCase) {
        this.passwordError = "Password must include at least one lowercase letter.";
        return false
      } else if (!hasNumber) {
        this.passwordError = "Password must include at least one number.";
        return false
      } else if (!hasSpecialChar) {
        this.passwordError = "Password must include at least one special character.";
        return false
      } else {
        this.passwordError = "";
        return true
      }
    },
    validateConfirmPassword() {
      if (this.confirmPassword !== this.password) {
        this.confirmPasswordError = "Passwords do not match.";
      } else {
        this.confirmPasswordError = "";
      }
    }

  }
}

</script>


<template>

    <head>

        <meta charset="utf-8"/>
        <title>Desk Birdy | Business Permits</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport">
        <meta content="Rental management system by Nouveta LTD" name="description"/>
        <meta content="Kelvin Njuguna" name="author"/>
        <!-- App favicon -->
        <link href="assets/images/favicon.svg" rel="shortcut icon">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css"/>

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css"/>

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
        <!-- Bootstrap select styling -->
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css"
              rel="stylesheet">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
        <!-- App Css-->

        <!-- owl.carousel css -->
        <link href="./assets/libs/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">

        <link href="./assets/libs/owl.carousel/assets/owl.theme.default.min.css" rel="stylesheet">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

    </head>

    <body @contextmenu.prevent data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
         <div id="content" class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Business Permits</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Certificates & Licenses</a></li>
                                        <li class="breadcrumb-item"><a href="cert-biz-stats.html">Business Permits</a>
                                        </li>
                                        <li class="breadcrumb-item active"> Applications Pending Validation</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                    <ToolBar/>

                    <!-- end of toolbar -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                        <div class="d-flex align-items-center justify-content-center p-3"  role="toolbar">
                                            <label class="text-nowrap mb-0 px-2">Date From:</label>
                                            <input v-model="dateFrom" class="form-control pr-3" type="date"  id="example-datetime-local-input">

                                            <label class="text-nowrap mb-0 px-2">Date To:</label>
                                            <input v-model="dateTo" class="form-control pr-2" type="date"  id="example-datetime-local-input">

                                            <label class="text-nowrap mb-0 px-2">Business Activity:</label>
                                            <select @change="getTradeSubCategory($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Business Activity">
                                                <option value=" ">All</option>
                                                <option :key="index" :value="item.incomeTypeId"
                                                        v-for="(item, index) in tradeCategories">
                                                    {{ item.incomeTypeDescription }}
                                                </option>
                                            </select>


                                            <label class="text-nowrap mb-0 mx-2">Business Sub Category:</label>
                                            <select @change="selectedTradeSubCategory($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Sub Categories">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in tradeSubCategories">
                                                    {{ item.feeDescription }}
                                                </option>
                                            </select>

                                            <select @change="selectedSubCounty($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Sub County" style="margin-left: 10px">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in subCounties">
                                                    {{ item.subCountyName }}
                                                </option>
                                            </select>

                                            <select @change="selectedWard($event)" class="form-control bg-white"
                                                    data-live-search="true" title="Select Ward" style="margin-left: 10px">
                                                <option :key="index" :value="JSON.stringify(item)"
                                                        v-for="(item, index) in wards">
                                                    {{ item.wardName }}
                                                </option>
                                            </select>

                                        </div>
                                    </div>


                                </div>
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom d-flex">
                                    <div class="d-flex align-items-center justify-content-center p-3 flex-grow-1"  role="toolbar">
                                        <form class="app-search p-0 m-0 w-100">
                                            <div class="position-relative">
                                                <input v-model="search" type="text" class="form-control w-100" placeholder="Search... eg Business Name, ID Number, Bill Number, etc" spellcheck="false" data-ms-editor="true">
                                                <span class="bx bx-search-alt"></span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="pr-3">
                                        <button  v-if="getRight('DOWNLOAD REPORTS')" @click="downloadApplicationsPaginated()" class="btn btn-primary">Download  </button>
                                    </div>
                                </div>


                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped "
                                               id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">Receipt No.</th>
                                                <th scope="col">Business ID</th>
                                                <th scope="col">Business Name</th>
                                                <th scope="col">Application Date</th>
                                                <th>Licensing Officer</th>
                                                <th>Status</th>
                                                <th class="text-right" scope="col">Amount Paid</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr :key="index" :value="item" v-for="(item, index) in businesses">
                                                <td class="text-capitalize text-nowrap">{{item.billNo}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.businessID}}</td>
                                                <td class="text-capitalize text-nowrap">
                                                    <a href="">{{item.businessName}}</a>
                                                </td>
                                                <td class="text-capitalize text-nowrap">
                                                    {{formattedDate(item.startDate)}}
                                                </td>
                                                <td>{{item.issuingOfficer}}</td>

                                                <td>
                                                        <span
                                                                class="badge badge-soft-info text-uppercase">{{item.stageStatus}}</span>
                                                </td>
                                                <td class="text-nowrap text-right ">
                                                    <span class="fw-semibold ">KES {{numberFormat(item.receiptAmount)}}</span>
                                                </td>

                                                <td>
                                                    <div class="d-flex justify-content-end">
                                                        <button @click="getBusinessDetails(item.businessID,item.billNo)"
                                                                class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3"
                                                                data-bs-target=".receipting-modal"
                                                                data-bs-toggle="modal"
                                                                type="button"><i
                                                                class="bx bx-show font-size-16 align-middle me-2"></i>
                                                            Application Details
                                                        </button>
                                                        <a @click="printBill(item.billNo)"
                                                           class="btn btn-success btn-sm waves-effect waves-light"
                                                           href=""
                                                           target="_blank">
                                                            <i
                                                                    class="bx bx-printer font-size-16 align-middle me-2"></i>
                                                            Print Receipt
                                                        </a>
                                                        <div class="dropdown">
                                                            <a aria-haspopup="true" class="text-muted font-size-16"
                                                               data-bs-toggle="dropdown" role="button">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end ">
                                                                <a @click="getBusinessDetails(item.businessID,item.billNo)"
                                                                   class="dropdown-item "
                                                                   data-bs-target=".receipting-modal"
                                                                   data-bs-toggle="modal" href="javascript: void(0);"><i
                                                                        class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                <a @click="printBill(item.billNo)"
                                                                   class="dropdown-item " href="javascript: void(0);"><i
                                                                        class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>

                                                <th>
                                                    {{businesses.length}} Certs
                                                </th>


                                                <th class="text-nowrap" colspan="4"></th>

                                                <td class="text-nowrap text-right " colspan="2">
                                                    <span class="fw-semibold ">KES {{numberFormat(totalAmount)}}</span>
                                                </td>
                                                <td></td>


                                            </tr>

                                            </tfoot>
                                        </table>

                                        <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                                             v-if="loading">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait
                                                    and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>
                                        <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                                             v-if="businesses.length<1 & loading === false">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" alt="No search results"
                                                     class="img mb-4"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter
                                                criteria. Please try using different keywords or filters.</p>
                                        </div>

                                    </div>
                                </div>

                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example "
                                         class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px " v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li :class="{ disabled: currentPage === 1 }" class="page-item">
                                                <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                                                   tabindex="-1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                                                v-for="page in pagination(currentPage, totalPages, 3)">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span class="page-link" v-if="page === '...'">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                                                <a :disabled="currentPage === totalPages" @click="nextPage"
                                                   class="page-link">Next</a>
                                            </li>
                                        </ul>


                                    </nav>
                                </div>

                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade receipting-modal"
                 role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                    class="badge rounded-pill bg-warning text-uppercase font-12px text-uppercase mx-3">{{entries.stageStatus}}</span>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">


                                            <div :class="item.completed==='active' ? 'active' : ''" class="item event-list"  v-for="(item, index) in statuses" :value="item" :key="index" style="display: inline-block; width: 350px">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">{{getHumanDate(item.updatedDate)}}</div>
                                                        <h5 class="mb-4">{{item.status}}</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i v-if="item.completed==='true'" class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                        <i  v-if ="item.completed==='false' || item.completed==='active'" class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a href="javascript: void(0);">{{item.issuingOfficer}}</a></p>

                                                        <p v-if="item.statusID !== '2'" class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i>{{item.location}}</p>
                                                        <p v-if="item.completed === 'true' && item.statusID==='2'" @click="printReceipt()" class="text-muted text-capitalize mb-1"><a href="javascript: void(0);"><i class="bx bx-receipt"></i> View Receipt</a></p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <!--   <div class="item event-list active">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">-</div>
                                                    <h5 class="mb-4">Details Verification</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i
                                                            class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted text-capitalize"><a
                                                            href="user-details.html">-</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">-</div>
                                                    <h5 class="mb-4">Inspection</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i
                                                            class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted text-capitalize"><a
                                                            href="user-details.html">-</a></p>
                                                </div>
                                            </div>
                                        </div>-->




                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details </p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Name</p>
                                                        </td>
                                                        <td class="">{{businessDetails.businessName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Plot Number. </p>
                                                        </td>
                                                        <td class="">{{businessDetails.plotNumber}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                Pin
                                                                No.</p>

                                                        </td>
                                                        <td class="">{{businessDetails.kraPin}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                No.
                                                            </p>

                                                        </td>
                                                        <td class="">{{businessDetails.kraPin}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>


                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Activity</p>

                                                        </td>
                                                        <td class="">{{businessDetails.businessDes}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>


                                                    <tr v-if="businessDetails.feeID === billDetail.feeID">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Susiness Sub category</p>

                                                        </td>
                                                        <td class="">{{billDetail.feeDescription}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr class="text-danger fw-semibold"
                                                        v-if="businessDetails.feeID !== billDetail.feeID">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business
                                                                Sub category</p>
                                                        </td>

                                                        <td class="">{{billDetail.feeDescription}}</td>

                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="businessDetails.feeID !== billDetail.feeID">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="" colspan="3">Corrections Needed/Done <br> <a
                                                            href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>


                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class=""><span class="text-primary">change to:</span>{{businessDetails.businessSubCategory}}
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr class=" fw-semibold text-capitalize">
                                                        <td class="text-primary">
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Additional Amount</p>

                                                        </td>
                                                        <td class=""><span class="text-primary"></span> <span
                                                                class="text-primary">KES {{feesAndCharges.unitFeeAmount - billDetail.receiptAmount }}</span>
                                                            (BILL NO. {{billDetail.billNo}})
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-x text-danger font-28"></i>
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{businessDetails.createdBy}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Full Names</p>
                                                        </td>
                                                        <td class="">{{businessDetails.fullNames}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                ID/PPT No.</p>
                                                        </td>
                                                        <td class="">{{businessDetails.ownerID}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Email</p>

                                                        </td>
                                                        <td class="">{{businessDetails.ownerEmail}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.ownerPhone}}'">{{maskPhoneNumber(businessDetails.ownerPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.ownerPhone}}'">{{maskPhoneNumber(businessDetails.ownerPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>


                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                Address</p>

                                                        </td>
                                                        <td class="">{{businessDetails.postalAddress}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <thead class="table-light">
                                                    <th class="text-right text-uppercase" colspan="3">Verification
                                                        Status <br> <a href="user-details.html">({{entries.issuingOfficer}})</a>
                                                    </th>
                                                    </thead>
                                                    <tbody>
                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Mobile No.</p>
                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{maskPhoneNumber(businessDetails.businessPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Phone No</p>
                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{maskPhoneNumber(businessDetails.businessPhone)}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr
                                                            title="">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Email address</p>
                                                        </td>
                                                        <td class=""><a
                                                                href="mailto:'{{businessDetails.businessEmail}}'">{{businessDetails.businessEmail}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                county</p>

                                                        </td>
                                                        <td class="">{{businessDetails.subCountyName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                            </p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{businessDetails.wardName}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Physical address/Street</p>

                                                        </td>
                                                        <td class=""><a href="tel:'{{businessDetails.businessPhone}}'">{{businessDetails.physicalAddress}}</a>
                                                        </td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Building Name</p>

                                                        </td>
                                                        <td class="">{{businessDetails.buildingName}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Stall/Room No.</p>

                                                        </td>
                                                        <td class="">{{businessDetails.roomNo}}</td>
                                                        <td class="text-right">
                                                            <i class="bx bx-check text-success font-28"></i>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">

                                            <div>
                                                <iframe frameborder="0" scrolling="no"
                                                        style="width: 100%; height: 450px;"
                                                        v-bind:src="iframeSrc"></iframe>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Revenue Officer Notes
                                                    ({{entries.issuingOfficer}})</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="alert alert-warning border-1 border-warning" role="alert">
                                                ({{entries.comments}})
                                            </div>
                                        </div>
                                        <div v-if="getRight('APPROVE SBP PERMIT')" class="col-12">
                                            <div>
                                                <div class="d-flex flex-column">
                                                    <label class="mb-0">Remarks</label>
                                                    <small class="mb-3">Enter your Remarks</small>
                                                </div>
                                                <textarea v-model="comments" name="" id="" cols="30" rows="5" class="form-control mb-2" placeholder="Enter your Remarks"></textarea>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div v-if ="getRight('APPROVE SBP PERMIT')" class="modal-footer">

                            <a v-if="viewApproved" data-bs-dismiss="modal" @click="businessValidation('Approved')" class="btn btn-primary waves-effect waves-light"
                               type="button">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Generate Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade invoice-modal" role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                                            class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                        <tr>
                                            <th style="width: 70px;">No.</th>
                                            <th>Item</th>
                                            <th class="text-end">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Rent Deposit</td>
                                            <td class="text-end">KES 24,500</td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Monthly Rent</td>
                                            <td class="text-end">KES 12,000</td>
                                        </tr>

                                        <tr>
                                            <td>03</td>
                                            <td>TAX (18%)</td>
                                            <td class="text-end">KES 250</td>
                                        </tr>
                                        <tr>
                                            <td class="text-end" colspan="2">Total</td>
                                            <td class="text-end fw-bold">KES 36,750</td>
                                        </tr>
                                        <tr>
                                            <td class="text-end" colspan="2">Paid</td>
                                            <td class="text-end  fw-bold">KES 0.00</td>
                                        </tr>

                                        <tr>
                                            <td class="border-0 text-end" colspan="2">
                                                <strong>Balance</strong>
                                            </td>
                                            <td class="border-0 text-end">
                                                <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                        <tr data-id="1">
                                            <td>
                                                <label class="" for="">Payment Method</label>
                                                <select class="form-control selectpicker w-100 payment-method"
                                                        data-live-search="true" data-style="btn-secondary w-100"
                                                        title="Select payment Method">
                                                    <option value="Mpesa">MPESA</option>
                                                    <option value="Cash">CASH</option>
                                                </select>
                                            </td>
                                            <td class="px-3 ">
                                                <div class="phone-num d-none">
                                                    <label for="">Phone No.</label>
                                                    <input class="form-control w-100 d-flex " data-ms-editor="true"
                                                           placeholder="Phone No." spellcheck="false"
                                                           type="text ">
                                                </div>

                                            </td>
                                            <td class="px-3">
                                                <label for="">Amount To Be Paid</label>
                                                <input class="form-control w-100 d-flex" data-ms-editor="true"
                                                       placeholder="KES" spellcheck="false" type="text ">

                                            </td>
                                            <td class="text-right float-right">
                                                <div class="d-flex flex-column">
                                                    <label class="opacity-0">Something</label>
                                                    <a class="btn btn-primary w-md waves-effect waves-light"
                                                       href="#">Submit</a>
                                                </div>
                                            </td>


                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a class="btn btn-success waves-effect waves-light me-1"
                                   href="javascript:window.print()"><i
                                        class="mdi mdi-printer font-16px"></i></a>
                                <a class="btn btn-primary w-md waves-effect waves-light"
                                   href="javascript: void(0);">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">

                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                County Government
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


    </body>


</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/sbp/ToolBar";
    import router from "@/router";
    import {authUrl, biller, execute, executeDownload, getRight, trade} from "@/api";
    import moment from 'moment'

    export default {
        name: "CertBizOngoing",
        components: {ToolBar, NavigationBar},
        data() {
            return {
              viewApproved:false,
                search:'',
                loading: false,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                summary: {
                    count: 0,
                    total: 0
                },
                totalAmount: 0,
                selectedItem: null,
                valueItem: "",
                subCounty: "",
                itemList: [{
                    id: null,
                    value: null
                }],
                subCounties: [{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards: [{
                    wardID: "",
                    wardName: ""
                }],
                filter: '',
                dateFrom: '',
                dateTo: '',
                message: '',
                alert: {
                    success: false,
                    info: false,
                    failed: false
                },
                billDetails: [
                    {
                        receiptAmount: "0",
                    }

                ],
                billInfo: [],
                billInfoTotal: 0,
                billNo: '',
                comments:'',
                businessDetails: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: 0.0,
                    lng: 0.0
                },
                billDetail: {
                    id: null,
                    billNo: null,
                    incomeTypeID: null,
                    incomeTypeDescription: null,
                    costCenterNo: null,
                    accountNo: null,
                    description: null,
                    feeID: null,
                    feeDescription: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    status: null,
                    dateCreated: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    category: null
                },
                businesses: [
                    {
                        id: null,
                        billNo: null,
                        businessID: null,
                        businessName: null,
                        duration: null,
                        billTotal: null,
                        receiptAmount: null,
                        billBalance: null,
                        startDate: null,
                        endDate: null,
                        statusID: null,
                        status: null,
                        printable: null,
                        issuingOfficer: null,
                        designation: null,
                        dateOfIssue: null,
                        stageStatus: null
                    }],
                entries: {
                    id: null,
                    billNo: null,
                    businessID: null,
                    businessName: null,
                    duration: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    startDate: null,
                    endDate: null,
                    statusID: null,
                    status: null,
                    printable: null,
                    issuingOfficer: null,
                    designation: null,
                    dateOfIssue: null,
                    stageStatus: null
                },
                feesAndCharges: {
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc: null,
                    costCenterNo: null,
                    zone: null,
                    typeDescription: null,
                    prorated: null,
                    duration: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                },

                receiptDetails: {
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    costCenterNo: null,
                    accountNo: null,
                    incomeTypeDescription: null,
                    feeID: null,
                    feeDescription: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    currency: null,
                    source: null,
                    transactionCode: null,
                    paidBy: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    isActive: null,
                    status: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    description: null,
                    category: null
                },
                receiptInfos: [
                    {
                        id: null,
                        receiptNo: null,
                        billNo: null,
                        feeID: null,
                        feeDescription: null,
                        billTotal: null,
                        receiptAmount: null,
                        billBalance: null,
                        customer: null,
                        clientPhoneNo: null,
                        description: null,
                        receiptDate: null,
                        printCount: null,
                        wardID: null,
                        subCountyID: null,
                        subCountyName: null,
                        wardName: null,
                        dateCreated: null,
                        dateModified: null,
                        createdBy: null,
                        modifiedBy: null,
                        printedBy: null,
                        updated: null,
                        isActive: null,
                        status: null,
                        zone: null,
                        departmentID: null,
                        department: null,
                        idNo: null,
                        phoneNumber: null,
                        names: null,
                        customerPhoneNumber: null,
                        category: null
                    }],
                tradeCategories: [{
                    incomeTypeId: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                }],
                tradeSubCategories: [
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null
                    }
                ],
                statuses: [{
                    id: null,
                    businessID: null,
                    statusID: null,
                    status: null,
                    description: null,
                    dateCreated: null,
                    completed: true,
                    billNo: null,
                    comments: null,
                    issuingOfficer: null,
                    updatedDate: null,
                    location: null
                }]
            }

        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getApplicationsPaginated("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            search(){
                const keyword = this.search;
                this.getApplicationsPaginated(keyword)
            }

        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },

            totalReceiptAmount() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.receiptAmount), 0));
            }, totalBillBalance() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.billBalance), 0));
            },
            totalDetailAmount() {
                return this.formatPrice(this.billDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.detailAmount), 0));
            },
            iframeSrc() {
                return 'https://maps.google.com/maps?q=' + this.businessDetails.lat + ',' + this.businessDetails.lng + '&t=&z=13&ie=UTF8&iwloc=&output=embed';
            }

        },
        mounted() {

            this.getApplicationsPaginated("")
            this.getTradeCategory()
            this.getSubCounties()

            this.$watch('dateFrom', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getApplicationsPaginated("")
                }

            })
            this.$watch('dateTo', function (newValue) {
                if (this.dateFrom === '' || this.dateTo === '') {

                } else {
                    this.getApplicationsPaginated("")
                }
            })


          this.menuAccess()
        },
        methods: {

          maskPhoneNumber(input) {
            if (!input || typeof input !== 'string') {
              return input; // Return as is if input is null, undefined, or not a string
            }

            const digits = input.replace(/\D/g, '');

            if (digits.length > 4) {
              return input.slice(0, 2) + input.slice(2, -2).replace(/\d/g, '*') + input.slice(-2);
            }

            return input; // Return as is if not a phone number or too short
          },

          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Pending Approval");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then((res) => {
                  // alert(res.data.message);
                })
                .catch((e) => {
                  // alert(e.message);
                });

          },

            getRight(type){
                return getRight(type)
            },
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getApplicationsPaginated("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getApplicationsPaginated("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getApplicationsPaginated("")
                }
            },

            //number format
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            gotTo(route) {
                router.push(route)
            },
            timelineCarousel() {

                $("#timeline-carousel").owlCarousel({
                    items: 1,
                    loop: !1,
                    margin: 0,
                    nav: !0,
                    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
                    dots: !1,
                    responsive: {
                        576: {
                            items: 2
                        },
                        768: {
                            items: 4
                        }
                    },
                    startPosition: -1 // display the last item first
                });

            },
            getHumanDate(dateData){
                //  const res = dateData.split(" ");
                if(dateData=="-"){
                    return "-";
                }else {
                    const dateObject = new Date(Date.parse(dateData));
                    return dateObject.toDateString();
                }


            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounties");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties = res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getWards(subCountyID){
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID",subCountyID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards = res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getZones() {
                this.itemList.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {

                            for (let i = 0; i < res.data.data.zones.length; i++) {
                                let zone = res.data.data.zones[i];
                                let item = {
                                    id: zone.id,
                                    value: zone.zone
                                };

                                this.itemList.push(item);
                            }

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            getApplicationsPaginated(search){
                this.loading = true
                this.businesses.splice(0)
                const data = new FormData();
                data.append("function", "getApplicationsPaginated")
                data.append("keyword", "4")
                data.append("search", search)
                data.append("dateFrom", this.dateFrom)
                data.append("dateTo", this.dateTo)
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                execute(data, trade)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {

                            this.totalItems = res.data.data.totalItems.count
                            this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

                            this.businesses = res.data.data.businesses
                            this.totalAmount = this.businesses.reduce((acc, cur) => {
                                let totalAmount = Number(cur.receiptAmount);
                                if (!isNaN(totalAmount)) {
                                    return acc + totalAmount;
                                } else {
                                    return acc;
                                }
                            }, 0);

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            downloadApplicationsPaginated(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadApplicationsPaginated");
                data.append("keyword", "4")
                data.append("dateFrom", this.dateFrom)
                data.append("dateTo", this.dateTo)
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                executeDownload(data,trade).then(response => {
                    this.download ="Download Report"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.dateFrom+' to '+ this.dateTo+" Businesses.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getBusinessDetails(businessID,billNo) {
               this.viewApproved = false
               this.billNo = billNo
                this.statuses.splice(0)
                const data = new FormData();
                data.append("function", "getBusinessDetails")
                data.append("billNo", billNo)
                data.append("sessionNames", sessionStorage.getItem("sessionNames"))
                data.append("sessionIdNo", sessionStorage.getItem("sessionIdNo"))
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {
                           this.viewApproved = true
                            this.statuses = res.data.data.statuses
                            this.businessDetails = res.data.data.business
                            this.billDetail = res.data.data.bill.billDetails
                            this.feesAndCharges = res.data.data.feesAndCharge
                            this.receiptDetails = res.data.data.receipt.receiptDetails
                            this.receiptInfos = res.data.data.receipt.receiptInfos
                            this.entries = res.data.data.entries
                            this.timelineCarousel()

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            printTradePermit(businessID) {

                const data = new FormData();
                data.append("function", "printTradePermit");
                data.append("businessID", businessID);
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {

                            localStorage['params'] = JSON.stringify({
                                businessID: businessID
                            })
                            const routeData = this.$router.resolve({name: 'trade'});
                            window.open(routeData.href, '_blank');

                        } else {
                            this.businessNotFound = true
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            businessValidation(stageStatus/*Approved or Declined*/) {
                const data = new FormData();
                data.append("function", "businessValidation")
                data.append("business", JSON.stringify(this.businessDetails))
                data.append("description","No Changes done")
                data.append("comments", this.comments)
                data.append("billNo", this.billNo)
                data.append("statusID", Number(this.entries.statusID) + 1)
                data.append("idNo",  sessionStorage.getItem("idNo"))
                data.append("names",  sessionStorage.getItem("names"))
                data.append("phoneNumber",  sessionStorage.getItem("phoneNumber"))
                data.append("sessionNames",  sessionStorage.getItem("sessionNames"))
                data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"))
                data.append("sessionPhoneNumber",  sessionStorage.getItem("sessionPhoneNumber"))
                data.append("balanceAmount",  this.billDetail.billBalance)
                data.append("stageStatus",  stageStatus)
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {
                            this.printTradePermit(this.businessDetails.businessID)
                            this.getApplicationsPaginated("")
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            formattedDate(date) {
                return moment(date).format('DD MMM YYYY hh:mm A') + ' (' + moment(date).fromNow() + ')'
            },
            printBill(billNo) {
                localStorage['params'] = JSON.stringify({
                    billNo: billNo
                })
                const routeData = this.$router.resolve({name: 'bill'});
                window.open(routeData.href, '_blank');
            },
            printReceipt() {
                if (this.receiptDetails.receiptNo == null) {
                    alert("Try again later");
                } else {
                    //  this.$refs.closeReceived.click();
                    localStorage['params'] = JSON.stringify({
                        receiptNo: this.receiptDetails.receiptNo
                    })
                    const routeData = this.$router.resolve({name: 'receipt'});
                    window.open(routeData.href, '_blank');
                }
            },
            getTradeCategory() {
                this.loading = true
                this.message = null
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "SBP");
                execute(data, biller)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.tradeCategories = res.data.data.incomeTypes
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            getTradeSubCategory(event) {
                this.loading = true
                this.getApplicationsPaginated(event.target.value)
                this.message = null
                this.tradeSubCategories.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", event.target.value);
                execute(data, biller)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.tradeSubCategories = res.data.data.feesAndCharges
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            selectedTradeSubCategory(event) {
                const data = JSON.parse(event.target.value)
                this.getApplicationsPaginated(data.feeId)
                //set fee and charges
                /*    this.selectedFeeAndCharge(event)

                    const data = JSON.parse(event.target.value)
                    this.trade.businessDetails.businessCategory = data.accountDesc
                    this.trade.businessDetails.businessSubCategory =  data.feeDescription
                    this.trade.amount =  data.unitFeeAmount
                    this.trade.businessDetails.feeID = data.feeId;*/

            },
            selectedSubCounty(event) {
                const data = JSON.parse(event.target.value)
                this.getWards(data.subCountyID)
                this.getApplicationsPaginated(data.subCountyName)

            },
            selectedWard(event) {
                const data = JSON.parse(event.target.value)
                this.getApplicationsPaginated(data.wardName)
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div class="row">
        <div class="col-12 active">
            <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active ">
                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                        <button
                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="mdi mdi-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between active"
                             id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                <a v-if="getRight('PENDING WAIVER')" :class="activeClass" @click="gotTo('waiver-pending')" href="javascript:void(0)">Pending Waiver</a>
                                <a v-if="getRight('APPROVED WAIVER')" :class="activeClass2" @click="gotTo('waiver-approved')" href="javascript:void(0)">Approved Waiver</a>
                                <a v-if="getRight('DECLINED WAIVER')" :class="activeClass3" @click="gotTo('waiver-declined')" href="javascript:void(0)">Declined Waiver</a>
                                <a v-if="getRight('REVERTED WAIVER')" :class="activeClass4" @click="gotTo('waiver-reverted')" href="javascript:void(0)">Reverted Waiver</a>

                            </div>

                        </div>
                    </nav>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import {execute, parking,getRight} from "@/api";

    export default {
        name: "ToolBarWaiver",
        data(){
            return{
                todayHighlights:[
                    {
                        title: "LOGGED VEHICLES",
                        amount: "41,695"
                    }
                ],
                subCountyName:'',
                currentPage:'',
            }
        },
        mounted() {
            this.currentPage = sessionStorage.getItem("currentPage")
        },

        computed: {
            activeClass() {
                return {
                    'nav-item': true,
                    'nav-link': true,
                    'active': this.currentPage === 'waiver-pending'
                }
            },
            activeClass2() {
                return {
                    'nav-item': true,
                    'nav-link': true,
                    'active': this.currentPage === 'waiver-approved'
                }
            },
            activeClass3() {
                return {
                    'nav-item': true,
                    'nav-link': true,
                    'active': this.currentPage === 'waiver-declined'
                }
            },
            activeClass4() {
                return {
                    'nav-item': true,
                    'nav-link': true,
                    'active': this.currentPage === 'waiver-reverted'
                }
            },

        },
        methods:{
          getRight(role){
            return getRight(role)
          },
            gotTo(route){
                sessionStorage.setItem("currentPage",route)
                this.$router.push(route);
            },
            getTodayHighlights(){
                this.todayHighlights.splice(0)
                const data = new FormData();
                data.append("function", "todayHighlights");
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.todayHighlights = res.data.data.todayHighlights

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
        }
    }

</script>

<style scoped>

</style>